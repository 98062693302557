import React from 'react'
import { TextField, InputAdornment, IconButton, Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { debounce } from '../../../../utils'

import './Info.css'
import { getEntity } from '../../../../API/requests'

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			nameIsValid: false,
			emailIsValid: false,
			confirmPasswordIsValid: false,
			passwordIsValid: false,
			formIsValid: false,
			showPassword1: false,
			showPassword2: false,
			usersRoles: [],
			formErrors: { name: '', email: '', password: '', confirmPassword: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {

		getEntity('roles').then(res => {
			this.setState({ usersRoles: res.data })
		}, err => {
			console.log(err)
		})

		if (this.state.infoComplete) {
			this.setState({
				nameIsValid: true,
				emailIsValid: true,
				passwordIsValid: true,
				confirmPasswordIsValid: true,
				formErrors: { name: '', email: '', password: '', confirmPassword: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;

		if (name === 'password') {
			this.props.updateStateOnParent('confirmPassword', '')
			this.setState({ confirmPassword: '', confirmPasswordIsValid: false })
		}

		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	validateFields(name, value) {

		let fieldValidationErrors = this.state.formErrors;
		let nameIsValid = this.state.nameIsValid;
		let emailIsValid = this.state.emailIsValid;
		let passwordIsValid = this.state.passwordIsValid;
		let confirmPasswordIsValid = this.state.confirmPasswordIsValid

		switch (name) {
			case 'name':
				nameIsValid = value.length > 4;
				fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid name`}`
				break;
			case 'email':
				emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a email`}`
				break;
			case 'password':
				passwordIsValid = new RegExp(/^(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
				fieldValidationErrors.password = passwordIsValid ? '' : `Password must have at least 2 Uppercase Letters, 2 numbers and more than 8 characters `
				break;
			case 'confirmPassword':
				confirmPasswordIsValid = this.state.password === this.state.confirmPassword;
				fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : 'Passwords do not match'
				break;
			default: ;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			nameIsValid,
			emailIsValid,
			passwordIsValid,
			confirmPasswordIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.nameIsValid &&
				this.state.emailIsValid &
				this.state.passwordIsValid &&
				this.state.confirmPasswordIsValid &&
				this.state.userType !== ''
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		return (
			<div className='user-info-main-container' >
				<div className='user-info-left-container'>
					<div className='user-info-input-container'>
						<TextField
							required
							autoFocus
							id='name'
							name='name'
							label='Name'
							color='secondary'
							sx={{ width: '45%' }}
							fullWidth
							size='small'
							type='text'
							autoComplete='name'
							variant='standard'
							value={this.state.name}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.nameIsValid}
							helperText={this.state.formErrors.name}
						/>
						<TextField
							required
							id='email'
							color='secondary'
							name='email'
							label='Email'
							sx={{ width: '45%' }}
							fullWidth
							variant='standard'
							type='email'
							autoComplete='email'
							value={this.state.email}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.emailIsValid}
							helperText={this.state.formErrors.email}
						/>
					</div >
					<div className='user-info-input-container'>
						<TextField
							required
							id='password'
							color='secondary'
							name='password'
							label='Password'
							sx={{ width: '45%' }}
							variant='standard'
							type={this.state.showPassword1 ? 'text' : 'password'}
							value={this.state.password}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.passwordIsValid}
							helperText={this.state.formErrors.password}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton tabIndex={-1} onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })} >
										{this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>,
							}}
						/>
						<TextField
							required
							id='confirmPassword'
							color='secondary'
							name='confirmPassword'
							label='Confirm Password'
							variant='standard'
							sx={{ width: '45%' }}
							type={this.state.showPassword2 ? 'text' : 'password'}
							value={this.state.confirmPassword}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.confirmPasswordIsValid}
							helperText={this.state.formErrors.confirmPassword}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton tabIndex={-1} onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })} >
										{this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>,
							}}
						/>
					</div>
					<FormControl variant="standard" sx={{ minWidth: 220, marginTop: '25px' }}>
						<InputLabel id="user type select">User type</InputLabel>
						<Select
							labelId="user type select"
							id="user type select"
							name='userType'
							value={this.state.userType}
							onChange={(e) => this.handelInput(e)}
							error={this.state.userType === ''}
							label="User type"
						>
							{this.state.usersRoles.map(role => {
								return (
									<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
				</div>
			</div>
		)
	}
}

export default Info