import React from 'react'
import { TextField, Checkbox, FormControlLabel, FormGroup, Tooltip, Autocomplete, Popper, Avatar } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TooltipHintsEvents } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'
import { getEntity, getFiltersData } from '../../../../API/requests';
import { statusDropdownOptions } from '../../../../Data/EpisodesStatus';
import { languages } from '../../../../Data/LanguageData';

import '../Add/Info.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			titleIsValid: false,
			videoIsValid: true,
			formIsValid: false,
			salesForceIsValid: true,
			formErrors: { title: '', video: '', salesForceUrl: '' },
			dropDownData: null,
			responsibleData: []
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		getFiltersData('episodes').then(res => {
			this.setState({ dropDownData: res.data })
		}, err => {
			console.log(err)
		})

		getEntity('backoffice_users').then(res => {
			const users = res.data.map(user => `${user.name},${user.picture}`)
			this.setState({ responsibleData: users })
		}, err => {
			console.log(err)
		})

		if (this.state.infoComplete) {
			this.setState({
				titleIsValid: true,
				videoIsValid: true,
				salesForceIsValid: true,
				formErrors: { title: '', video: '', salesForceUrl: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleDateInput = (name, value) => {
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleAutocompleteInput = (name, value) => {
		this.props.updateStateOnParent(name, value)
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let titleIsValid = this.state.titleIsValid;
		let videoIsValid = this.state.videoIsValid;
		let salesForceIsValid = this.state.salesForceIsValid;

		switch (name) {
			case 'title':
				titleIsValid = value.length > 4;
				fieldValidationErrors.title = titleIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid title`}`
				break;
			case 'video':
				videoIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.video = videoIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			case 'salesforce_url':
				salesForceIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.salesForceUrl = salesForceIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			titleIsValid,
			videoIsValid,
			salesForceIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.titleIsValid &&
				this.state.videoIsValid &&
				this.state.salesForceIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		const CustomPopper = (props) => {
			return <Popper {...props} style={{ width: 'max-content', maxWidth: '300px', minWidth: '150px' }} />
		}

		return (
			<div className='episode-info-main-container' >
				<div className='episode-info-container'>
					<div className='episode-info-input-container'>
						<TextField
							required
							autoFocus
							id='title'
							name='title'
							label='Title'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.title}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.titleIsValid}
							helperText={this.state.formErrors.title}
						/>
					</div>
					<div className='episode-info-input-container'>
						<TextField
							multiline
							rows={3}
							id='description'
							color='secondary'
							name='description'
							label='Description'
							fullWidth
							variant='standard'
							type='text'
							value={this.state.description}
							onChange={(e) => this.handelInput(e)}
						/>
					</div >
					<div className='episode-info-input-container'>
						<TextField
							id='video'
							color='secondary'
							name='video'
							label='Video Url'
							fullWidth
							variant='standard'
							type='url'
							value={this.state.video}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.videoIsValid}
							helperText={this.state.formErrors.video}
						/>
					</div>
					<div className='episode-info-date-picker-container'>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
								<DatePicker
									inputFormat='yyyy-MM-dd'
									mask='____-__-__'
									label='Date'
									value={this.state.date}
									onChange={(value) => this.handleDateInput('date', value)}
									renderInput={(params) => <TextField fullWidth variant='standard' color='secondary' {...params} />}
								/>
							</div>
						</LocalizationProvider>
					</div>
					<div className='episode-info-checkbox-container'>
						<FormGroup>
							<Tooltip title={TooltipHintsEvents.private} disableInteractive>
								<FormControlLabel control={<Checkbox
									name='private'
									color='secondary'
									value={this.state.private}
									checked={this.state.private}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Private' />
							</Tooltip>
							<Tooltip title={TooltipHintsEvents.featured} disableInteractive>
								<FormControlLabel control={<Checkbox
									name='featured'
									color='secondary'
									value={this.state.featured}
									checked={this.state.featured}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Featured' />
							</Tooltip>
							<Tooltip title={TooltipHintsEvents.force} disableInteractive>
								<FormControlLabel control={<Checkbox
									name='force'
									color='secondary'
									value={this.state.force}
									checked={this.state.force}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Forced' />
							</Tooltip>
						</FormGroup>
						<FormGroup>
							<Tooltip title={TooltipHintsEvents.available} disableInteractive>
								<FormControlLabel control={<Checkbox
									name='available'
									color='secondary'
									value={this.state.available}
									checked={this.state.available}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Available' />
							</Tooltip>
							<Tooltip title={TooltipHintsEvents.online} disableInteractive>
								<FormControlLabel control={<Checkbox
									name='online'
									color='secondary'
									value={this.state.online}
									checked={this.state.online}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Online' />
							</Tooltip>
						</FormGroup>
					</div>
				</div>
				<div className='episode-info-container'>
					<div className='episode-info-autocomplete-div'>
						<Autocomplete
							sx={{ width: '100%' }}
							id="event"
							options={this.state.dropDownData ? this.state.dropDownData.events : []}
							disableCloseOnSelect
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							value={this.state.eventId}
							disableClearable
							onChange={(e, newValue) => this.handleAutocompleteInput('eventId', newValue)}
							getOptionLabel={(option) => option.title}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.title}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth error={!this.state.eventId} {...params} label='Event*' variant='standard' color='secondary' />
							)}
						/>
						<Autocomplete
							multiple
							sx={{ width: '100%' }}
							id="topics"
							options={this.state.dropDownData ? this.state.dropDownData.topics : []}
							disableCloseOnSelect
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.length} topics selected`}</p>}
							value={this.state.topics}
							onChange={(e, newValue) => this.handleAutocompleteInput('topics', newValue)}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.name === value.name}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Topics' variant='standard' color='secondary' />
							)}
						/>
						<Autocomplete
							multiple
							id="industries"
							options={this.state.dropDownData ? this.state.dropDownData.industries : []}
							disableCloseOnSelect
							sx={{ width: '100%' }}
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.length} industries selected`}</p>}
							value={this.state.industries}
							onChange={(e, newValue) => this.handleAutocompleteInput('industries', newValue)}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.name === value.name}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Industries' variant='standard' color='secondary' />
							)}
						/>
						<Autocomplete
							multiple
							sx={{ width: '100%' }}
							id="speakers"
							options={this.state.dropDownData ? this.state.dropDownData.speakers : []}
							disableCloseOnSelect
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.length} speakers selected`}</p>}
							value={this.state.speakers}
							onChange={(e, newValue) => this.handleAutocompleteInput('speakers', newValue)}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.name === value.name}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Speakers' variant='standard' color='secondary' />
							)}
						/>
						<Autocomplete
							multiple
							sx={{ width: '100%' }}
							id="formats"
							options={this.state.dropDownData ? this.state.dropDownData.formats : []}
							disableCloseOnSelect
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.length} formats selected`}</p>}
							value={this.state.formats}
							onChange={(e, newValue) => this.handleAutocompleteInput('formats', newValue)}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.name === value.name}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Formats' variant='standard' color='secondary' />
							)}
						/>
						<Autocomplete
							multiple
							sx={{ width: '100%' }}
							id="types"
							options={this.state.dropDownData ? this.state.dropDownData.types : []}
							disableCloseOnSelect
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.length} types selected`}</p>}
							value={this.state.types}
							onChange={(e, newValue) => this.handleAutocompleteInput('types', newValue)}
							getOptionLabel={(option) => option.name}
							isOptionEqualToValue={(option, value) => option.name === value.name}
							renderOption={(props, option, { selected }) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									<Checkbox
										icon={<CheckBoxOutlineBlank fontSize='small' />}
										checkedIcon={<CheckBox fontSize='small' />}
										style={{ color: '#B14282' }}
										checked={selected}
									/>
									{option.name}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Types' variant='standard' color='secondary' />
							)}
						/>
					</div>
				</div>
				<div className='episode-info-container'>
					<div className='joined-inputs'>
						<div className='episode-info-input-container'>
							<TextField
								id='location'
								color='secondary'
								name='location'
								label='Location'
								fullWidth
								variant='standard'
								value={this.state.location}
								onChange={(e) => this.handelInput(e)}
							/>
						</div>
						<div className='episode-info-input-container'>
							<Autocomplete
								sx={{ width: '100%' }}
								id="language"
								options={languages}
								limitTags={1}
								PopperComponent={CustomPopper}
								openOnFocus
								value={this.state.language}
								onChange={(e, newValue) => this.handleAutocompleteInput('language', newValue)}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.name === value.name}
								renderOption={(props, option) => (
									<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
										{option.emoji} {option.name}
									</li>
								)}
								renderInput={(params) => (
									<TextField fullWidth {...params} label='Language' variant='standard' color='secondary' />
								)}
							/>
						</div>
					</div>
					<div className='episode-info-input-container'>
						<Autocomplete
							sx={{ width: '100%' }}
							id="responsible"
							options={this.state.responsibleData}
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							value={this.state.responsible}
							onChange={(e, newValue) => this.handleAutocompleteInput('responsible', newValue)}
							getOptionLabel={(option) => option.split(',')[0]}
							isOptionEqualToValue={(option, value) => option === value}
							renderOption={(props, option) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px', gap: '10px' }}>
									<Avatar alt={option.split(',')[0]} src={option.split(',')[1] !== 'null' ? `${SERVER_URL}/resources/images/users/${option.split(',')[1]}` : null} sx={{ width: '30px', height: '30px' }} />
									{option.split(',')[0]}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Responsible' variant='standard' color='secondary' />
							)}
						/>
					</div>
					<div className='episode-info-input-container'>
						<TextField
							id='region'
							color='secondary'
							name='region'
							label='Region'
							fullWidth
							variant='standard'
							value={this.state.region}
							onChange={(e) => this.handelInput(e)}
						/>
					</div>
					<div className='episode-info-input-container'>
						<TextField
							id='organization'
							color='secondary'
							name='organization'
							label='Organization'
							fullWidth
							variant='standard'
							value={this.state.organization}
							onChange={(e) => this.handelInput(e)}
						/>
					</div>
					<div className='episode-info-input-container'>
						<Autocomplete
							sx={{ width: '100%' }}
							id="status"
							options={statusDropdownOptions}
							limitTags={1}
							PopperComponent={CustomPopper}
							openOnFocus
							value={this.state.status}
							onChange={(e, newValue) => this.handleAutocompleteInput('status', newValue)}
							renderOption={(props, option) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									{option}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Status' variant='standard' color='secondary' />
							)}
						/>
					</div>
					<div className='episode-info-input-container'>
						<TextField
							sx={{ paddingBottom: '12px' }}
							id='salesforce_url'
							color='secondary'
							name='salesforce_url'
							label='Salesforce Link'
							fullWidth
							variant='standard'
							type='utl'
							value={this.state.salesforce_url}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.salesForceIsValid}
							helperText={this.state.formErrors.salesForceUrl}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default Info