import React, { useState } from 'react';

import GroupedButtons from '../components/Buttons/GroupedButtons';
import About from '../components/ListPageContainers/About'
import FAQs from '../components/ListPageContainers/FAQs'
import TopicsAndIndustries from '../components/ListPageContainers/TopicsAndIndustries'
import AddIndustry from '../components/Dialogs/Industries/Add/AddIndustry';
import AddTopic from '../components/Dialogs/Topics/Add/AddTopic';
import AddFaq from '../components/Dialogs/FAQ/Add/AddFaq';
import AddAbout from '../components/Dialogs/About/Add/AddAbout';
import useUserInfo from '../customHooks/useUserInfo'
import { getUserPrefs, setUserPrefs } from '../components/user/UserPrefs';

import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'

import './css/Lists.css'
import TypesAndFormats from '../components/ListPageContainers/TypesAndFormats';
import AddTypes from '../components/Dialogs/Types/Add/AddTypes';
import AddFormats from '../components/Dialogs/Formats/Add/AddFormats';

const groupButtonsStyle = {
	'& .MuiToggleButton-primary': {
		color: 'var(--light-blue)',
		border: '1px solid #174779',
		transition: 'all 0.3s ease-in-out',
		height: '40px',
		// minWidth: '100px',
		'&:hover': {
			backgroundColor: 'var(--light-blue)',
			color: 'white',
		}
	},
	'& .Mui-selected': {
		color: 'rgba(255,255,255,1)',
		backgroundColor: 'var(--light-blue) !important',
		'&:hover': {
			backgroundColor: 'var(--light-blue)'
		}
	}
}

const viewsButtons = [
	{ label: 'Topics And Industries', value: 'topicsAndIndustries' },
	{ label: 'Types And Formats', value: 'typesAndFormats' },
	{ label: `FAQ's`, value: 'faq' },
	{ label: 'About Sections', value: 'about' },
]


function Lists() {
	const [openAddTopicDialog, setOpenAddTopicDialog] = useState(false)
	const [openAddIndustryDialog, setOpenAddIndustryDialog] = useState(false)
	const [openAddFAQDialog, setOpenAddFAQDialog] = useState(false)
	const [openAddAboutDialog, setOpenAddAboutDialog] = useState(false)
	const [openAddTypesDialog, setOpenAddTypesDialog] = useState(false)
	const [openAddFormatsDialog, setOpenAddFormatsDialog] = useState(false)

	const [topicAndIndustryParentRefresh, setTopicAndIndustryParentRefresh] = useState(false)
	const [typesAndFormatsRefresh, setTypesAndFormatsRefresh] = useState(false)
	const [faqParentRefresh, setFaqParentRefresh] = useState(false)
	const [aboutParentRefresh, setAboutParentRefresh] = useState(false)

	const [entityAmount, setEntityAmount] = useState({ topics: 0, industries: 0, faq: 0, about: 0, types: 0, formats: 0 })

	const [view, setView] = useState(getUserPrefs('lists').view.view)

	const { userInfo } = useUserInfo()

	const viewsConfig = {
		topicsAndIndustries: {
			topics: { nameS: 'Topic', nameP: 'Topics', addFunc: () => setOpenAddTopicDialog(true) },
			industries: { nameS: 'Industry', nameP: 'Industries', addFunc: () => setOpenAddIndustryDialog(true) }
		},
		typesAndFormats: {
			types: { nameS: 'Type', nameP: 'Types', addFunc: () => setOpenAddTypesDialog(true) },
			formats: { nameS: 'Format', nameP: 'Formats', addFunc: () => setOpenAddFormatsDialog(true) }
		},
		faq: { nameS: 'FAQ', nameP: `FAQ's`, addFunc: () => setOpenAddFAQDialog(true) },
		about: { nameS: 'About Section', nameP: 'Sections', addFunc: () => setOpenAddAboutDialog(true) },
	}

	const setAmount = (entity, amount) => {
		setEntityAmount(state => ({ ...state, ...{ [entity]: amount } }))
	}

	return (
		<div className='misc-page-main-div'>
			<div className='misc-page-top-div'>
				<div className='misc-page-top-add'>
					{view === 'topicsAndIndustries' ? (
						<>
							{userInfo.user_role.canAddTopic &&
								<Fab
									sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', padding: '0px 6px', marginRight: '10px' }}
									onClick={viewsConfig.topicsAndIndustries.topics.addFunc}
								>
									<Add />
									<p>{`Add new ${viewsConfig.topicsAndIndustries.topics.nameS}`}</p>
								</Fab>
							}
							{userInfo.user_role.canAddIndustry &&
								<Fab
									sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', padding: '0px 6px' }}
									onClick={viewsConfig.topicsAndIndustries.industries.addFunc}
								>
									<Add />
									<p>{`Add new ${viewsConfig.topicsAndIndustries.industries.nameS}`}</p>
								</Fab>
							}
						</>
					) :
						view === 'typesAndFormats' ?
							<>
								{userInfo.user_role.canAddType &&
									<Fab
										sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', padding: '0px 6px', marginRight: '10px' }}
										onClick={viewsConfig.typesAndFormats.types.addFunc}
									>
										<Add />
										<p>{`Add new ${viewsConfig.typesAndFormats.types.nameS}`}</p>
									</Fab>
								}
								{userInfo.user_role.canAddFormat &&
									<Fab
										sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', padding: '0px 6px' }}
										onClick={viewsConfig.typesAndFormats.formats.addFunc}
									>
										<Add />
										<p>{`Add new ${viewsConfig.typesAndFormats.formats.nameS}`}</p>
									</Fab>
								}
							</>
							:
							(
								(view === 'faq' && userInfo.user_role.canAddFaq) || (view === 'about' && userInfo.user_role.canAddAbout) ? (
									<Fab
										sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', padding: '0px 6px' }}
										onClick={viewsConfig[view].addFunc}
									>
										<Add />
										<p>{`Add new ${viewsConfig[view].nameS}`}</p>
									</Fab>
								) : ''
							)}
				</div>
				<div className='events-page-top-info-main-div'>
					{view === 'topicsAndIndustries' ? (
						<>
							<MiscTopInfo title={`Total of ${viewsConfig.topicsAndIndustries.topics.nameP}`} value={entityAmount.topics} />
							<MiscTopInfo title={`Total of ${viewsConfig.topicsAndIndustries.industries.nameP}`} value={entityAmount.industries} />
						</>
					) : (
						view === 'typesAndFormats' ?
							<>
								<MiscTopInfo title={`Total of ${viewsConfig.typesAndFormats.types.nameP}`} value={entityAmount.types} />
								<MiscTopInfo title={`Total of ${viewsConfig.typesAndFormats.formats.nameP}`} value={entityAmount.formats} />
							</>
							:
							<MiscTopInfo title={`Total of ${viewsConfig[view].nameP}`} value={entityAmount[view]} />
					)}
				</div>
			</div>
			<div className='misc-page-bottom-div'>
				<div className='bottom-div-misc-wrapper'>
					<GroupedButtons currentValue={view} setValue={(value) => { setView(value); setUserPrefs('lists', 'view', 'view', value) }} buttons={viewsButtons} style={groupButtonsStyle} />
					<div className={`bottom-div-misc ${view === 'topicsAndIndustries' ? '' : 'hidden'}`}>
						<TopicsAndIndustries setAmount={(entity, amount) => setAmount(entity, amount)} parentRefresh={topicAndIndustryParentRefresh} />
					</div>
					<div className={`bottom-div-misc ${view === 'typesAndFormats' ? '' : 'hidden'}`}>
						<TypesAndFormats setAmount={(entity, amount) => setAmount(entity, amount)} parentRefresh={typesAndFormatsRefresh} />
					</div>
					<div className={`bottom-div-misc ${view === 'faq' ? '' : 'hidden'}`}>
						<FAQs setAmount={(entity, amount) => setAmount(entity, amount)} parentRefresh={faqParentRefresh} />
					</div>
					<div className={`bottom-div-misc ${view === 'about' ? '' : 'hidden'}`}>
						<About setAmount={(entity, amount) => setAmount(entity, amount)} parentRefresh={aboutParentRefresh} />
					</div>
				</div>
			</div>
			<AddIndustry open={openAddIndustryDialog} close={() => setOpenAddIndustryDialog(false)} refresh={() => setTopicAndIndustryParentRefresh(!topicAndIndustryParentRefresh)} />
			<AddTopic open={openAddTopicDialog} close={() => setOpenAddTopicDialog(false)} refresh={() => setTopicAndIndustryParentRefresh(!topicAndIndustryParentRefresh)} />
			<AddFaq open={openAddFAQDialog} close={() => setOpenAddFAQDialog(false)} refresh={() => setFaqParentRefresh(!faqParentRefresh)} />
			<AddAbout open={openAddAboutDialog} close={() => setOpenAddAboutDialog(false)} refresh={() => setAboutParentRefresh(!aboutParentRefresh)} />
			<AddTypes open={openAddTypesDialog} close={() => setOpenAddTypesDialog(false)} refresh={() => setTypesAndFormatsRefresh(!typesAndFormatsRefresh)} />
			<AddFormats open={openAddFormatsDialog} close={() => setOpenAddFormatsDialog(false)} refresh={() => setTypesAndFormatsRefresh(!typesAndFormatsRefresh)} />
		</div>
	);
}

const MiscTopInfo = ({ title, value }) => {
	return (
		<div className='misc-page-top-info'>
			<p className='misc-page-top-info-title'>{title}</p>
			<p className='misc-page-top-info-value'>{value}</p>
		</div>
	)
}

export default Lists;
