import React, { useEffect, useState } from "react";
import moment from "moment";
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'
import { Clear } from "@mui/icons-material";
import TableDatePicker from '../../Inputs/TableDatePicker'

export function dateBetweenFilterFn(row, filterValues, property) {

    if (!filterValues[0] && !filterValues[1]) {
        return row
    }

    if (filterValues[0] && !filterValues[1]) {
        return moment(row.original[property]).isSameOrAfter(filterValues[0], 'day')
    } else if (filterValues[0] && filterValues[1]) {
        return moment(row.original[property]).isBetween(filterValues[0], filterValues[1], 'day', "[]")
    } else if (!filterValues[0] && filterValues[1]) {
        return moment(row.original[property]).isSameOrBefore(filterValues[1], 'day')
    }
}

export function DateRangeColumnFilter({ column, header, table }) {
    const [filterValues, setFilterValues] = useState([null, null])

    useEffect(() => {
        if (!column.getFilterValue()) {
            setFilterValues([null, null])
        }
    }, [column])

    const setFilter = (field, date) => {

        if (field === 'from') {
            if (!date && !filterValues[1]) {
                column.setFilterValue(undefined)
                setFilterValues((state) => [date, state[1]])
                return;
            }

            column.setFilterValue((state) => [date, state ? state[1] : null])
            setFilterValues((state) => [date, state[1]])
            return;
        }

        if (!date && !filterValues[0]) {
            column.setFilterValue(undefined)
            setFilterValues((state) => [state[0], date])
            return;
        }

        column.setFilterValue((state) => [state ? state[0] : null, date])
        setFilterValues((state) => [state[0], date])
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div style={{ display: 'flex', gap: '5px', marginBottom: '5px', }}>
                <p style={{ fontSize: '14px' }}>From</p>
                <DatePicker
                    inputFormat='DD-MM-YYYY'
                    mask='__-__-____'
                    value={filterValues[0]}
                    onChange={(date) => setFilter('from', date)}
                    renderInput={(params) => <TableDatePicker size='small' params={params} />}
                />
                {filterValues[0] && <Clear sx={{ fontSize: '1.1rem', cursor: 'pointer' }} onClick={() => setFilter('from', null)} />}
            </div>
            <div style={{ display: 'flex', gap: '5px' }}>
                <p style={{ fontSize: '14px' }}>To</p>
                <DatePicker
                    inputFormat='DD-MM-YYYY'
                    mask='__-__-____'
                    value={filterValues[1]}
                    onChange={(date) => setFilter('to', date)}
                    renderInput={(params) => <TableDatePicker params={params} size='small' />}
                />
                {filterValues[1] && <Clear sx={{ fontSize: '1.1rem', cursor: 'pointer' }} onClick={() => setFilter('to', null)} />}
            </div>
        </LocalizationProvider>
    )
}