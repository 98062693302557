import React from 'react';
import './DashboardCard.css'
import MiniViewChart from '../Charts/Views/MiniViewChart';

function DashboardCard({ title, text, type, data }) {

    return (
        <div className='dashboard-card'>
            <div className='dashboard-card-info-div'>
                <p className='dashboard-card-title'>{title}</p>
                <p className='dashboard-card-text'>{text}</p>
            </div>
            {data &&
                <div className='dashboard-card-graph'>
                    <MiniViewChart data={data} />
                </div>
            }
        </div>
    )
}

export default DashboardCard;
