import React, { useState, useEffect } from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import moment from 'moment'
import '../Add/Review.css'

function Review(props) {
	const [infoText, setInfoText] = useState('No changes Detected')

	useEffect(() => {
		if (Object.keys(props.state.string).length > 0 ||
			Object.keys(props.state.boolean).length > 0 ||
			Object.keys(props.state.object).length > 0 ||
			Object.keys(props.state.date).length > 0) {
			setInfoText('Detected Changes:')
		}
	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review changed information before submitting</p>
			<p style={{ textAlign: 'center', fontSize: '16px' }}>{infoText}</p>
			<div className='review-scrollable-div'>
				{Object.entries(props.state.string).map(([key, value]) => {
					if (key === 'speakers' || key === 'topics' || key === 'industries' || key === 'formats' || key === 'types') return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>{key}</p>
							<p className='review-text'>{value === '' ? 'None' : props.originalState[key].map(elem => `${elem.name}`)}</p>
						</div>
					)
					if (key === 'eventId') return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>Event</p>
							<p className='review-text'>{!value ? 'None' : props.originalState.eventId.title}</p>
						</div>
					)
					if (key === 'responsible') return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>{key}</p>
							<p className='review-text'>{value ? value.split(',')[0] : 'Removed'}</p>
						</div>
					)
					if (key === 'language') return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>{key}</p>
							<p className='review-text'>{value.emoji} {value.name}</p>
						</div>
					)
					return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>{key}</p>
							<p className='review-text'>{value === '' ? 'None' : value}</p>
						</div>
					)
				})}
				{Object.entries(props.state.date).map(([key, value]) => {
					return (
						<div key={key} className='review-label-div'>
							<p className='review-label'>{key}</p>
							<p className='review-text'>{moment(value).format('yyyy-MMM-DD')}</p>
						</div>
					)
				})}
				{/* <Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} /> */}
				<div className='review-chips-div'>
					{Object.entries(props.state.boolean).map(([key, value]) => {
						return (
							<BooleanChip key={key} value={value} label={key} tooltip={key} />
						)
					})}
				</div>
				{/* <Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} /> */}
				<div className='review-images-div'>
					{Object.entries(props.state.object).map(([key, value]) => {
						if (!value) return (
							<p key={key}>Image removed</p>
						)
						return (
							<img key={key} alt={`${key}-preview`} src={getImageBlob(value)} width='auto' height='100%' />
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Review