import React from 'react'
import { TextField } from '@mui/material'
import { debounce } from '../../../utils'


class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			nameIsValid: false,
			emailIsValid: false,
			formIsValid: false,
			formErrors: { name: '', email: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		if (this.state.predefinedName && this.state.name.length > 4) {
			this.setState({ nameIsValid: true }, () => this.props.updateStateOnParent('infoComplete', true))
		}

		if (this.state.infoComplete) {
			this.setState({
				nameIsValid: true,
				formErrors: { name: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}


	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let nameIsValid = this.state.nameIsValid;
		let emailIsValid = this.state.emailIsValid;

		switch (name) {
			case 'name':
				nameIsValid = value.length > 1;
				fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid name`}`
				break;
			case 'email':
				emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a email`}`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			nameIsValid: nameIsValid,
			emailIsValid: emailIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({ formIsValid: this.state.nameIsValid && this.state.emailIsValid }, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		return (
			<div className='industry-info-main-container' >
				<div className='industry-info-left-container'>
					<p style={{ textAlign: 'center' }}>By adding a user, this user will receive an email with a code to access private information</p>
					<div className='industry-info-input-container'>
						<TextField
							required
							autoFocus
							id='name'
							name='name'
							label='Name'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.name}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.nameIsValid}
							helperText={this.state.formErrors.name}
						/>
					</div>
					<div className='industry-info-input-container'>
						<TextField
							required
							autoFocus
							id='email'
							name='email'
							label='Email'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.email}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.emailIsValid}
							helperText={this.state.formErrors.email}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default Info