import React from 'react'
import PerEpisode from '../Charts/Doughnut/PerEpisode'
import './MiscReportPanel.css'
import { topics } from '../../Data/Topics'
import { industries } from '../../Data/Industries'

function MiscReportPanel(props) {

    return (
        <div className='misc-report-main-div'>
            <div className='topics-per-episode-div'>
                <PerEpisode episodes={props.data.episodes} subData={topics} subDataTitle='Topics' subDataProperty='topics'  />
            </div>
            <div className='industries-per-episode-div'>
                <PerEpisode episodes={props.data.episodes} subData={industries} subDataTitle='Industries' subDataProperty='industries'/>
            </div>
        </div>
    )
}

export default MiscReportPanel