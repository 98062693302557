const defaultPrefs = {
    series: {
        collapsed: { view: false, state: false, filter: false},
        view: { view: 'cards' },
        order: { order: 'id' }
    },
    events: {
        collapsed: { view: false, state: false, filter: false},
        view: { view: 'cards' },
        order: { order: 'start_date' }
    },
    episodes: {
        collapsed: { view: false, state: false, filter: false},
        view: { view: 'cards' },
        order: { order: 'date' }
    },
    speakers: {
        collapsed: { view: false, state: false, filter: false},
        view: { view: 'cards' },
        order: { order: 'name' }
    },
    users: {
        order: { order: 'name' } 
    },
    lists: {
        view: { view: 'topicsAndIndustries' },
    },
    recycle: {
        view: { view: 'events' },
    }, 
}

export const setUserPrefs = (entity, param, subParam, value) => {

    if (localStorage.getItem('userPrefs')) {
        const localStorageData = JSON.parse(localStorage.getItem('userPrefs'))
        localStorageData[entity][param][subParam] = value

        localStorage.setItem('userPrefs', JSON.stringify(localStorageData))

    } else {
        const prefObject = defaultPrefs;
        prefObject[entity][param][subParam] = value

        localStorage.setItem('userPrefs', JSON.stringify(prefObject))
    }
}

export const getUserPrefs = (entity) => {
    if(localStorage.getItem('userPrefs')) {
        const localStorageData = JSON.parse(localStorage.getItem('userPrefs'))
        return localStorageData[entity]
    }
    return defaultPrefs[entity]
}