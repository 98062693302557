import { useModal } from 'mui-modal-provider'

import MoreInfo from '../../components/Dialogs/Events/MoreInfo'
import EditAppUser from '../../components/Dialogs/Events/Edit/EditEvent'
import AddAppUser from '../../components/Dialogs/Events/Add/AddEvent'
import DeleteEntity from '../../components/Dialogs/DeleteDialog'


export default function useEventsDialog() {
    const { showModal } = useModal()

    const openDeleteDialog = (data, refresh) => {

        const modal = showModal(DeleteEntity, {
            refresh,
            type: "Event",
            data,
            close: () => modal.hide(),
        })
    }

    const openAddDialog = (refresh) => {
        const modal = showModal(AddAppUser, {
            refresh,
            close: () => modal.hide(),
        })
    }

    const openEditDialog = (data, refresh) => {
        const modal = showModal(EditAppUser, {
            data,
            refresh,
            close: () => modal.hide(),
        })
    }

    const openViewMore = (data) => {
        const modal = showModal(MoreInfo, {
            data,
            close: () => modal.hide(),
        })
    }

    return {
        openDeleteDialog,
        openAddDialog,
        openEditDialog,
        openViewMore,
    }
}