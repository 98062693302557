import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/user';
import { Divider } from '@mui/material'
import DashboardCard from '../components/Cards/DashboardCard';
import Activity from '../components/DashboardContainers/Activity';
import UserSmallCard from '../components/Cards/Users/UserSmallCard'
import useUserInfo from '../customHooks/useUserInfo';
import AlertsContainer from '../components/DashboardContainers/AlertsContainer';
import { getEntity } from '../API/requests';
import { SocketContext } from '../context/socket/socket';

import './css/DashboardPage.css'

function Dashboard() {
	const [dashboardData, setDashboardData] = useState([])
	const [onlineUsers, setOnlineUsers] = useState([])
	const [state] = useContext(UserContext)
	const { userInfo } = useUserInfo()
	const socket = useContext(SocketContext)

	useEffect(() => {
		socket.on('user_logged', getDashboardData)

		return () => {
			socket.off('user_logged', getDashboardData)
		}
	}, [])


	useEffect(() => {
		getDashboardData()
	}, [])

	const getDashboardData = () => {
		getEntity('dashboard').then(res => {
			setDashboardData(res.data)
		}, err => {
			console.log(err)
		})

		getEntity('backoffice_users').then(res => {
			const usersOnline = res.data.filter(user => user.online === true && user.id !== userInfo.id)
			setOnlineUsers([...usersOnline, userInfo])
		}, err => {
			console.log(err)
		})
	}

	return (
		<div className='dashboard-main-div'>
			<div className='dashboard-top-div'>
				<DashboardCard title='Total Events' data={dashboardData.views?.events.lastMonthEvents} text={dashboardData.nActiveItems?.events} />
				<Divider orientation='vertical' className='dashboard-card-divider' />
				<DashboardCard title='Events Views' data={dashboardData.views?.events.eventsViews} text={dashboardData.views?.events.countEventViews} />
				<Divider orientation='vertical' className='dashboard-card-divider' />
				<DashboardCard title='Total Episodes' data={dashboardData.views?.episodes.lastMonthEpisodes} text={dashboardData.nActiveItems?.episodes} />
				<Divider orientation='vertical' className='dashboard-card-divider' />
				<DashboardCard title='Episodes Views' data={dashboardData.views?.episodes.episodesViews} text={dashboardData.views?.episodes.countEpisodeViews} />
			</div>
			<div className='dashboard-middle-div'>
				{userInfo.user_role.canViewActivity &&
					<div className='dashboard-middle-left'>
						<p className='dashboard-div-title'>Last 24h Activities</p>
						<div className='dashboard-activity-main-div'>
							<Activity />
						</div>
					</div>
				}
				<div className='dashboard-middle-middle'>
					<p className='dashboard-div-title'>Alerts</p>
					<div className='dashboard-alerts-main-div'>
						<AlertsContainer data={state} />
					</div>
				</div>
				<div className='dashboard-middle-right'>
					<p className='dashboard-div-title'>Online Users</p>
					<div className='dashboard-users-main-div'>
						{onlineUsers.map(user => {
							return (
								<UserSmallCard key={user.id} data={user} />
							)
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
