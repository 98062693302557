import React, { useState } from 'react'
import { EventNoteOutlined, Delete, Edit, Visibility, PowerSettingsNew } from '@mui/icons-material'
import useUserInfo from '../../../customHooks/useUserInfo';
import { Tooltip } from '@mui/material'
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import { setEntityOnlineOrOffline } from '../../../API/requests';
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import EditSerie from '../../Dialogs/Series/Edit/EditSerie';
import MoreInfo from '../../Dialogs/Series/MoreInfo';

import './SerieCard.css'

function SerieCard({ serie, refresh }) {
    const { userInfo } = useUserInfo()
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openViewMoreDialog, setOpenViewMoreDialog] = useState(false)

    const setOnlineDialogInfo = { title: `Set serie ${serie.online ? 'Offline' : 'Online'}?`, message: `Set serie ${serie.title} ${serie.online ? 'Offline' : 'Online'}?` }

    const handleSetEventOnline = () => {
        setEntityOnlineOrOffline('series', serie.id, !serie.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            refresh()
        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <div className='serie-card-main-div'>
            <Tooltip title='Number of Events'>
                <div className='serie-card-event-counter'>
                    <EventNoteOutlined />
                    <p>{serie.events?.length}</p>
                </div>
            </Tooltip>
            <p className='serie-card-title'>{serie.title}</p>
            <img alt={serie.name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.portrait_image}`} />
            <div className='serie-card-action-div'>
                {userInfo.user_role.canRemoveSerie &&
                    <Tooltip disableInteractive title='Delete Serie'>
                        <Delete className='event-card-icon' onClick={() => setOpenDeleteDialog(true)} />
                    </Tooltip>
                }
                {userInfo.user_role.canEditSerie &&
                    <Tooltip disableInteractive title='Edit Serie'>
                        <Edit className='event-card-icon' onClick={() => setOpenEditDialog(true)} />
                    </Tooltip>
                }
                <Tooltip disableInteractive title='View more info'>
                    <Visibility className='event-card-icon' onClick={() => setOpenViewMoreDialog(true)} />
                </Tooltip>
                {userInfo.user_role.canEditSerie &&
                    <Tooltip disableInteractive title={`Set serie ${serie.online ? 'Offline' : 'Online'}`} >
                        <PowerSettingsNew className={`event-card-icon-online-${serie.online ? 'on' : 'off'}`} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetEventOnline())} />
                    </Tooltip>
                }
            </div>
            <DeleteDialog data={serie} type='Serie' open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} refresh={refresh} />
            <EditSerie data={serie} open={openEditDialog} close={() => setOpenEditDialog(false)} refresh={refresh} />
            <MoreInfo data={serie} open={openViewMoreDialog} close={() => setOpenViewMoreDialog(false)} refresh={refresh}  />
        </div>
    )
}

export default SerieCard