export function debounce(fn, ms) {
    let timer;
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn(...args)
        }, ms)
    };
}

export const getMaxNumberOfEpisodes = (events) => {
    let numberOfEpisdodes = 0;

    events.forEach(event => {
        if(event.episodes.length > numberOfEpisdodes) {
            numberOfEpisdodes = event.episodes.length
        }
    })

    return numberOfEpisdodes;
}

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

export const generateThumbnailImageFromVideoUrl = (videoUrl) => {
	if (!videoUrl || videoUrl.length === 0) return `${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/placeholder.png`
	const url = new URL(videoUrl)
	const videoID = videoUrl.split('/')[3];
	const imageID = url.searchParams.get('photo_id');
	const token = url.searchParams.get('token');
	const size = '800x' // or 400x200cr
	return `https://teleperformance.videomarketingplatform.co/${videoID.split('.')[0]}/${imageID}/${token}/${size}/thumbnail.jpg`
}
