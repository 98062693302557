import React, { useState, useEffect } from 'react'
import sortArray from 'sort-array'
import FaqTable from '../Tables/FaqTable'

import './FAQs.css'
import { getEntity } from '../../API/requests'

function FAQs(props) {
	const [faqs, setFaqs] = useState([])
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {

		getEntity('faq').then(res => {
			setFaqs(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
			props.setAmount('faq', res.data.length)
		}, err => {
			console.log(err)
		})

	}, [refresh, props.parentRefresh])

	return (
		<div className='faq-main-div'>
			<div className='faq-table-div'>
				<FaqTable data={faqs} refresh={() => setRefresh(!refresh)} />
			</div>
		</div>
	)
}

export default FAQs