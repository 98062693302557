import React, { useState, useEffect } from 'react';
import UsersTable from '../components/Tables/UsersTable';
import { getEntity } from '../API/requests';
import useUserInfo from '../customHooks/useUserInfo';
import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import AddPrivateUser from '../components/Dialogs/PrivateUsers/AddPrivateUser';

import './css/Activity.css'

function Activity() {
	const [users, setUsers] = useState([])
	const { userInfo } = useUserInfo()
	const [refresh, setRefresh] = useState(false)
	const [openAddUser, setOpenAddUser] = useState(false)

	useEffect(() => {
		getEntity('app_users').then(res => {
			setUsers(res.data)
		}, err => {
			console.log(err)
		})
	}, [refresh])

	return (
		<div className='activity-page-main-div'>
			<div className='activity-page-top-div'>
				<div className='episodes-page-top-add'>
					{userInfo.user_role.canAddUser &&
						<Fab
							sx={{ width: '200px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
							onClick={() => setOpenAddUser(true)}
						>
							<Add />
							<p>Add new User</p>
						</Fab>
					}
				</div>
				<div className='activity-page-top-info-main-div'>
					<EventsTopInfo title='Total Users' value={users.length} />
				</div>
			</div>
			<div className='activity-page-bottom-div'>
				<div className='bottom-div-activity'>
					<div className='bottom-div-activity-wrapper'>
						<div className='bottom-div-activity-table'>
							<UsersTable data={users} refresh={() => setRefresh(!refresh)} canRemoveUser={userInfo.user_role.canRemoveUser} />
						</div>
					</div>
				</div>
			</div>
			<AddPrivateUser open={openAddUser} close={() => setOpenAddUser(false)} refresh={() => setRefresh(!refresh)} />
		</div>
	);
}

const EventsTopInfo = ({ title, value }) => {
	return (
		<div className='activity-page-top-info'>
			<p className='activity-page-top-info-title'>{title}</p>
			<p className='activity-page-top-info-value'>{value}</p>
		</div>
	)
}

export default Activity;
