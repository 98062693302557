import React, { useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    PowerSettingsNew as OnlineIcon
} from '@mui/icons-material'

import { styled } from '@mui/material/styles'

import DeleteDialog from '../DeleteDialog';

import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { setEntityOnlineOrOffline } from '../../../API/requests';
import EditIndustry from './Edit/EditIndustry';
import useUserInfo from '../../../customHooks/useUserInfo'

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

function MoreInfo(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    const setOnlineDialogInfo = { title: `Set Industry ${props.industry.online ? 'Offline' : 'Online'}?`, message: `Set Industry ${props.industry.name} ${props.industry.online ? 'Offline' : 'Online'}?` }

    const handleSetSpeakerOnline = () => {
        setEntityOnlineOrOffline('industries', props.industry.id, !props.industry.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <Dialog
            maxWidth='sm'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='industry-mui-dialog-container'
        >
            <DialogContent className='industry-more-info-dialog-content'>
                <div className='industry-more-info-dialog-header'>
                    <div className='industry-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{props.industry.name}</p>
                    </div>
                    <div className='industry-dialog-actions'>
                        {!props.hideActions &&
                            <>
                                {userInfo.user_role.canRemoveIndustry &&
                                    <Tooltip title='Delete Industry'>
                                        <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                    </Tooltip>
                                }
                                {userInfo.user_role.canEditIndustry &&
                                    <>
                                        <Tooltip title='Edit Industry'>
                                            <EditIcon onClick={() => setOpenEditDialog(true)} />
                                        </Tooltip>
                                        <Tooltip title={`Set Industry ${props.industry.online ? 'Offline' : 'Online'}`}>
                                            <OnlineIcon sx={props.industry.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetSpeakerOnline())} />
                                        </Tooltip>
                                    </>
                                }
                            </>}
                    </div>
                </div>
                <div className='industry-more-info-dialog-info-wrapper'>
                    <div className='industry-more-info-info-container'>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <StyledAvatar>
                                        <Title />
                                    </StyledAvatar>
                                </ListItemAvatar>
                                <ListItemText primary='Name' secondary={props.industry.name} />
                            </ListItem>
                        </List>
                        <div className='industry-more-info-chips-div'>
                            <BooleanChip value={props.industry.online} label='Online?' tooltip='online' />
                        </div>
                        <Divider orientation='horizontal' sx={{ width: '90%' }} />
                        <div className='industry-more-info-other-info'>
                            <p>{`ID: ${props.industry.id}`}</p>
                            <p>{`Created At: ${moment(props.industry.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                            <p>{`Last Update: ${moment(props.industry.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} nameField='name' type='Industry' data={props.industry} refresh={props.refresh} />
            <EditIndustry open={openEditDialog} close={() => { setOpenEditDialog(false); props.close() }} data={props.industry} refresh={props.refresh} />
        </Dialog>
    )
}

export default MoreInfo