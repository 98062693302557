import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmailTextField, PasswordTextField } from '../components/textfields/InputTextFields'
import { ThreeDots } from 'react-loading-icons'
import ForgotPassDialog from '../components/Dialogs/ForgotPassDialog';
import StandardSnackbar from '../components/Snackbar/StandardSnackbar';
import useToken from '../customHooks/useToken'
import { SocketContext } from '../context/socket/socket'
import { loginUser } from '../API/requests';

import Logo from '../assets/LEADER_LOGO.svg'

import './css/LoginPage.css'

export default function LoginPage() {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [forgotPassDialog, setForgotPassDialog] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarInfo, setSnackbarInfo] = useState({ message: '', type: 'success' })
	const [submitting, setSubmitting] = useState(false)
	const { setToken } = useToken()
	const socket = useContext(SocketContext)
	const navigate = useNavigate()

	useEffect(() => {
        sessionStorage.removeItem('id')
        setToken(null)
        if (socket.connected) socket.disconnect()
    }, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitting(true)

		loginUser(e.target[0].value, e.target[1].value).then(res => {
			setToken({ token: res.headers.key })
			sessionStorage.setItem('id', res.headers.id)
			setSubmitting(false);
			navigate('/app/dashboard', { replace: true })	
		}, err => {
			setSnackbarInfo({ message: err.response ? err.response.data : 'Cannot comunicate with the server, please try again later', type: 'error' })
			setSubmitting(false);
			setOpenSnackbar(true)
		})
	}


	const triggerSnackbar = (message, type) => {
		setSnackbarInfo({ message: message, type: type })
		setOpenSnackbar(true);
		setForgotPassDialog(false)
	}

	const handleChange = (e) => {
		setEmail(e.target.value)
	}

	return (
		<div className='login-page-main-div'>
			<p style={{ color: 'white', position: 'absolute', bottom: '0', left: '0' }}>{process.env.REACT_APP_VERSION}</p>
			<img alt='Leaders insight logo' className='login-logo' src={Logo} height='40px' width='auto' />
			<form className='login-form' onSubmit={handleSubmit}>
				<EmailTextField color='light' value={email} onChange={handleChange} />
				<PasswordTextField color='light' value={password} onChange={(e) => setPassword(e.target.value)} />
				<div className='login-forgotpass-div'>
					<p onClick={() => setForgotPassDialog(true)}>Forgot my password</p>
				</div>
				{submitting ? <ThreeDots fill='white' height='1em' style={{ marginBottom: '15px' }} /> : (
					<button
						className='login-button'
						type='submit'
					>
						Log in
					</button>
				)}
			</form>
			<ForgotPassDialog open={forgotPassDialog} close={() => setForgotPassDialog(false)} snackbarTrigger={triggerSnackbar} />
			<StandardSnackbar
				open={openSnackbar}
				close={() => setOpenSnackbar(false)}
				autoHide={true}
				type={snackbarInfo.type}
				position={['bottom', 'center']}
				direction='up'
			>
				{snackbarInfo.message}
			</StandardSnackbar>
		</div>
	);
}
