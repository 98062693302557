import React, { useMemo, useEffect, useState } from 'react'
import TablePrefab from './TablePrefab';
import { DateBodyComponent, LanguageComponent, LinkComponent, ResponsibleAvatar, StatusBodyComponent, TitleComponent, BooleanComponent } from './TableComponents/RowsComponents'
import { DateRangeColumnFilter, dateBetweenFilterFn } from './TableComponents/DateFilter'
import moment from 'moment'
import useEpisodesDialog from '../../customHooks/Dialogs/useEpisodesDialog';

import './EpisodesTable.css'

const statusFilterOptions = [
    { text: 'Delivered', value: 'Delivered' },
    { text: 'In progress', value: 'In Progress' },
    { text: 'Completed', value: 'Completed' },
    { text: 'Planned', value: 'Planned' },
]

function EpisodesTable(props) {
    const [episodes, setEpisodes] = useState([])
    const { openEditDialog, openDeleteDialog, openViewMore } = useEpisodesDialog();

    useEffect(() => {
        setEpisodes(props.episodes)
    }, [props.episodes])


    const onEdit = (data) => {
        openEditDialog(data.row.original, () => props.refresh())
    }

    const onView = (data) => {
        openViewMore(data.row.original)
    }

    const onDelete = (data) => {
        openDeleteDialog(data.row.original, () => props.refresh())
    }

    //Se houver valores null tem de se usar accessorFn e passa a empty strings para não dar erro nos filtros
    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 50,
                columnFilterModeOptions: ['equals', 'between', 'betweenInclusive', 'greaterThan', 'lessThan'],
                filterFn: 'equals',
                enableGrouping: false,
            },
            {
                accessorKey: "title",
                header: "Title",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                enableHiding: false,
                Cell: ({ renderedCellValue }) => <TitleComponent value={renderedCellValue} />
            },
            {
                accessorKey: "event.title",
                header: "Event",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                Cell: ({ renderedCellValue }) => <TitleComponent value={renderedCellValue} />
            },
            {
                accessorFn: (data) => data.location ?? '',
                id: 'location',
                header: "Location",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.language ?? '',
                id: 'language',
                header: "Language",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                Cell: ({ renderedCellValue }) => renderedCellValue && <LanguageComponent language={renderedCellValue} />
            },
            {
                accessorFn: (data) => data.region ?? '',
                id: 'region',
                header: "Region",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.organization ?? '',
                id: 'organization',
                header: "Organization",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.responsible ?? '',
                id: 'responsible',
                header: "Responsible",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                Cell: ({ cell }) => <ResponsibleAvatar cell={cell} />
            },
            {
                accessorFn: (data) => data.status ?? '',
                id: 'status',
                header: "Status",
                filterVariant: 'select',
                filterSelectOptions: statusFilterOptions,
                enableGlobalFilter: false,
                enableColumnFilterModes: false,
                Cell: ({ renderedCellValue }) => renderedCellValue && <StatusBodyComponent value={renderedCellValue} />
            },
            {
                accessorKey: "featured",
                header: "Featured?",
                filterVariant: 'checkbox',
                Cell: ({ renderedCellValue }) => <BooleanComponent boolean={renderedCellValue} />
            },
            {
                accessorKey: "salesforce_url",
                header: "Salesforce",
                enableGrouping: false,
                enableColumnFilter: false,
                Cell: ({ renderedCellValue }) => renderedCellValue && <LinkComponent url={renderedCellValue} name='Click to open' />
            },
            {
                accessorKey: "video",
                header: "Video",
                enableGrouping: false,
                enableColumnFilter: false,
                Cell: ({ renderedCellValue }) => renderedCellValue && <LinkComponent url={renderedCellValue} name='Click to View' />
            },
            {
                accessorKey: "date",
                header: "Date",
                Cell: DateBodyComponent,
                enableGlobalFilter: false,
                enableGrouping: false,
                enableColumnFilterModes: false,
                Filter: DateRangeColumnFilter,
                filterFn: (row, id, filterValues) => dateBetweenFilterFn(row, filterValues, 'date'),
            },
        ],
        []
    );

    const onExportData = () => {
        const dataToExport = episodes.map(el => {
            return {
                "ID": el.id,
                "Title": el.title,
                "Description": el.description,
                "Event": el.event?.title,
                "Location": el.location,
                "Language": el.language,
                "Region": el.region,
                "Organization": el.organization,
                "Status": el.status,
                "Salesforce Link": el.salesforce_url,
                "Responsible": el.responsible ? el.responsible.split(',')[0] : '',
                "Video": el.video,
                "Date": moment(el.date).format('DD MMMM YYYY'),
                "Topics": el.topics.map(topic => topic.name).join(', '),
                "Speakers": el.speakers.map(speaker => speaker.name).join(', '),
                "Industries": el.industries.map(indust => indust.name).join(', '),
                "Formats": el.formats.map(format => format.name).join(', '),
                "Type": el.types.map(type => type.name).join(', '),
                "Views": el.views

            }
        })

        return dataToExport
    }

    return (
        <TablePrefab
            settingsId='episodesTable'
            roleId='Episode'
            columns={columns}
            data={episodes}
            exportData={onExportData()}
            title='Episodes'
            enableGrouping
            enablePinning
            enableColumnDragging
            enableColumnOrdering
            export
            adicionalState={{ isLoading: props.loading }}
            enableColumnFilterModes
            onRowView={onView}
            onRowEdit={onEdit}
            onRowDelete={onDelete}
            renderDetailPanel={({ row }) => <DetailedPanel episode={row.original} />}
        />
    )
}

export default EpisodesTable

const DetailedPanel = ({ episode }) => {

    return (
        <div className='detailed-panel-main-div'>
            <div className='detailed-panel-chips'>
                <div className='detailed-panel-chip'>
                    <p>Available: <span>{episode.available ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Featured: <span>{episode.featured ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Forced Recommendation: <span>{episode.force ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Online: <span>{episode.online ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Private: <span>{episode.private ? 'Yes' : 'No'}</span></p>
                </div>
            </div>
            <div className='detailed-panel-description'>
                <p style={{ fontWeight: '700' }}>Description:</p>
                <p>{episode.description}</p>
            </div>
            <div className='detailed-panel-info'>
                {episode.formats?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Format: <span>{episode.formats.map(format => format.name).join(', ')}</span></p>
                    </div>
                }
                {episode.types?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Type: <span>{episode.types.map(type => type.name).join(', ')}</span></p>
                    </div>
                }
                {episode.industries?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Industries: <span>{episode.industries.map(ind => ind.name).join(', ')}</span></p>
                    </div>
                }
                {episode.topics?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Topics: <span>{episode.topics.map(topic => topic.name).join(', ')}</span></p>
                    </div>
                }
            </div>
        </div>
    )
}