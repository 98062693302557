import React, { useEffect, useState } from 'react'
import ViewsReportsTable from '../Tables/ViewsReportsTable'
import ViewsChart from '../Charts/Views/ViewsChart'

import './ViewsReportPanels.css'


function ViewsReportsPanel(props) {
    const [data, setData] = useState([])
    const [chartTitle, setChartTitle] = useState('Total Views')

    useEffect(() => {
        setData([...props.views])
    }, [props.views])

    const setActiveEntity = (entity) => {
        setChartTitle(entity.title)
        const filteredViews = [...props.views].filter(view => view.entity_id === entity.id)
        setData(filteredViews)
    }

    const clearEntitySelected = () => {
        setChartTitle('Total Views')
        setData([...props.views])
    }

    return (
        <div className='views-report-panel-main-div'>
            <div className='views-report-chart'>
                <ViewsChart data={data} title={chartTitle} clearSelected={clearEntitySelected} />
            </div>
            <div className='views-report-table'>
                <ViewsReportsTable setActiveEntity={setActiveEntity} type={props.type} data={props.data} />
            </div>
        </div>
    )
}

export default ViewsReportsPanel