import React from 'react';
import { Alert, Button } from '@mui/material'
import './WarningAlert.css'

function WarningAlert({show, children, viewAction, dismissAction}) {
    return <div className={`alert-div${show ? '-show' : '-hide'}`}>
        <Alert
            sx={{ boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.5)', height: '50px' }}
            severity='warning'
            action={
                <>
                    <Button onClick={viewAction} color='inherit' size='small'>View</Button>
                    <Button onClick={dismissAction} color='inherit' size='small'>Dismiss</Button>
                </>
            }
        >
            {children}
        </Alert>
    </div>
}

export default WarningAlert;
