export const getLanguage = (name) => {
    return languages.find(el => el.name === name)
}

export const languages = [
    {
        "name": "Arabic",
        "emoji": "🇸🇦",
    },
    {
        "name": "Chinese",
        "emoji": "🇨🇳",
    },
    {
        "name": "Danish",
        "emoji": "🇩🇰",
    },
    {
        "name": "English",
        "emoji": "🇬🇧"
    },
    {
        "name": "Finish",
        "emoji": "🇫🇮",
    },
    {
        "name": "French",
        "emoji": "🇫🇷",
    },
    {
        "name": "Greek",
        "emoji": "🇬🇷",
    },
    {
        "name": "German",
        "emoji": "🇩🇪",
    },
    {
        "name": "Hindi",
        "emoji": "🇮🇳",
    },
    {
        "name": "Italian",
        "emoji": "🇮🇹",
    },
    {
        "name": "Japanese",
        "emoji": "🇯🇵",
    },
    {
        "name": "Korean",
        "emoji": "🇰🇷",
    },
    {
        "name": "Polish",
        "emoji": "🇵🇱",
    },
    {
        "name": "Portuguese",
        "emoji": "🇵🇹",
    },
    {
        "name": "Russian",
        "emoji": "🇷🇺",
    },
    {
        "name": "Spanish",
        "emoji": "🇪🇸",
    },
    {
        "name": "Swedish",
        "emoji": "🇸🇪",
    },
    {
        "name": "Thai",
        "emoji": "🇹🇭",
    },
    {
        "name": "Turkish",
        "emoji": "🇹🇷",
    },
    {
        "name": "Vietnamese",
        "emoji": "🇻🇳",
    },
]