import React, { Component } from 'react';
import { EmailTextField } from '../textfields/InputTextFields'
import { Dialog, DialogContent, DialogActions } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'
import StandardSnackbar from '../Snackbar/StandardSnackbar';
import { forgotPass } from '../../API/requests';

class ForgotPassDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailIsValid: false,
            formErrors: '',
            submitting: false,
            openSnackbar: false,
            response: { message: '', type: 'error' },
            test: false
        }
    }

    handleDialogInput = event => {
        this.setState({ email: event.target.value }, () => this.validateInput(event.target.value))
    }

    validateInput = value => {

        let emailIsValid = this.state.emailIsValid;
        let formErrors = this.state.formErrors;

        emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        formErrors = emailIsValid ? '' : `"${value}" is not a valid email`;

        this.setState({
            emailIsValid: emailIsValid,
            formErrors: formErrors,
        })
    }

    handleSubmit = () => {
        this.setState({ submitting: true });

        forgotPass(this.state.email).then(() => {
            this.setState({ submitting: false }, () => this.props.snackbarTrigger(`Password Reset instructions were sent to ${this.state.email}`, 'success'))
        }, (err) => {
            console.log(err)
            this.setState({ response: { message: err.response ? err.response.data : 'Cannot communicate with the server, please try again later', type: 'error' } }, () => {
                this.setState({ submitting: false, openSnackbar: true })
            })
        })
    }

    render() {
        return (
            <Dialog
                fullWidth
                maxWidth='sm'
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p style={{ marginBottom: '20px' }}>Provide your email address to reset your password</p>
                    <EmailTextField
                        color='dark'
                        value={this.state.email}
                        onChange={this.handleDialogInput}
                        error={!this.state.emailIsValid ? true : false}
                        helperText={this.state.formErrors}
                    />
                </DialogContent>
                <DialogActions>
                    {this.state.submitting ? <ThreeDots fill='var(--orange)' height='1em' style={{ marginBottom: '15px' }} /> : (
                        <button
                            className='forgot-pass-button'
                            disabled={!this.state.emailIsValid}
                            onClick={this.handleSubmit}
                        >
                            Send
                        </button>
                    )}
                </DialogActions>
                <StandardSnackbar
                    open={this.state.openSnackbar}
                    close={() => this.setState({ openSnackbar: false })}
                    autoHide={true}
                    type={this.state.response.type}
                    position={['bottom', 'center']}
                    direction='up'
                >
                    {this.state.response.message}
                </StandardSnackbar>
            </Dialog>
        );
    }
}

export default ForgotPassDialog;
