import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import { AppBar, Toolbar, CssBaseline, Avatar, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material'
import { SocketContext } from '../../context/socket/socket'
import { ExitToApp as LogoutIcon, PersonOutlined as ProfileIcon, Settings as SettingsIcon } from '@mui/icons-material'
import useUserInfo from '../../customHooks/useUserInfo';
import UserProfile from '../Dialogs/Users/UserProfile';
import useDialog from '../Dialogs/DialogHooks/useDialog'
import SettingsDialog from '../Dialogs/Settings/SettingsDialog';

const TopAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'var(--light-blue)',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    boxShadow: 'none',
    border: 'none',
    left: 0,
    minWidth: '900px',
    right: 'auto',
    minHeight: '80px',
    paddingLeft: '72px',
    justifyContent: 'center',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

const menuStyle = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 22,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

const mdTheme = createTheme()

function TopPanel() {
    const location = useLocation()
    const navigate = useNavigate()
    const { openErrDialog } = useDialog()
    const [openUserProfile, setOpenUserProfile] = useState(false)
    const { userInfo } = useUserInfo()
    const socket = useContext(SocketContext)

    const [openSettings, setOpenSettings] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        socket.on('connect_error', onConnectionError)

        return () => {
            socket.off('connect_error', onConnectionError)
        }
    }, [])


    const logoutUser = () => {
        navigate('/login', { replace: true })
    }

    const onConnectionError = (event) => {
        const timer = setTimeout(() => {
            logoutUser()
        }, 4000)
        openErrDialog({ title: 'Connection lost', message: 'Could not communicate wth the server, you will be redirected to the login page' }, () => { clearInterval(timer); logoutUser() })
    }

    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <TopAppBar position='absolute'>
                <Toolbar sx={{ pr: '24px', pl: '72px', display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ textTransform: 'capitalize', fontSize: '32px', fontWeight: '600', color: '#ebebeb' }}>
                        {location.pathname.split('/')[2].replace('-', ' ')}
                    </p>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
                        <IconButton sx={{ marginLeft: '15px' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
                            {userInfo.picture ? (
                                <Avatar src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${userInfo.picture}`} sx={{ height: '45px', width: '45px', marginRight: '10px' }}></Avatar>
                            ) : (
                                <Avatar sx={{ height: '45px', width: '45px', marginRight: '10px' }}></Avatar>
                            )}
                            <p style={{ fontSize: '16px', color: '#ebebeb' }}>{userInfo.name}</p>
                        </IconButton>
                    </div>
                </Toolbar>
                <Menu
                    id='profile-menu'
                    PaperProps={menuStyle}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => { setOpenUserProfile(true); setAnchorEl(null) }}>
                        <ListItemIcon>
                            <ProfileIcon fontSize='medium' />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                    {userInfo.user_role.canManageSettings &&
                        <MenuItem onClick={() => { setOpenSettings(true); setAnchorEl(null) }}>
                            <ListItemIcon>
                                <SettingsIcon fontSize='medium' />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                    }
                    <MenuItem onClick={logoutUser}>
                        <ListItemIcon>
                            <LogoutIcon fontSize='medium' />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </TopAppBar>
            <UserProfile open={openUserProfile} close={() => setOpenUserProfile(false)} />
            <SettingsDialog open={openSettings} close={() => setOpenSettings(false)} />
        </ThemeProvider>
    );
}

export default TopPanel;
