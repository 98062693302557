import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Divider, IconButton } from '@mui/material'
import moment from 'moment'

import { Close } from '@mui/icons-material';

import UserProfileEdit from './UserProfileEdit';

import Portrait_Placeholder from '../../../assets/portrait_placeholder.png'
import useUserInfo from '../../../customHooks/useUserInfo'

import './UserProfile.css'


function UserInfoDialog(props) {
    const [user, setUser] = useState([])
    const [lastActivities, setLastActivities] = useState([])
    const [totalActivity, setTotalActivity] = useState(0)
    const [userPictureUrl, setUserPictureUrl] = useState('')
    const [sideView, setSideView] = useState('info')
    const { userInfo } = useUserInfo()

    useEffect(() => {
        setUser(props.data)
        setLastActivities(props.data.activities)
        setTotalActivity(props.data.activities.length)
        setUserPictureUrl(`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${props.data.picture}?${new Date().getTime()}`)
    }, [props.data])

    return (
        <Dialog
            maxWidth='sm'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='user-profile-mui-dialog-container'
        >
            <DialogContent className='user-profile-more-info-dialog-content'>
                <div className='user-profile-more-info-dialog-header'>
                    <div className='user-profile-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{user.name}</p>
                    </div>
                </div>
                <div className='user-profile-more-info-dialog-info-wrapper'>
                    <div className='user-profile-more-info-dialog-picture-div'>
                        <div className='userType-wrapper-div-profile'>
                            <div className='userType-div-profile'>{user.user_role?.name}</div>
                        </div>
                        <div className='user-profile-more-info-dialog-picture'>
                            {user.picture ? (
                                <img style={{ cursor: 'default' }} className='user-profile-picture' alt='user portrait' src={userPictureUrl} width='100%' height='100%' />
                            ) : (
                                <img style={{ cursor: 'default' }} className='user-profile-picture' alt='user portrait' src={Portrait_Placeholder} width='100%' height='100%' />
                            )}
                        </div>
                        <div className='user-profile-info-common-info'>
                            <p className='user-profile-name'>{user.name}</p>
                            <p className='user-profile-email'>{user.email}</p>
                        </div>
                        <div className='user-profile-actions'>
                            <div style={{ alignItems: 'flex-end', flex: '1 1' }}>
                                {userInfo.user_role.canEditBackofficeUser &&
                                    <button onClick={() => setSideView('editProfile')}>Edit User</button>
                                }
                            </div>
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: '90%' }} />
                    <div className='user-profile-more-info-info-container'>
                        <div className={`user-profile-static-info ${sideView === 'info' ? '' : 'hidden'}`}>
                            <div className='user-profile-info-last-activity'>
                                <p style={{ marginBottom: '10px ' }} className='user-profile-activity-title' >{`Total activity: ${totalActivity}`}</p>
                                <p className='user-profile-activity-title' >Last activity:</p>
                                <div className='user-profile-activity-scrollable'>
                                    {lastActivities.map(activity => {
                                        return (
                                            <p key={activity.id} className='user-profile-activity-text'>
                                                {`${moment(activity.createdAt).format('YYYY-MM-DD HH:mm:ss')}: ${activity.user}, ${activity.activity}`}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider orientation='horizontal' sx={{ width: '80%' }} />
                            <div className='user-profile-info-meta'>
                                <p className='user-profile-meta'>{`User ID: ${user.id}`}</p>
                                <p className='user-profile-meta'>{`Image ID: ${user.image_id}`}</p>
                                <p className='user-profile-meta'>{`Created At: ${moment(user.createdAt)}`}</p>
                                <p className='user-profile-meta'>{`Updated At: ${moment(user.updatedAt)}`}</p>
                            </div>
                        </div>
                        <div className={`user-profile-static-editProfile ${sideView === 'editProfile' ? '' : 'hidden'}`}>
                            <IconButton sx={{ position: 'absolute', top: '0', right: '0' }} onClick={() => setSideView('info')}>
                                <Close />
                            </IconButton>
                            <p style={{ fontSize: '18px' }} >Edit User</p>
                            <div className='user-profile-edit-fields'>
                                <UserProfileEdit data={user} currentUserRole={userInfo.user_role} refresh={props.refresh} />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default UserInfoDialog