import React from 'react'
import './AlertsContainer.css'

function AlertsContainer(props) {

    return (
        <div className='alerts-container-main-div'>
            {props.data.alerts.events.length > 0 &&
                <div className='alerts-div'>
                    <p>The following events does not have any episodes:</p>
                    <div className='alert-inner-div'>
                        {props.data.alerts.events.map(alert => {
                            return (
                                <p key={alert.id}>{alert.title}</p>
                            )
                        })}
                    </div>
                </div>
            }
            {props.data.alerts.episodes.length > 0 &&
                <div className='alerts-div'>
                    <p>The following episodes does not have any speaker:</p>
                    <div className='alert-inner-div'>
                        {props.data.alerts.episodes.map(alert => {
                            return (
                                <p key={alert.id}>{alert.title}</p>
                            )
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

export default AlertsContainer