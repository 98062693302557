import { getEntity } from "../API/requests"

export let industries = []

export const getIndustries = (ids) => {
    if (ids === null) return []
    const idsArray = ids.split(',')
    return industries.filter(indust => idsArray.includes(indust.id.toString()))
}

export async function fetchIndustries(force) {

    if (industries.length > 0 && !force) return industries;

    getEntity('industries').then(res => {
        industries = [...res.data]
        return [...res.data]
    }, err => {
        console.log(err)
    })
}