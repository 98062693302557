import React, { useMemo, useEffect, useState } from 'react'
import TablePrefab from './TablePrefab';
import { BooleanComponent, DateBodyComponent, LanguageComponent, LinkComponent, ResponsibleAvatar, StatusBodyComponent, SimpleComponent, TitleComponent } from './TableComponents/RowsComponents'
import { DateRangeColumnFilter, dateBetweenFilterFn } from './TableComponents/DateFilter'
import moment from 'moment'
import useEventsDialog from '../../customHooks/Dialogs/useEventsDialog';

import './EpisodesTable.css'

const statusFilterOptions = [
    { text: 'Delivered', value: 'Delivered' },
    { text: 'In progress', value: 'In Progress' },
    { text: 'Completed', value: 'Completed' },
    { text: 'Planned', value: 'Planned' },
]

function EpisodesTable(props) {
    const [events, setEvents] = useState([])
    const { openEditDialog, openDeleteDialog, openViewMore } = useEventsDialog();

    useEffect(() => {
        setEvents(props.events)
    }, [props.events])


    const onEdit = (data) => {
        openEditDialog(data.row.original, () => props.refresh())
    }

    const onView = (data) => {
        openViewMore(data.row.original)
    }

    const onDelete = (data) => {
        openDeleteDialog(data.row.original, () => props.refresh())
    }

    //Se houver valores null tem de se usar accessorFn e passa a empty strings para não dar erro nos filtros
    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                size: 50,
                columnFilterModeOptions: ['equals', 'between', 'betweenInclusive', 'greaterThan', 'lessThan'],
                filterFn: 'equals',
                enableGrouping: false,
            },
            {
                accessorKey: "title",
                header: "Title",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                enableGrouping: false,
                enableHiding: false,
                Cell: ({ renderedCellValue }) => <TitleComponent value={renderedCellValue} />
            },
            {
                accessorFn: (data) => data.location ?? '',
                id: 'location',
                header: "Location",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.language ?? '',
                id: 'language',
                header: "Language",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                Cell: ({ renderedCellValue }) => renderedCellValue && <LanguageComponent language={renderedCellValue} />
            },
            {
                accessorFn: (data) => data.region ?? '',
                id: 'region',
                header: "Region",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.organization ?? '',
                id: 'organization',
                header: "Organization",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
            },
            {
                accessorFn: (data) => data.responsible ?? '',
                id: 'responsible',
                header: "Responsible",
                columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith'],
                filterFn: 'fuzzy',
                Cell: ({ cell }) => <ResponsibleAvatar cell={cell} />
            },
            {
                accessorFn: (data) => data.status ?? '',
                id: 'status',
                header: "Status",
                filterVariant: 'select',
                filterSelectOptions: statusFilterOptions,
                enableGlobalFilter: false,
                enableColumnFilterModes: false,
                Cell: ({ renderedCellValue }) => renderedCellValue && <StatusBodyComponent value={renderedCellValue} />
            },
            {
                accessorKey: "salesforce_url",
                header: "Salesforce",
                enableGrouping: false,
                enableColumnFilter: false,
                Cell: ({ renderedCellValue }) => renderedCellValue && <LinkComponent url={renderedCellValue} name='Click to open' />
            },
            {
                accessorKey: "has_episodes",
                header: "Has Episodes?",
                filterVariant: 'checkbox',
                Cell: ({ renderedCellValue }) => <BooleanComponent boolean={renderedCellValue} />
            },
            {
                accessorFn: (data) => data.episodes ? data.episodes.length : 0,
                id: 'episodes',
                header: "Nº Episodes",
                columnFilterModeOptions: ['equals', 'between', 'betweenInclusive', 'greaterThan', 'lessThan'],
                filterFn: 'equals',
                enableGrouping: false,
                Cell: ({ renderedCellValue }) => <SimpleComponent value={renderedCellValue} />
            },
            {
                accessorKey: "start_date",
                header: "Start Date",
                Cell: DateBodyComponent,
                enableGlobalFilter: false,
                enableGrouping: false,
                enableColumnFilterModes: false,
                Filter: DateRangeColumnFilter,
                filterFn: (row, id, filterValues) => dateBetweenFilterFn(row, filterValues, 'start_date'),

            },
            {
                accessorKey: "end_date",
                header: "End Date",
                Cell: DateBodyComponent,
                enableGlobalFilter: false,
                enableGrouping: false,
                enableColumnFilterModes: false,
                Filter: DateRangeColumnFilter,
                filterFn: (row, id, filterValues) => dateBetweenFilterFn(row, filterValues, 'end_date'),
            },
        ],
        []
    );

    const onExportData = () => {
        const dataToExport = events.map(el => {
            return {
                "ID": el.id,
                "Title": el.title,
                "Description": el.description,
                "Location": el.location,
                "Language": el.language,
                "Region": el.region,
                "Organization": el.organization,
                "Status": el.status,
                "Has Episodes?": el.has_episodes,
                "Number of Episodes": el.episodes.length,
                "Salesforce Link": el.salesforce_url,
                "Responsible": el.responsible ? el.responsible.split(',')[0] : '',
                "Start Date": moment(el.start_date).format('DD MMMM YYYY'),
                "End Date": moment(el.end_date).format('DD MMMM YYYY'),
                "Topics": el.topics.map(topic => topic.name).join(', '),
                "Industries": el.industries.map(indust => indust.name).join(', '),
                "Formats": el.formats.map(format => format.name).join(', '),
                "Type": el.types.map(type => type.name).join(', '),
                "Views": el.views
            }
        })

        return dataToExport
    }

    return (
        <TablePrefab
            settingsId='eventsTable'
            roleId='Event'
            columns={columns}
            data={events}
            exportData={onExportData()}
            title='Events'
            enableGrouping
            enablePinning
            enableColumnDragging
            enableColumnOrdering
            export
            adicionalState={{ isLoading: props.loading }}
            enableColumnFilterModes
            onRowView={onView}
            onRowEdit={onEdit}
            onRowDelete={onDelete}
            renderDetailPanel={({ row }) => <DetailedPanel event={row.original} />}
        />
    )
}

export default EpisodesTable

const DetailedPanel = ({ event }) => {

    return (
        <div className='detailed-panel-main-div'>
            <div className='detailed-panel-chips'>
                <div className='detailed-panel-chip'>
                    <p>Highlighted: <span>{event.highlighted ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Available: <span>{event.available ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Private: <span>{event.private ? 'Yes' : 'No'}</span></p>
                </div>
                <div className='detailed-panel-chip'>
                    <p>Online: <span>{event.online ? 'Yes' : 'No'}</span></p>
                </div>
                {event.event_page &&
                    <div className='detailed-panel-chip'>
                        <p>External Page: <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>{event.event_page}</span></p>
                    </div>
                }
            </div>
            <div className='detailed-panel-description'>
                <p style={{ fontWeight: '700' }}>Description:</p>
                <p>{event.description}</p>
            </div>
            <div className='detailed-panel-info'>
                {event.formats?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Format: <span>{event.formats.map(format => format.name).join(', ')}</span></p>
                    </div>
                }
                {event.types?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Type: <span>{event.types.map(type => type.name).join(', ')}</span></p>
                    </div>
                }
                {event.industries?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Industries: <span>{event.industries.map(ind => ind.name).join(', ')}</span></p>
                    </div>
                }
                {event.topics?.length > 0 &&
                    <div className='detailed-panel-chip'>
                        <p>Topics: <span>{event.topics.map(topic => topic.name).join(', ')}</span></p>
                    </div>
                }
            </div>
        </div>
    )
}