import React, { useState } from 'react';
import { Drawer, Toolbar, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { MainListItems, SecondaryListItems, BottomListItems } from './ListItems'
import Logo from '../../../assets/logo.svg'

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        '& .MuiDrawer-paper': {
            position: 'fixed',
            whiteSpace: 'nowrap',
            height: '100vh',
            width: '72px',
            alignItems: 'center',
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            overflowX: 'hidden',
            background: 'linear-gradient(to top, var(--orange), #E8954E)',
            boxShadow: '1px 0px 7px -1px rgba(0,0,0,0.4)',
            border: 'none',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
        },
    }),
);

function SidePanel() {
    const location = useLocation()
    const [activeTab, setActiveTab] = useState(location.pathname.split('/')[2])

    const handleTabNavigation = (data) => {
        setActiveTab(data);
    }

    return (
        <div>
            <SideDrawer variant='permanent'>
                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifycontent: 'flex-end', px: [1] }}>
                    <img alt='logo' src={Logo} lable='Leaders Insight Logo' height='auto' width='100%' />
                </Toolbar>
                <MainListItems activeTab={activeTab} setActiveTab={handleTabNavigation} />
                <Divider sx={{ backgroundColor: 'white', width: '80%'}} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <SecondaryListItems activeTab={activeTab} setActiveTab={handleTabNavigation}  />
                    <BottomListItems activeTab={activeTab} setActiveTab={handleTabNavigation}  />
                </div>
            </SideDrawer>
        </div>
    )
}

export default SidePanel;