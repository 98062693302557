import React from "react";
import { Tooltip, Divider } from "@mui/material";
import { Delete, Restore } from '@mui/icons-material'

import './TableCustomToolbar.css'

const TableCustomToolbar = ({ visible, deleteCb, restoreCb }) => {

    return (
        <>
            {visible &&
                <div className="custom-toolbar">
                    <Tooltip title={"Restore Selected Items"}>
                        <div className="custom-toolbar-btn" onClick={restoreCb}>
                            <Restore />
                        </div>
                    </Tooltip>
                    <Tooltip title={"Delete Selected Items"}>
                        <div className="custom-toolbar-btn" onClick={deleteCb}>
                            <Delete />
                        </div>
                    </Tooltip>
                    <Divider orientation="vertical" sx={{ height: '25px', backgroundColor: 'white', marginLeft:'5px'}} />
                </div>
            }
        </>
    );

}

export default TableCustomToolbar
