import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)({
    width: '100%',
    height: '20px',
    // backgroundColor: 'red',
    transition: 'all 0.2s ease-in-out',
    '& input': {
        padding: '0',
        height: '100%',
        fontSize: '15px',
        letterSpacing: '0'
    },
    '&:hover': {

    },
    '& .MuiInputBase-root': {
        borderRadius: '0px',
        fontFamily: 'ropa-mix-pro, sans-serif',
        fontWeight: '300',
        fontSize: '20px',
        letterSpacing: '1px',
        '&.Mui-focused': {

        },
    },
    '& fieldset': {
        borderColor: 'transparent !important',
    },
    '& .MuiButtonBase-root': {
        scale: "0.8",
        position: "absolute",
        bottom: "-10px",
        right: "2px",
    }


})

function TableDatePicker({ params, ...props }) {
    return (
        <StyledTextField {...params} {...props} variant='standard' />
    )
}

export default TableDatePicker