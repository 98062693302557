import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { styled } from '@mui/material/styles';

function GroupedButtons({ currentValue, setValue, buttons, style }) {

    let StyledToggleButtonGroup;

    if (style) {
        StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => (style))
    } else {
        StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
            '& .MuiToggleButton-primary': {
                color: 'rgb(21 118 200 / 39%)',
                border: '1px solid #174779',
                transition: 'all 0.3s ease-in-out',
                height: '30px',
                // minWidth: '100px',
                '&:hover': {
                    borderColor: '#1576c8'
                }
            },
            '& .Mui-selected': {
                color: 'rgba(255,255,255,1)',
            }
        }))
    }

    return (
        <StyledToggleButtonGroup
            color='primary'
            exclusive
            value={currentValue}
            variant='outlined'
            aria-label='outlined button group'
        >
            {buttons.map(button => {
                return (
                    <ToggleButton
                        key={button.label}
                        value={button.value}
                        sx={{ borderBottomColor: `${button.color} !important`, '&.Mui-selected': { color: button.color ? `${button.color} !important` : 'white !important' } }}
                        onClick={() => setValue(button.value)}
                    >
                        {button.label}
                    </ToggleButton>
                )
            })}
        </StyledToggleButtonGroup>
    )
}

GroupedButtons.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        color: PropTypes.string
    }))
}

export default GroupedButtons