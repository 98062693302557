import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { UserProvider } from './context/user/index';

import { SocketContext, socket } from './context/socket/socket'

import LoginPage from './pages/LoginPage'
import PassResetPage from './pages/PassResetPage'


ReactDOM.render(
	<BrowserRouter>
		<UserProvider>
			<SocketContext.Provider value={socket}>
				<Routes>
					<Route path='/login' element={<LoginPage />} />
					<Route path='/resetmypassword' element={<PassResetPage />} />
					<Route path='/app/*' element={<App />} />
					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</SocketContext.Provider>
		</UserProvider>
	</BrowserRouter>,
	document.getElementById('root')
);


