import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { styled } from '@mui/material/styles'
import MoreInfo from '../Dialogs/Industries/MoreInfo'

import { TooltipHintsEvents } from '../../Data/TooltipsHints'

import './EventsTable.css'

const CustomTableBody = (props) => {
    return (
        <div className='custom-table-body' style={props.value ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }}>{props.value.toString()}</div>
    )
}

const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    width: '600px',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        }
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)',
        borderLeft: '5px solid var(--orange-soft)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        cursor: 'pointer',
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    }
})

function IndustriesTable(props) {
    const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false)
    const [selectedIndustry, setSelectedIndustry] = useState(null)

    const getIndustryID = (id) => {
        return props.data.filter(topic => topic.id === id)[0]
    }

    const handleRowClick = (id, e) => {
        setSelectedIndustry(getIndustryID(id))
        setOpenMoreInfoDialog(true)
    }

    const options = {
        filter: true,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: item => handleRowClick(item[0])
    };

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: false } },
        { name: 'name', label: 'Name', options: { filter: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ maxWidth: '225px' }}>{value}</p> } },
        {
            name: 'online', label: 'Online', options: {
                hint: TooltipHintsEvents.online,
                filter: true,
                searchable: false,
                customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} />,
                customFilterListOptions: {
                    render: v => {
                        return `Online? ${v.toString()}`
                    },
                },
            }
        },
    ]

    return (
        <div style={{ marginTop: '20px' }}>
            <MuiDataTableStyled
                title={"Industries"}
                data={props.data}
                columns={columns}
                options={options}
            />
            {selectedIndustry && <MoreInfo open={openMoreInfoDialog} close={() => setOpenMoreInfoDialog(false)} industry={selectedIndustry} refresh={props.refresh} />}
        </div>
    )
}

export default IndustriesTable