import React, { useState, useEffect } from 'react'
import GroupedButtons from '../components/Buttons/GroupedButtons'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import EventsTable from '../components/Tables/trash/EventsTable'
import EpisodesTable from '../components/Tables/trash/EpisodesTable'
import SpeakersTable from '../components/Tables/trash/SpeakersTable'
import AboutTable from '../components/Tables/trash/AboutTable'
import TopicsTable from '../components/Tables/trash/TopicsTable'
import FaqTable from '../components/Tables/trash/FaqTable'
import IndustriesTable from '../components/Tables/trash/IndustriesTable'
import useUserInfo from '../customHooks/useUserInfo'
import { getUserPrefs, setUserPrefs } from '../components/user/UserPrefs'

import useGlobalSnackbar from '../components/Snackbar/useGlobalSnackbar';
import useDialog from '../components/Dialogs/DialogHooks/useDialog'

import './css/Trash.css'
import { getEntity } from '../API/requests'

const groupButtonsStyle = {
	'& .MuiToggleButton-primary': {
		color: 'var(--light-blue)',
		border: '1px solid #174779',
		transition: 'all 0.3s ease-in-out',
		height: '40px',
		// minWidth: '100px',
		'&:hover': {
			backgroundColor: 'var(--light-blue)',
			color: 'white',
		}
	},
	'& .Mui-selected': {
		color: 'rgba(255,255,255,1)',
		backgroundColor: 'var(--light-blue) !important',
		'&:hover': {
			backgroundColor: 'var(--light-blue)'
		}
	}
}

const viewsButtons = [
	{ label: 'Events', value: 'events' },
	{ label: `Episodes`, value: 'episodes' },
	{ label: 'Speakers', value: 'speakers' },
	{ label: 'About Sections', value: 'about' },
	{ label: 'Topics', value: 'topics' },
	{ label: 'FAQ', value: 'faq' },
	{ label: 'Industries', value: 'industries' },
]


function RecycleBin() {
	const [trash, setTrash] = useState()
	const [refresh, setRefresh] = useState(false)
	const [view, setView] = useState(getUserPrefs('recycle').view.view)
	const { triggerSnackbar } = useGlobalSnackbar();
	const { openInfoDialog, openErrDialog } = useDialog()
	const { userInfo } = useUserInfo()
	const navigate = useNavigate()

	const viewsConfig = [
		{ name: 'events', component: () => <EventsTable data={trash.events} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'episodes', component: () => <EpisodesTable data={trash.episodes} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'speakers', component: () => <SpeakersTable data={trash.speakers} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'about', component: () => <AboutTable data={trash.about} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'topics', component: () => <TopicsTable data={trash.topics} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'faq', component: () => <FaqTable data={trash.faq} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
		{ name: 'industries', component: () => <IndustriesTable data={trash.industries} refresh={() => setRefresh(!refresh)} restore={handleRestore} delete={handleDelete} /> },
	]

	useEffect(() => {
		const fetch = async () => {
			getEntity('trash').then(res => {
				setTrash(res.data)
			}, err => {
				console.log(err)
			})
		}

		if (!userInfo.user_role.canManageTrash) {
			navigate('/app/dashboard', { replace: true })
		} else {
			fetch();
		}
	}, [refresh])

	const handleDelete = (entity, ids) => {
		const infoDialogData = { title: 'Are you sure?', message: `Are you sure you want to delete ${entity} ${ids.join(', ')}?` };
		openInfoDialog(infoDialogData, 'y/n', () => deleteEntity(entity, ids))
	}

	const handleRestore = (entity, ids) => {
		const infoDialogData = { title: 'Are you sure?', message: `Are you sure you want to restore ${entity} ${ids.join(', ')}?` };
		openInfoDialog(infoDialogData, 'y/n', () => restoreEntity(entity, ids))
	}

	const handleRemoveAll = () => {
		const infoDialogData = { title: 'Are you sure?', message: `Are you sure you want to remove all element on the trash ?` };
		openInfoDialog(infoDialogData, 'y/n', () => removeAll())
	}

	const deleteEntity = (entity, ids) => {
		const fetchConfig = {
			headers: {
				"Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
				"requesting-user": `bo_${sessionStorage.getItem('id')}`
			}
		}

		axios.put(`${process.env.REACT_APP_SERVER_URL}/api/trash/delete`, { ids: ids, entity: entity }, fetchConfig)
			.then((res) => {
				setRefresh(!refresh)
				triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
				console.log(res)
			}, (err) => {
				console.log(err)
				openErrDialog(err.response ? err.response : err)
			});
	}

	const restoreEntity = (entity, ids) => {
		const fetchConfig = {
			headers: {
				"Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
				"requesting-user": `bo_${sessionStorage.getItem('id')}`
			}
		}

		axios.put(`${process.env.REACT_APP_SERVER_URL}/api/trash/restore`, { ids: ids, entity: entity }, fetchConfig)
			.then((res) => {
				setRefresh(!refresh)
				triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
				console.log(res)
			}, (err) => {
				console.log(err)
				openErrDialog(err.response ? err.response : err)
			});
	}

	const removeAll = () => {

		const fetchConfig = {
			headers: {
				"Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
				"requesting-user": `bo_${sessionStorage.getItem('id')}`
			}
		}

		axios.put(`${process.env.REACT_APP_SERVER_URL}/api/trash/empty`, {}, fetchConfig)
			.then(res => {
				setRefresh(!refresh)
				triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
			}, err => {
				console.log(err)
				openErrDialog(err.response ? err.response : err)
			})
	}

	return (
		<div className='trash-page-main-div'>
			<div className='trash-page-top-div'>
				<EventsTopInfo title='Events' value={trash?.events.length} />
				<EventsTopInfo title='Episodes' value={trash?.episodes.length} />
				<EventsTopInfo title='Speakers' value={trash?.speakers.length} />
				<EventsTopInfo title='About Section' value={trash?.about.length} />
				<EventsTopInfo title='Topics' value={trash?.topics.length} />
				<EventsTopInfo title={`FAQ's`} value={trash?.faq.length} />
				<EventsTopInfo title='Industries' value={trash?.industries.length} />
			</div>
			<div className='trash-page-bottom-div'>
				<div className='bottom-div-trash-wrapper'>
					<GroupedButtons currentValue={view} setValue={(value) => { setView(value); setUserPrefs('recycle', 'view', 'view', value) }} buttons={viewsButtons} style={groupButtonsStyle} />
					<button className='recycle-remove-all-btn' onClick={handleRemoveAll}>Empty trash bin</button>
					{trash && viewsConfig.map(entity => {
						return (
							<div key={`${entity.name}-trash-table`} className={`bottom-div-trash ${view === entity.name ? '' : 'hidden'}`}>
								{entity.component()}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

const EventsTopInfo = ({ title, value }) => {
	return (
		<div className='trash-page-top-info'>
			<p className='trash-page-top-info-title'>{title}</p>
			<p className='trash-page-top-info-value'>{value}</p>
		</div>
	)
}


export default RecycleBin