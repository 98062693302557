import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { InputBase, Select, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Search as SearchIcon, ArrowUpward } from '@mui/icons-material'
import { debounce } from '../../utils'
import sortArray from 'sort-array'

import './Search.css'
import { setUserPrefs } from '../user/UserPrefs'

const StyledSelect = styled(Select)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    height: '50%',
    marginRight: '5px',
    backgroundColor: 'rgba(81,92,110,1)',
    '&:hover': {
        backgroundColor: 'rgba(81,92,110, 0.9)'
    },
    '& > .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: '15px',
        width: '100%',
        color: '#9aa0ab'
    },
    '& > .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        outline: 'none'
    },
    '& > .MuiSvgIcon-root': {
        color: 'white'
    }
}))

const SearchInput = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    height: '50%',
    backgroundColor: 'rgba(81,92,110,1)',
    '&:hover': {
        backgroundColor: 'rgba(81,92,110, 0.9)'
    },
    marginLeft: 0,
    marginRight: '25px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function Search({ entity, refresh, data, orderFields, setParentData, defaultOrderDirection, entitySearchProperty, order }) {
    const [orderField, setOrderField] = useState(order);
    const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);
    const [searchString, setSearchString] = useState('')

    const debounceSearchData = debounce((e) => {
        searchData(e)
    }, 300)

    const orderData = (field) => {
        setOrderField(field)
        const sortedArray = sortArray([...data], { by: field, order: orderDirection })
        setParentData(sortedArray)
    }

    const searchData = (searchString) => {
        setSearchString(searchString)
        if(searchString.length === 0) setParentData([...data])
        const filteredData = data.filter(elem => elem[entitySearchProperty].toLowerCase().includes(searchString.toLowerCase()))
        setParentData(filteredData)
    }

    useEffect(() => {
        searchData(searchString)
    },[refresh])

    useEffect(() => {
        orderData(orderField)
    }, [orderDirection])

    return (
        <div className='search-main-div'>
            <div className='search-input'>
                <SearchInput>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(a) => debounceSearchData(a.target.value)}
                    />
                </SearchInput>
            </div>
            <div className='order-input'>
                <p style={{ marginRight: '5px' }}>Order By:</p>
                <StyledSelect
                    value={orderField}
                    label='Order by'
                    onChange={value => { orderData(value.target.value); setUserPrefs([entity], 'order', 'order', value.target.value)  }}
                    autoWidth
                >
                    {orderFields.map(field => {
                        return (
                            <MenuItem key={field.name} value={field.value}>{field.name}</MenuItem>
                        )
                    })}
                </StyledSelect>
                {orderDirection === 'asc' ? (
                    <ArrowUpward sx={{ color: 'rgba(81,92,110,1)', cursor: 'pointer' }} onClick={() => setOrderDirection('desc')} />
                ) : (
                    <ArrowUpward sx={{ color: 'rgba(81,92,110,1)', cursor: 'pointer', transform: 'rotate(180deg)' }} onClick={() => setOrderDirection('asc')} />
                )}
            </div>
        </div>
    )
}

Search.propTypes = {
    data: PropTypes.array.isRequired,
    orderFields: PropTypes.array.isRequired,
    setParentData: PropTypes.func.isRequired,
    refresh: PropTypes.bool,
    defaultOrderDirection: PropTypes.oneOf(['asc','desc']).isRequired,
    entitySearchProperty: PropTypes.string.isRequired
}

export default Search