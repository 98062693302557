import React from 'react'
import { Tooltip } from '@mui/material'

function CustomTooltip({ title, interactive, onFocusListener, children, ...props }) {
    return (
        <Tooltip
            title={title}
            disableInteractive={!interactive}
            disableFocusListener={!onFocusListener}
            {...props}
        >
            {children}
        </Tooltip>
    )
}

export default CustomTooltip