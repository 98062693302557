import React, { useState, useEffect } from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import { Divider } from '@mui/material'
import './Review.css'

function Review(props) {
	const [state, setState] = useState()
	
	useEffect(() => {
		setState(props.state)
	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='speaker-review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review information before submitting</p>
			{state &&
				<div className='speaker-review-scrollable-div'>
					<div className='speaker-review-label-div'>
						<p className='speaker-review-label'>Name</p>
						<p className='speaker-review-text'>{state.name}</p>
					</div>
					<div className='speaker-review-label-div'>
						<p className='speaker-review-label'>Job Title</p>
						<p className='speaker-review-text'>{state.job_title}</p>
					</div>
					{state.video &&
						<div className='speaker-review-label-div'>
							<p className='speaker-review-label'>LinkedIn</p>
							<p className='speaker-review-text'>{state.social_links}</p>
						</div>
					}
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='speaker-review-chips-div'>
						<BooleanChip value={state.online} label='Online?' tooltip='online' />
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='speaker-review-images-div'>
						{state.portrait ? (
							<img alt='landscape-preview' src={getImageBlob(state.portrait)} width='auto' height='100%' />
						) : (
							<p>No image provided</p>
						)}
					</div>
				</div>
			}
		</div>
	)
}

export default Review