import React, { useState } from 'react'
import './SmallEpisodeCard.css'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { generateThumbnailImageFromVideoUrl } from '../../../utils'
import { styled } from '@mui/material/styles'
import MoreInfo from '../../Dialogs/Episodes/MoreInfo';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(--light-blue)',
        color: 'white',
        border: 'none',
        fontSize: '12px',
    },
}));


function SmallEpisodeCard(props) {
    const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false)

    return (
        <>
            <StyledTooltip title={<TooltipContent episode={props.episode} />} placement='right' enterDelay={300} enterNextDelay={300}>
                <div className='small-episode-card-container'
                    onClick={() => setOpenMoreInfoDialog(true)}
                    style={props.episode.image ?
                        { backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/${props.episode.image})` } :
                        { backgroundImage: `url(${generateThumbnailImageFromVideoUrl(props.episode.video)})` }
                    }
                >
                    <div className='small-episode-card-gradient'>
                        <p className='small-episode-card-title'>{props.episode.title}</p>
                    </div>
                </div>
            </StyledTooltip>
            <MoreInfo event_title={props.event_title} hideActions={true} open={openMoreInfoDialog} close={() => setOpenMoreInfoDialog(false)} data={props.episode} refresh={props.refresh} />
        </>
    )
}

const TooltipContent = ({ episode }) => {
    return (
        <div>
            <div style={{ display: 'flex' }}><p style={{ fontWeight: '600', marginRight: '5px' }}>Title:</p><p>{episode.title}</p></div>
            <div style={{ display: 'flex' }}><p style={{ fontWeight: '600', marginRight: '5px' }}>Featured:</p><p>{episode.featured.toString()}</p></div>
            <div style={{ display: 'flex' }}><p style={{ fontWeight: '600', marginRight: '5px' }}>Force:</p><p>{episode.force.toString()}</p></div>
            <div style={{ display: 'flex' }}><p style={{ fontWeight: '600', marginRight: '5px' }}>Online:</p><p>{episode.online.toString()}</p></div>
            <div style={{ display: 'flex' }}><p style={{ fontWeight: '600', marginRight: '5px' }}>Private:</p><p>{episode.private.toString()}</p></div>
        </div>
    )
}

export default SmallEpisodeCard