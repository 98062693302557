import React from 'react';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import InfoDialog from '../components/Dialogs/InfoDialog';
import ErrorDialog from '../components/Dialogs/ErrorDialog';
import axios from 'axios'
import { debounce } from '../utils';
import { Navigate } from 'react-router-dom';

import './css/ForgotPassword.css'

class PassResetPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userName: '',
			userEmail: '',
			userSecret: '',
			newPassword: '',
			confirmPassword: '',
			newPasswordIsValid: false,
			confirmPasswordIsValid: false,
			formIsValid: false,
			formErrors: { newPassword: '', confirmPassword: '' },
			showNewPassword: false,
			showConfirmPassword: false,
			openErrDialog: false,
			openInfoDialog: false,
			errDialogInfo: '',
			infoDialogData: '',
			submitting: false,
			fireRedirect: false
		}
	}

	componentDidMount() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString)

		if (urlParams.get('email') === null || urlParams.get('secret') === null) {
			this.setState({ fireRedirect: true })
		}

		axios.get(`${process.env.REACT_APP_SERVER_URL}/api/backoffice_users/getbyemail/${urlParams.get('email')}`)
			.then(res => {
				this.setState({ userName: res.data.name ?? res.data.email})
				if (res.data.pass_recovery_key !== urlParams.get('secret')) {
					this.setState({ fireRedirect: true })
				}
			}, err => {
				this.setState({ fireRedirect: true })
			})

		this.setState({
			userEmail: urlParams.get('email'),
			userSecret: urlParams.get('secret')
		})
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let newPasswordIsValid = this.state.newPasswordIsValid;
		let confirmPasswordIsValid = this.state.confirmPasswordIsValid;

		switch (name) {
			case 'newPassword':
				newPasswordIsValid = new RegExp(/^(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
				fieldValidationErrors.newPassword = newPasswordIsValid ? '' : 'Password must have at least 2 Uppercase Letters, 2 numbers and more than 8 characters'
				break;
			case 'confirmPassword':
				confirmPasswordIsValid = this.state.newPassword === this.state.confirmPassword;
				fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : 'Passwords do not match'
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			newPasswordIsValid,
			confirmPasswordIsValid,
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.newPasswordIsValid &&
				this.state.confirmPasswordIsValid
		})
	}

	handleSubmit = () => {
		this.setState({ submitting: true })

		axios.post(`${process.env.REACT_APP_SERVER_URL}/api/backoffice_users/resetpassword`, {
			email: this.state.userEmail,
			password: this.state.newPassword,
			secretKey: this.state.userSecret,
			secure: true
		}).then(res => {
			this.setState({ infoDialogData: { title: 'Success', message: res.data } }, () => {
				this.setState({ openInfoDialog: true, submitting: false })
			})
		}, err => {
			console.log(err)
			this.setState({ errDialogInfo: err.response ? err.response : err }, () => {
				this.setState({ openErrDialog: true, submitting: false })
			})
		});
	}

	render() {
		return (
			<div className='forgot-password-main-div' >
				<div className='forgot-password-card-div'>
					<p>{`Hello ${this.state.userName}, set your new password`}</p>
					<TextField
						required
						id='newPassword'
						color='secondary'
						name='newPassword'
						label='New Password'
						sx={{ width: '80%', margin: '20px 0' }}
						variant='standard'
						type={this.state.showNewPassword ? 'text' : 'password'}
						value={this.state.newPassword}
						onChange={(e) => this.handelInput(e)}
						error={!this.state.newPasswordIsValid}
						helperText={this.state.formErrors.newPassword}
						InputProps={{
							endAdornment: <InputAdornment position="end">
								<IconButton tabIndex={-1} onClick={() => this.setState({ showNewPassword: !this.state.showNewPassword })} >
									{this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>,
						}}
					/>
					<TextField
						required
						id='confirmPassword'
						color='secondary'
						name='confirmPassword'
						label='Confirm Password'
						variant='standard'
						sx={{ width: '80%', margin: '20px 0' }}
						type={this.state.showConfirmPassword ? 'text' : 'password'}
						value={this.state.confirmPassword}
						onChange={(e) => this.handelInput(e)}
						error={!this.state.confirmPasswordIsValid}
						helperText={this.state.formErrors.confirmPassword}
						InputProps={{
							endAdornment: <InputAdornment position="end">
								<IconButton tabIndex={-1} onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} >
									{this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>,
						}}
					/>
					<div style={{ margin: '10px 0' }}>
						<Button disabled={!this.state.formIsValid} variant='standard' onClick={this.handleSubmit}>Submit</Button>
					</div>
					<ErrorDialog
						open={this.state.openErrDialog}
						close={() => this.setState({ openErrDialog: false, fireRedirect: true })}
						ocb={() => this.setState({ openErrDialog: false, fireRedirect: true })}
						info={this.state.errDialogInfo}
					/>
					<InfoDialog
						open={this.state.openInfoDialog}
						close={() => this.setState({ InfoDialog: false, fireRedirect: true })}
						ocb={() => this.setState({ InfoDialog: false, fireRedirect: true })}
						info={this.state.infoDialogData}
					/>
					{this.state.fireRedirect &&
						<Navigate to='/' replace={true} />
					}
				</div>
			</div>
		);
	}
}

export default PassResetPage;
