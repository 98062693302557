import React, { Component } from 'react'
import { IconButton, TextField, InputAdornment, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { debounce } from '../../../utils'
import ErrorDialog from '../ErrorDialog';
import InfoDialog from '../InfoDialog';
import { editEntity, getEntity } from '../../../API/requests';

export default class UserProfileEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data.name,
            email: this.props.data.email,
            password: '',
            nameIsValid: true,
            emailIsValid: true,
            passwordIsValid: false,
            formIsValid: false,
            showPassword1: false,
            openInfoDialog: false,
            openErrDialog: false,
            infoDialogData: '',
            errDialogInfo: '',
            formErrors: { name: '', email: '' },
            userType: this.props.data.userRoleId,
            usersRoles: []
        }
    }

    componentDidMount() {
        getEntity('roles').then(res => {
            this.setState({ usersRoles: res.data })
        }, err => {
            console.log(err)
        })
    }

    changesDetected = () => {
        return this.state.name !== this.props.data.name ||
            this.state.email !== this.props.data.email ||
            this.state.userType !== this.props.data.userRoleId
    }

    handelInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let passwordIsValid = this.state.passwordIsValid;

        switch (name) {
            case 'name':
                nameIsValid = value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid name`}`
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid email`}`
                break;
            case 'password':
                passwordIsValid = value.length > 7;
                fieldValidationErrors.password = passwordIsValid ? '' : `Type your password to confirm your identity`
                break;
            default: return;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid,
            emailIsValid,
            passwordIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.passwordIsValid &&
                this.state.userType !== ''
        })
    }

    handleSubmit = () => {

        const data = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            userRoleId: this.state.userType
        }

        editEntity('backoffice_users', this.props.data.id, data).then(res => {
            this.setState({ infoDialogData: { title: 'Success', message: res.data.message } }, () => {
                this.setState({ openInfoDialog: true, submitting: false, password: '', passwordIsValid: false, formIsValid: false })
            })
        }, err => {
            console.log(err)
            this.setState({ errDialogInfo: err.response ? err.response : err }, () => {
                this.setState({ openErrDialog: true, submitting: false, password: '', passwordIsValid: false, formIsValid: false })
            })
        })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.state.formIsValid && this.changesDetected()) {
                this.handleSubmit()
            }
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px 0' }}>
                <TextField
                    required
                    id='name'
                    color='secondary'
                    name='name'
                    label='Name'
                    sx={{ width: '80%', margin: '5px 0' }}
                    variant='standard'
                    type='text'
                    size='small'
                    value={this.state.name}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.nameIsValid}
                    helperText={this.state.formErrors.name}
                />
                <TextField
                    required
                    id='email'
                    color='secondary'
                    name='email'
                    label='Email'
                    variant='standard'
                    size='small'
                    sx={{ width: '80%', margin: '5px 0' }}
                    type='email'
                    value={this.state.email}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.emailIsValid}
                    helperText={this.state.formErrors.email}
                />
                {this.props.currentUserRole.canEditBackofficeUser &&
                    <FormControl variant="standard" sx={{ minWidth: 220, marginTop: '10px' }}>
                        <InputLabel id="user type select">User type</InputLabel>
                        <Select
                            labelId="user type select"
                            id="user type select"
                            name='userType'
                            size='small'
                            value={this.state.userType}
                            onChange={(e) => this.handelInput(e)}
                            error={this.state.userType === ''}
                            label="User type"
                        >
                            {this.state.usersRoles.map(role => {
                                return (
                                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                }
                <TextField
                    required
                    id='password'
                    color='secondary'
                    name='password'
                    label='Password'
                    variant='standard'
                    size='small'
                    sx={{ width: '80%', margin: '10px 0' }}
                    type={this.state.showPassword1 ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.passwordIsValid}
                    onKeyPress={this.handleKeyPress}
                    helperText='Type your current password to confirm your identity'
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton tabIndex={-1} onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })} >
                                {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <div style={{ margin: '10px 0' }}>
                    {this.changesDetected() ? (
                        <Button disabled={!this.state.formIsValid} variant='standard' onClick={this.handleSubmit}>Submit</Button>
                    ) : (
                        <p>No changes detected</p>
                    )}
                </div>
                <ErrorDialog
                    open={this.state.openErrDialog}
                    close={() => this.setState({ openErrDialog: false })}
                    ocb={() => this.setState({ openErrDialog: false })}
                    info={this.state.errDialogInfo}
                />
                <InfoDialog
                    open={this.state.openInfoDialog}
                    close={() => { this.setState({ openInfoDialog: false }); this.props.refresh() }}
                    ocb={() => { this.setState({ openInfoDialog: false }); this.props.refresh() }}
                    info={this.state.infoDialogData}
                />
            </div>
        )
    }
}
