import React, { useEffect, useState } from 'react'
import './Images.css'
import { DropzoneDialog } from 'material-ui-dropzone';
import { Divider, Tooltip, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { InfoTooltips } from '../../../../Data/TooltipsHints';
import { Help } from '@mui/icons-material';

const ImageUploadDialog = props => {
	return (
		<DropzoneDialog
			acceptedFiles={['image/*']}
			cancelButtonText='Cancel'
			submitButtonText='Submit'
			filesLimit={1}
			maxFileSize={1000000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeProfilePic(file); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Image'
			maxWidth='xs'
		/>
	)
}

function Images(props) {
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState()
	const [imageInfo, setImageInfo] = useState({})
	const [imagePosition, setImagePosition] = useState('')

	useEffect(() => {
		if (props.state.section_image) handleImageUpload(props.state.section_image, 'section_image', false)
		setImagePosition(props.state.image_position)
	}, [])

	const handleImageUpload = (file, type, updateParent) => {

		const picture = URL.createObjectURL(new Blob(file, { type: 'image/png' }))
		const image = new Image();

		image.onload = () => {
			file[0].width = image.width;
			file[0].height = image.height;
			const GCD = findGCD(image.width, image.height);
			file[0].aspectRatio = `${image.width / GCD}:${image.height / GCD}`

			setPreviewImage(picture)
			setImageInfo(file[0])
		}

		image.src = picture;

		if (updateParent) props.updateStateOnParent(type, file)
	}

	const findGCD = (w, h) => {
		if (h === 0) return w
		return findGCD(h, w % h)
	}

	const removeImage = (type) => {
		setPreviewImage(null);
		setImageInfo({})

		props.updateStateOnParent(type, null)
	}

	return (
		<div className='about-images-upload-container'>
			<div className='about-images-portrait-upload-div'>
				<div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<p className='about-image-div-title'>About Image<span style={{ marginLeft: '10px' }}><Tooltip title={InfoTooltips.episodeImage}><Help /></Tooltip></span></p>
					<p className='about-image-div-subtitle'>About image has a recommended aspect ration of 9:16 and a max size of 1Mb</p>
					{props.state.section_image &&
						<FormControl variant="standard" sx={{ minWidth: 220, marginTop: '25px' }}>
							<InputLabel id="image position select">Image Position</InputLabel>
							<Select
								labelId="image position select"
								id="image position select"
								value={imagePosition}
								onChange={(e) => { props.updateStateOnParent('image_position', e.target.value); setImagePosition(e.target.value) }}
								label="Image Position"
							>
								<MenuItem value={'right'}>Right</MenuItem>
								<MenuItem value={'left'}>Left</MenuItem>
							</Select>
						</FormControl>
					}
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				<div className='about-landscape-image-place' onClick={() => setOpenImageDialog(true)}>
					{previewImage ?
						(
							<img className='about-landscape-image-preview' alt='preview' src={previewImage} width='100%' height='100%' />
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p style={{ marginBottom: '10px' }}>Click here to upload Image</p>
								<UploadIcon fontSize='large' />
							</div>
						)
					}
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				{previewImage && <button className='about-remove-image-btn' onClick={() => removeImage('section_image')}>Remove image</button>}
				{imageInfo.name &&
					<div className='about-image-info-div'>
						<p>{`${imageInfo.name}`}</p>
						<p>{`${imageInfo.size} Bytes`}</p>
						<p>{`${imageInfo.width} X ${imageInfo.height} | ${imageInfo.aspectRatio}`}</p>
					</div>
				}
			</div>
			<ImageUploadDialog
				close={() => setOpenImageDialog(false)}
				open={openImageDialog}
				changeProfilePic={(file) => handleImageUpload(file, 'section_image', true)}
			/>
		</div>
	)
}

export default Images