/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { FileDownload, Sync } from '@mui/icons-material'
import CustomTooltip from '../Misc/CustomTooltip'
import { ExportToCsv } from 'export-to-csv';
import Actions from './TableComponents/Actions';
import { IconButton } from '@mui/material';
import { getUserSettings, setUserSettings } from '../user/UserSettings'

import {
    MRT_ToggleDensePaddingButton,
    MRT_FullScreenToggleButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
    MRT_ShowHideColumnsButton,
} from 'material-react-table'

function TablePrefab(props) {
    const [state, setState] = useState(getUserSettings(props.settingsId))
    const [selection, setSelection] = useState({})

    const csvOptions = {
        fieldSeparator: ',',
        decimalSeparator: '.',
        showLabels: true,
        title: props.title,
        filename: `${props.title}_LeaderInsights`,
        useKeysAsHeaders: true,
    };

    useEffect(() => {
        setUserSettings(props.settingsId, state)
    }, [state])

    const csvExporter = new ExportToCsv(csvOptions);

    const headerInternalButtons = ({ table }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '40px', padding: '0 10px' }}>
                {Object.keys(selection).length > 1 &&
                    <div style={{ display: 'flex', gap: '10px', marginRight: '10px', height: '100%' }}>
                        {props.onSelectionExport &&
                            <CustomTooltip title='Export selected lines'>
                                <IconButton disabled={props.actionLoading} onClick={() => props.onSelectionExport(selection)}>
                                    {props.actionLoading ? <Sync /> : <FileDownload />}
                                </IconButton>
                            </CustomTooltip>
                        }
                        {/* <Divider orientation='vertical' /> */}
                    </div>
                }
                <MRT_ToggleGlobalFilterButton table={table} />
                {props.export &&
                    <CustomTooltip title='Export'>
                        <IconButton onClick={() => handleExportData()}>
                            <FileDownload />
                        </IconButton>
                    </CustomTooltip>
                }
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_FullScreenToggleButton table={table} />

            </div>
        )
    }

    const handleExportData = () => {
        csvExporter.generateCsv(props.exportData);
    };

    const onStateChange = (e, entity) => {
        setState((prevState) => ({ ...prevState, [entity]: e instanceof Function ? e(prevState[entity]) : e }))
    }

    return (
        <MaterialReactTable
            onPaginationChange={(e) => onStateChange(e, 'pagination')}
            onColumnVisibilityChange={e => onStateChange(e, 'columnVisibility')}
            onDensityChange={e => onStateChange(e, 'density')}
            onExpandedChange={e => onStateChange(e, 'expanded')}
            onSortingChange={e => onStateChange(e, 'sorting')}
            onShowColumnFiltersChange={e => onStateChange(e, 'showColumnFilters')}
            onColumnPinningChange={e => onStateChange(e, 'columnPinning')}
            onColumnOrderChange={e => onStateChange(e, 'columnOrder')}
            onRowSelectionChange={setSelection}
            getRowId={(originalRow) => originalRow.id}
            state={{ ...state, ...props.adicionalState, rowSelection: selection }}
            columns={props.columns}
            data={props.data}
            enableStickyHeader
            muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 370px)' } }}
            positionActionsColumn="last"
            renderToolbarInternalActions={headerInternalButtons}
            enableRowActions
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    size: 125, //make actions column wider
                },
            }}
            renderRowActions={(row, index) =>
                <Actions
                    onEdit={props.onRowEdit ? () => props.onRowEdit(row) : null}
                    onView={props.onRowView ? () => props.onRowView(row) : null}
                    onDelete={props.onRowDelete ? () => props.onRowDelete(row) : null}
                    roleId={props.roleId}
                    selection={Object.keys(selection).length > 0}
                />
            }
            {...props}
            muiTablePaperProps={{
                sx: {
                    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.5)',
                    borderRadius: '10px'
                },
            }}
            muiBottomToolbarProps={{ //table bottom
                sx: {
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                    boxShadow: '0px -4px 7px -5px #171717',
                }
            }}
            muiTopToolbarProps={{ //Table top
                sx: {
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                }
            }}
            muiTableBodyRowProps={{ //table body cells
                sx: {
                    backgroundColor: 'transparent',
                    '&:hover': {
                        boxShadow: 'inset 0px 0px 2px 0px rgba(0,0,0,0.5)'
                    }
                }
            }}
            muiTableHeadRowProps={{ //table header
                sx: {
                    boxShadow: '0px 0px 6px 0 #171717',
                }
            }}
            muiToolbarAlertBannerProps={{ //top table info
                sx: {
                    backgroundColor: '#b9d9ef',
                }
            }}
            muiTableHeadCellProps={{ //header columns
                sx: {
                    '& > div': {
                        justifyContent: 'space-between'
                    }
                }
            }}
        />
    )
}

export default TablePrefab