export const TooltipHintsEvents = {
    available: 'This marks events as Available or not. Not available Events are represented on the website in a different color and with text informing that the event is coming soon',
    highlighted: 'Highlighted Event is used to show the trailer or image on top of the home page. There can only be one Highlighted event at the same time',
    online: 'Online is a global property that means if the entity of this property is visible on the website or not. Not online entities will not be fetch to the website. This property has no effect on deleted entities, deleted entities will not be visible on the website even if it is marked as online',
    private: 'Private Event means that the event need authentication for the user to be able to see the event page. These Events are represented in a different color if the user is not logged in',
    featured: 'Featured episodes are placed on the Featured section on the website homepage',
    force: 'Force episodes are episodes that are shown no matter what in the user recommendations',
    has_episodes: 'Select this if this event has no episodes, this will require you to fill more information'
}

export const TooltipHintsSeries = {
    online: 'Online is a global property that means if the entity of this property is visible on the website or not. Not online entities will not be fetch to the website. This property has no effect on deleted entities, deleted entities will not be visible on the website even if it is marked as online',
    private: 'Private Series means that the Serie need authentication for the user to be able to see the Serie page. These Series are represented in a different color if the user is not logged in',
}

export const InfoTooltips = {
    episodeImage: `This image is not mandatory, you can skip this step by pressing "next". If no image is provided it will fallback to the video thumbnail, if for instance the episode does not have any video so a thumbnail can't be retrieved, it will fallback to a pre-defined placeholder image.`,
    speakerImage: `This image is not mandatory, you can skip this step by pressing "next". If no image is provided it will fallback to a portrait placeholder.`,
}