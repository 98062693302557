import { getEntity } from "../API/requests"

export let speakers = []

export const getSpeakersFromEpisode = (ids) => {
    if (ids === null) return []
    const idsArray = ids.split(',')
    return speakers.filter(speaker => idsArray.includes(speaker.id.toString()))
}

export const getSpeakersForEvent = (episodes) => {
    if (episodes === null) return [];
    const compiledIds = [...new Set([].concat(...episodes.map(episode => episode.speaker.split(','))))]

    return [...speakers.filter(speaker => compiledIds.includes(speaker.id.toString()))]
}

export async function fetchSpeakers(force) {

    if (speakers.length > 0 && !force) return speakers;
    
    getEntity('speakers').then(res => {
        speakers = [...res.data]
        return [...res.data]
    }, err => {
        console.log(err) 
    })

}