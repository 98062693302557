import { Tooltip } from "@mui/material"
import { TooltipHintsEvents } from "../../Data/TooltipsHints"

import './CustomChip.css'

export const BooleanChip = (props) => {
    const tooltipTitle = TooltipHintsEvents[props.tooltip] ? TooltipHintsEvents[props.tooltip] : 'No tooltip found'
    return (
        <Tooltip title={tooltipTitle} disableInteractive enterDelay={500} enterNextDelay={500}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p>{props.label}</p>
                <div className='custom-chip' style={props.value ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }}>{props.value.toString()}</div>
            </div>
        </Tooltip>
    )
}
