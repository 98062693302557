import React from 'react';

import { ListItem, ListItemIcon, ListItemText, List, ListItemButton } from '@mui/material'
import {
    DashboardOutlined as DashboardIcon,
    EventNoteOutlined as EventIcon,
    OndemandVideoOutlined as EpisodesIcon,
    PeopleAltOutlined as SpeakersIcon,
    PersonOutlineOutlined as UserIcon,
    FormatListBulletedOutlined as ListIcon,
    AppRegistrationOutlined as ActivityIcon,
    HelpOutlineOutlined as HelpIcon,
    Delete as RecycleIcon,
    Assessment as ReportsIcon,
    Dns
} from '@mui/icons-material'

import { Link } from 'react-router-dom'
import useUserInfo from '../../../customHooks/useUserInfo'

const listItemStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }
const listItemStyleActive = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.3)' }
const iconStyle = { display: 'flex', justifyContent: 'center', color: 'white' }
const textStyle = { '&>span': { fontSize: '12px', color: 'white' } }

export const MainListItems = (props) => {
    const { userInfo } = useUserInfo()

    return (
        <List>
            <ListItemButton
                sx={props.activeTab === 'dashboard' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('dashboard')}
                component={Link}
                to={{ pathname: '/app/dashboard' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Dashboard' />
            </ListItemButton>

            {userInfo.user_role.canViewSerie &&
                <ListItemButton
                    sx={props.activeTab === 'series' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('series')}
                    component={Link}
                    to={{ pathname: '/app/series' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <Dns />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Series' />
                </ListItemButton>
            }
            {userInfo.user_role.canViewEvent &&
                <ListItemButton
                    sx={props.activeTab === 'events' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('events')}
                    component={Link}
                    to={{ pathname: '/app/events' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <EventIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Events' />
                </ListItemButton>
            }
            {userInfo.user_role.canViewEpisode &&
                <ListItemButton
                    sx={props.activeTab === 'episodes' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('episodes')}
                    component={Link}
                    to={{ pathname: '/app/episodes' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <EpisodesIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Episodes' />
                </ListItemButton>
            }
            {userInfo.user_role.canViewSpeaker &&
                <ListItemButton
                    sx={props.activeTab === 'speakers' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('speakers')}
                    component={Link}
                    to={{ pathname: '/app/speakers' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <SpeakersIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Speakers' />
                </ListItemButton>
            }
            {/* {userInfo.user_role.canViewUser &&
                <ListItemButton
                    sx={props.activeTab === 'users' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('users')}
                    component={Link}
                    to={{ pathname: '/app/users' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <UserIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Users' />
                </ListItemButton>
            } */}
        </List>
    )
}

export const SecondaryListItems = (props) => {
    const { userInfo } = useUserInfo()

    return (
        <div>
            {userInfo.user_role.canViewLists &&
                <ListItemButton
                    sx={props.activeTab === 'lists' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('lists')}
                    component={Link}
                    to={{ pathname: '/app/lists' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Lists' />
                </ListItemButton>
            }
            {userInfo.user_role.canViewActivity &&
                <ListItemButton
                    sx={props.activeTab === 'activity' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('activity')}
                    component={Link}
                    to={{ pathname: '/app/activity' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <ActivityIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Activity' />
                </ListItemButton>
            }
            {userInfo.user_role.canViewBackofficeUser &&
                <ListItemButton
                    sx={props.activeTab === 'backoffice-users' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('backoffice-users')}
                    component={Link}
                    to={{ pathname: '/app/backoffice-users' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <UserIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Backoffice' />
                </ListItemButton>
            }
        </div>
    )
}

export const BottomListItems = (props) => {
    const { userInfo } = useUserInfo()

    return (
        <div>
            {userInfo.user_role.canViewReport &&
                <ListItemButton
                    sx={props.activeTab === 'reports' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('reports')}
                    component={Link}
                    to={{ pathname: '/app/reports' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <ReportsIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Reports' />
                </ListItemButton>
            }
            {userInfo.user_role.canManageTrash &&
                <ListItemButton
                    sx={props.activeTab === 'recycle' ? listItemStyleActive : listItemStyle}
                    onClick={() => props.setActiveTab('recycle')}
                    component={Link}
                    to={{ pathname: '/app/recycle' }}
                >
                    <ListItemIcon sx={iconStyle}>
                        <RecycleIcon />
                    </ListItemIcon>
                    <ListItemText sx={textStyle} primary='Recycle' />
                </ListItemButton>
            }
            <ListItemButton
                sx={props.activeTab === 'help' ? listItemStyleActive : listItemStyle}
                onClick={() => props.setActiveTab('help')}
                component={Link}
                to={{ pathname: '/app/help' }}
            >
                <ListItemIcon sx={iconStyle}>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText sx={textStyle} primary='Help' />
            </ListItemButton>
        </div>
    )
}