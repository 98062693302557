import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Divider, Tooltip, IconButton } from '@mui/material'
import { DropzoneDialog } from 'material-ui-dropzone';
import FormData from 'form-data'
import moment from 'moment'

import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import { Close } from '@mui/icons-material';

import UserProfileChangePassword from './UserProfileChangePassword';
import UserProfileEdit from './UserProfileEdit';

import Portrait_Placeholder from '../../../assets/portrait_placeholder.png'
import useUserInfo from '../../../customHooks/useUserInfo'
import useToken from '../../../customHooks/useToken';

import './UserProfile.css'
import { changePortrait, getEntity } from '../../../API/requests';

const PictureUploadDialog = props => {
    return (
        <DropzoneDialog
            acceptedFiles={['image/*']}
            cancelButtonText='Cancel'
            submitButtonText='Submit'
            filesLimit={1}
            maxFileSize={600000}
            open={props.open}
            onClose={() => props.close()}
            onSave={(file) => { props.changeProfilePic(file); props.close() }}
            showPreviews={false}
            showPreviewsInDropzone={true}
            dialogTitle='Upload Image'
            maxWidth='xs'
        />
    )
}

function UserProfile(props) {
    const [user, setUser] = useState([])
    const [lastActivities, setLastActivities] = useState([])
    const [totalActivity, setTotalActivity] = useState(0)
    const [openPictureUpload, setOpenPictureUpload] = useState(false)
    const [userPictureUrl, setUserPictureUrl] = useState('')
    const [refresh, setRefresh] = useState(false)
    const { triggerSnackbar } = useGlobalSnackbar();
    const { openErrDialog } = useDialog()
    const [sideView, setSideView] = useState('info')
    const [sameUser, setSameUser] = useState(false)
    const { userInfo, setUserState } = useUserInfo()
    const { token } = useToken()

    useEffect(() => {

        getEntity('backoffice_users', sessionStorage.getItem('id')).then(res => {
            setUser(res.data)
            setLastActivities(res.data.activities)
            setTotalActivity(res.data.activities.length)
            setSameUser(sessionStorage.getItem('id') === res.data.id.toString())
            setUserState({ ...res.data, token })
            if (res.data.picture) {
                setUserPictureUrl(`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${res.data.picture}?${new Date().getTime()}`)
            }
        }, err => {
            console.log(err)
        })

    }, [refresh])

    const handleImageUpload = (file) => {

        let formData = new FormData();
        formData.append('picture', file[0]);

        changePortrait(user.id, 'update', formData).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            setRefresh(!refresh)
        }, (err) => {
            openErrDialog(err.response ? err.response : err, () => props.close())
            console.log(err);
        })
    }

    const removePicture = () => {
        changePortrait(user.id, 'remove', {}).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            setRefresh(!refresh)
        }, (err) => {
            console.log(err)
            openErrDialog(err.response ? err.response : err, () => props.close())
        })
    }

    return (
        <Dialog
            maxWidth='sm'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='user-profile-mui-dialog-container'
        >
            <DialogContent className='user-profile-more-info-dialog-content'>
                <div className='user-profile-more-info-dialog-header'>
                    <div className='user-profile-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{user.name}</p>
                    </div>
                </div>
                <div className='user-profile-more-info-dialog-info-wrapper'>
                    <div className='user-profile-more-info-dialog-picture-div'>
                        <div className='userType-wrapper-div-profile'>
                            <div className='userType-div-profile'>{user.user_role?.name}</div>
                        </div>
                        <div className='user-profile-more-info-dialog-picture'>
                            {user.picture ? (
                                <Tooltip title='Click to change picture'>
                                    {/* since the image url is always the same, adding current timestamp as query param makes the browser request again */}
                                    <img key={refresh} className='user-profile-picture' alt='user portrait' src={userPictureUrl} width='100%' height='100%' onClick={() => setOpenPictureUpload(true)} />
                                </Tooltip>
                            ) : (
                                <Tooltip title='Click to upload picture'>
                                    <img className='user-profile-picture' alt='user portrait' src={Portrait_Placeholder} width='100%' height='100%' onClick={() => setOpenPictureUpload(true)} />
                                </Tooltip>
                            )}
                        </div>
                        <div className='user-profile-info-common-info'>
                            <p className='user-profile-name'>{user.name}</p>
                            <p className='user-profile-email'>{user.email}</p>
                        </div>
                        <div className='user-profile-actions'>
                            {user.picture &&
                                <div>
                                    <button onClick={() => removePicture()}>Remove picture</button>
                                </div>
                            }
                            <div style={{ alignItems: 'flex-end', flex: '1 1' }}>
                                {sameUser &&
                                    <button onClick={() => setSideView('changePassword')}>Change Password</button>
                                }
                                {userInfo.user_role.canEditBackofficeUser &&
                                    <button onClick={() => setSideView('editProfile')}>Edit profile</button>
                                }
                            </div>
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: '90%' }} />
                    <div className='user-profile-more-info-info-container'>
                        <div className={`user-profile-static-info ${sideView === 'info' ? '' : 'hidden'}`}>
                            <div className='user-profile-info-last-activity'>
                                <p style={{ marginBottom: '10px ' }} className='user-profile-activity-title' >{`Total activity: ${totalActivity}`}</p>
                                <p className='user-profile-activity-title' >Last activity:</p>
                                <div className='user-profile-activity-scrollable'>
                                    {lastActivities.map(activity => {
                                        return (
                                            <p key={activity.id} className='user-profile-activity-text'>
                                                {`${moment(activity.createdAt).format('YYYY-MM-DD HH:mm:ss')}: ${activity.user}, ${activity.activity}`}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider orientation='horizontal' sx={{ width: '80%' }} />
                            <div className='user-profile-info-meta'>
                                <p className='user-profile-meta'>{`User ID: ${user.id}`}</p>
                                <p className='user-profile-meta'>{`Image ID: ${user.image_id}`}</p>
                                <p className='user-profile-meta'>{`Created At: ${moment(user.createdAt)}`}</p>
                                <p className='user-profile-meta'>{`Updated At: ${moment(user.updatedAt)}`}</p>
                            </div>
                        </div>
                        <div className={`user-profile-static-editProfile ${sideView === 'editProfile' ? '' : 'hidden'}`}>
                            <IconButton sx={{ position: 'absolute', top: '0', right: '0' }} onClick={() => setSideView('info')}>
                                <Close />
                            </IconButton>
                            <p style={{ fontSize: '18px' }} >Edit profile</p>
                            <div className='user-profile-edit-fields'>
                                <UserProfileEdit data={user} currentUserRole={userInfo.user_role} refresh={() => setRefresh(!refresh)} />
                            </div>
                        </div>
                        <div className={`user-profile-static-changePassword ${sideView === 'changePassword' ? '' : 'hidden'}`}>
                            <IconButton sx={{ position: 'absolute', top: '0', right: '0' }} onClick={() => setSideView('info')}>
                                <Close />
                            </IconButton>
                            <p style={{ fontSize: '18px' }} >Change password</p>
                            <div className='user-profile-change-pass-fields'>
                                <UserProfileChangePassword data={user} close={props.close} />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <PictureUploadDialog
                close={() => setOpenPictureUpload(false)}
                open={openPictureUpload}
                changeProfilePic={(file) => handleImageUpload(file)}
            />
        </Dialog>
    )
}

export default UserProfile