import React, { useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title, Description } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    OndemandVideoOutlined as VideoIcon,
    PowerSettingsNew as OnlineIcon,
    FormatListBulleted as TopicsIcon,
    Factory as IndustryIcon,
    Event as EventIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import DeleteDialog from '../DeleteDialog';


import { openInNewTab } from '../../../utils';
import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { setEntityOnlineOrOffline } from '../../../API/requests';
import SmallSpeakersCard from '../../Cards/Speakers/SmallSpeakersCard';
import EditEpisode from './Edit/EditEpisode';
import { generateThumbnailImageFromVideoUrl } from '../../../utils'
import useUserInfo from '../../../customHooks/useUserInfo';

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

function MoreInfo(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    const setOnlineDialogInfo = { title: `Set episode ${props.data.online ? 'Offline' : 'Online'}?`, message: `Set episode ${props.data.title} ${props.data.online ? 'Offline' : 'Online'}?` }

    const handleSetEventOnline = () => {
        setEntityOnlineOrOffline('episodes', props.data.id, !props.data.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='episode-mui-dialog-container'
        >
            <DialogContent className='episode-more-info-dialog-content'>
                <div className='episode-more-info-dialog-header'>
                    <div className='episode-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{props.data.title}</p>
                        <p style={{ color: '#bbbbbb', fontSize: '14px' }}>{moment(props.data.date).format('YYYY-MM-DD')}</p>
                    </div>
                    <div className='episode-dialog-actions'>
                        {!props.hideActions &&
                            <>
                                {userInfo.user_role.canRemoveEpisode &&
                                    <Tooltip title='Delete Episode'>
                                        <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                    </Tooltip>
                                }
                                {userInfo.user_role.canEditEpisode &&
                                    <>
                                        <Tooltip title='Edit Episode'>
                                            <EditIcon onClick={() => setOpenEditDialog(true)} />
                                        </Tooltip>
                                        <Tooltip title={`Set episode ${props.data.online ? 'Offline' : 'Online'}`}>
                                            <OnlineIcon sx={props.data.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetEventOnline())} />
                                        </Tooltip>
                                    </>
                                }
                            </>}
                    </div>
                </div>
                <div className='episode-more-info-dialog-info-wrapper'>
                    <div className='episode-more-info-dialog-episodes-div'>
                        <div className='episode-more-info-dialog-episodes-scroll-div'>
                            <p style={{ marginBottom: '25px' }}>Speakers</p>
                            {props.data.speakers.map(speaker => {
                                return (
                                    <SmallSpeakersCard key={speaker.id} data={speaker} refresh={props.refresh} />
                                )
                            })}
                        </div>
                    </div>
                    <Divider orientation='vertical' sx={{ height: '90%' }} />
                    <div className='episode-more-info-info-container'>
                        <div className='episode-more-info-dialog-info-div'>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Title />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Title' secondary={props.data.title} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Description' secondary={props.data.description} />
                                </ListItem>
                                <ListItem>
                                    {props.data.video?.length > 0 &&
                                        <div className='list-item-div' onClick={() => openInNewTab(props.data.video)}>
                                            <ListItemAvatar >
                                                <StyledAvatar>
                                                    <VideoIcon />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText sx={{ wordBreak: 'break-all' }} primary='Video' secondary={props.data.video} />
                                        </div>
                                    }
                                </ListItem>
                                {props.data.topics.length > 0 &&
                                    <ListItem>
                                        <ListItemAvatar>
                                            <StyledAvatar>
                                                <TopicsIcon />
                                            </StyledAvatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Topics' secondary={props.data.topics.map((topic, index) => {
                                            return (
                                                <label key={topic.id}>{index === 0 ? `${topic.name}` : `, ${topic.name}`}</label>
                                            )
                                        })} />
                                    </ListItem>
                                }
                                {props.data.industries.length > 0 &&
                                    <ListItem>
                                        <ListItemAvatar>
                                            <StyledAvatar>
                                                <IndustryIcon />
                                            </StyledAvatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Industries' secondary={props.data.industries.map((industry, index) => {
                                            return (
                                                <label key={industry.id}>{index === 0 ? `${industry.name}` : `, ${industry.name}`}</label>
                                            )
                                        })} />
                                    </ListItem>
                                }
                                {props.data.formats.length > 0 &&
                                    <ListItem>
                                        <ListItemAvatar>
                                            <StyledAvatar>
                                                <TopicsIcon />
                                            </StyledAvatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Format' secondary={props.data.formats.map((format, index) => {
                                            return (
                                                <label key={format.id}>{index === 0 ? `${format.name}` : `, ${format.name}`}</label>
                                            )
                                        })} />
                                    </ListItem>
                                }
                                {props.data.types.length > 0 &&
                                    <ListItem>
                                        <ListItemAvatar>
                                            <StyledAvatar>
                                                <TopicsIcon />
                                            </StyledAvatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Type' secondary={props.data.types.map((type, index) => {
                                            return (
                                                <label key={type.id}>{index === 0 ? `${type.name}` : `, ${type.name}`}</label>
                                            )
                                        })} />
                                    </ListItem>
                                }
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <EventIcon />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Belongs to Event:' secondary={props.data.event ? props.data.event.title : props.event_title} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Language' secondary={props.data.language ?? 'N/D'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Location' secondary={props.data.location ?? 'N/D'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Responsible' secondary={props.data.responsible ?? 'N/D'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Region' secondary={props.data.region ?? 'N/D'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Organization' secondary={props.data.organization ?? 'N/D'} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Description />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Status' secondary={props.data.status ?? 'N/D'} />
                                </ListItem>
                                {props.data.salesforce_url &&
                                    <ListItem sx={{cursor:'pointer'}} onClick={() => openInNewTab(props.data.salesforce_url)}>
                                        <ListItemAvatar >
                                            <StyledAvatar>
                                                <Description />
                                            </StyledAvatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Salesforce Link' secondary='Click to visit' />
                                    </ListItem>
                                }
                            </List>
                            <div className='episode-more-info-chips-div'>
                                <BooleanChip value={props.data.available} label='Available?' tooltip='available' />
                                <BooleanChip value={props.data.featured} label='Featured?' tooltip='featured' />
                                <BooleanChip value={props.data.online} label='Online?' tooltip='online' />
                                <BooleanChip value={props.data.private} label='Private?' tooltip='private' />
                                <BooleanChip value={props.data.force} label='Forced?' tooltip='force' />
                            </div>
                        </div>
                        <Divider orientation='horizontal' sx={{ borderColor: 'rgb(216 217 222)', width: '95%' }} />
                        <DialogActions className='episode-more-info-dialog-images'>
                            <div className='episode-more-info-other-info'>
                                <p>{`ID: ${props.data.id}`}</p>
                                <p>{`Image ID: ${props.data.image_id}`}</p>
                                <p>{`Created At: ${moment(props.data.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                                <p>{`Last Update: ${moment(props.data.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                            </div>
                            <Divider orientation='vertical' sx={{ height: '90%' }} />
                            <div className='episode-more-info-images-container'>
                                {props.data.image ? (
                                    <img
                                        alt='episode landscape'
                                        src={`${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/${props.data.image}`}
                                        width='auto'
                                        height='100%'
                                        onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/${props.data.image}`)}
                                    />
                                ) : (
                                    <img
                                        alt='episode landscape'
                                        src={generateThumbnailImageFromVideoUrl(props.data.video)}
                                        width='auto'
                                        height='100%'
                                        onClick={() => openInNewTab(generateThumbnailImageFromVideoUrl(props.data.video))}
                                    />
                                )}
                            </div>
                        </DialogActions>
                    </div>
                </div>
            </DialogContent>
            <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} type='Episode' data={props.data} refresh={props.refresh} />
            <EditEpisode open={openEditDialog} close={() => setOpenEditDialog(false)} data={props.data} refresh={props.refresh} />
        </Dialog>
    )
}

export default MoreInfo