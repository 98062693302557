import React from 'react'
import { TextField, Checkbox, FormControlLabel, FormGroup, Divider } from '@mui/material'
import { TooltipHintsEvents } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'
import { Help } from '@mui/icons-material'
import { SketchPicker } from 'react-color'
import { openInNewTab } from '../../../../utils';
import regexifyString from 'regexify-string'

import '../Add/Info.css'

const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		border: 'none',
		fontSize: '12px',
		maxWidth: 'none',
		whiteSpaces: 'pre-wrap'
	},
}));

const TooltipContent = () => {
	return (
		<div>
			<p>You can add links or clickable email by adding this format to the text:</p>
			<p>[[name here|link or email here]]</p>
			<p>Example: [[Learn more about Teleperformance|https://teleperformance.com]] or [[My Email|myemail@mail.com]]</p>
		</div>
	)
}

const regexifyPaterm = /\[\[(.*?)\]\]/gim;
const regexifyDecorator = (match, index) => {
	const string = match.substring(2, match.length - 2)
	return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>;
}


class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			titleIsValid: false,
			textIsValid: false,
			formIsValid: false,
			showGradient1Picker: false,
			showGradient2Picker: false,
			showBackGroundPicker: false,
			showTextPicker: false,
			formErrors: { title: '', text: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		if (this.state.infoComplete) {
			this.setState({
				titleIsValid: true,
				textIsValid: true,
				formErrors: { title: '', text: '' }
			})
		}
	}

	handleOnBlurAngleValue = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		if( value === '') {
			this.handelInput({target: { name: name, value: '0' }})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		if(name === 'gradient_angle') {
            if(value > 360) value = '0'
			if(value < 0) value = '360'
        }

		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleDateInput = (name, value) => {
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleAutocompleteInput = (name, value) => {
		this.props.updateStateOnParent(name, value)
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let titleIsValid = this.state.titleIsValid;
		let textIsValid = this.state.textIsValid;

		switch (name) {
			case 'title':
				titleIsValid = value.length > 4;
				fieldValidationErrors.title = titleIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid title`}`
				break;
			case 'text':
				textIsValid = value.length > 10;
				fieldValidationErrors.text = textIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid text`}`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			titleIsValid,
			textIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.titleIsValid &&
				this.state.textIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}


	handleColorPickerChange = (color, property) => {
		this.props.updateStateOnParent([property], color.hex);
		this.setState({ [property]: color.hex })
	}

	ColorPickerDiv = ({ property, showProperty }) => {
		return (
			<div>
				<div className='about-color-picker-swatch' onClick={() => this.setState({ [showProperty]: !this.state[showProperty] })}>
					{this.state[property] ? (
						<div className='about-color-picker-color' style={{ background: this.state[property], }} />
					) : (
						<div className='about-color-picker-color' style={{ background: 'gray' }} />
					)}
				</div>
				{this.state[showProperty] ? <div className='about-color-picker-popover'>
					<div className='about-color-picker-cover' onClick={() => this.setState({ [showProperty]: false })} />
					<SketchPicker
					color={this.state[property]} 
					presetColors={['#ff0082','#ff5c00','#7A2180','#E40276','#f37572','#B14282','#E8954E','#ffb54b','#5cb660', '#333f55','#362c40','#3990A6','#6854CE','#CDB369', '#FFFFFF', '#000000']}
					disableAlpha
					onChange={(color) => this.handleColorPickerChange(color, property)} />
				</div> : null}
			</div>
		)
	}

	render() {

		return (
			<div className='about-info-main-container' >
				<div className='about-info-left-container'>
					<StyledTooltip title={<TooltipContent />}><Help /></StyledTooltip>
					<div className='about-info-input-container'>
						<TextField
							required
							autoFocus
							id='title'
							name='title'
							label='Title'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.title}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.titleIsValid}
							helperText={this.state.formErrors.title}
						/>
					</div>
					<div className='about-info-input-container'>
						<TextField
							required
							multiline
							rows={10}
							id='text'
							color='secondary'
							name='text'
							label='text'
							fullWidth
							variant='standard'
							type='text'
							value={this.state.text}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.textIsValid}
							helperText={this.state.formErrors.text}
						/>
					</div >
					<Tooltip title={TooltipHintsEvents.online} disableInteractive>
						<FormControlLabel control={<Checkbox
							name='online'
							color='secondary'
							value={this.state.online}
							checked={this.state.online}
							onChange={(e) => this.handleCheckboxInput(e)}
						/>} label='Online' />
					</Tooltip>
				</div>
				<div className='about-info-right-container'>
					<div className='about-info-checkbox-container'>
						<div className='about-gradient'>
							<FormGroup>
								<FormControlLabel control={<Checkbox
									name='gradient'
									color='secondary'
									value={this.state.gradient}
									checked={this.state.gradient}
									onChange={(e) => this.handleCheckboxInput(e)}
								/>} label='Gradient?' />
							</FormGroup>
							{this.state.gradient &&
								<div>
									<div className='color-picker-div'>
										<p>Gradient color 1</p>
										<this.ColorPickerDiv property='gradient_color_1' showProperty='showGradient1Picker' />
									</div>
									<div className='color-picker-div'>
										<p>Gradient color 2</p>
										<this.ColorPickerDiv property='gradient_color_2' showProperty='showGradient2Picker' />
									</div>
									<div className='color-picker-div'>
										<p>Gradient Angle</p>
										<TextField
											id='gradient_angle'
											color='secondary'
											name='gradient_angle'
											variant='standard'
											type='number'
											onBlurCapture={this.handleOnBlurAngleValue}
											sx={{ width: '30px'}}
											value={this.state.gradient_angle}
											onChange={(e) => this.handelInput(e)}
										/>
									</div>
								</div>
							}
						</div>
						<div className='about-misc-style'>
							{!this.state.gradient &&
								<div className='color-picker-div'>
									<p>Background Color</p>
									<this.ColorPickerDiv property='background_color' showProperty='showBackGroundPicker' />
								</div>
							}
							<div className='color-picker-div'>
								<p>Text Color</p>
								<this.ColorPickerDiv property='text_color' showProperty='showTextPicker' />
							</div>
						</div>
					</div>
					<Divider orientation='horizontal' sx={{ width: '100%' }} />
					<div className='about-preview-container'
						style={this.state.gradient ?
							{ backgroundImage: `linear-gradient(${this.state.gradient_angle}deg, ${this.state.gradient_color_1} 0%, ${this.state.gradient_color_2} 100%)`, color: this.state.text_color } :
							{ backgroundColor: this.state.background_color, color: this.state.text_color }
						}
					>
						<p className='about-preview-title'>{this.state.title}</p>
						<p className='about-preview-text'>{regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: this.state.text })}</p>
					</div>
				</div>
			</div>
		)
	}
}

export default Info