import React from 'react'
import MUIDataTable from 'mui-datatables'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Delete } from '@mui/icons-material'
import { removeEntity } from '../../API/requests'
import useDialog from '../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../Snackbar/useGlobalSnackbar'

import './EventsTable.css'


const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    width: '100%',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        },
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)',
        borderLeft: '5px solid var(--light-red)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    }

})

function UsersTable(props) {
    const { triggerSnackbar } = useGlobalSnackbar();
    const { openInfoDialog, openErrDialog } = useDialog()

    const options = {
        filter: true,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
    };

    const deleteUser = (id) => {
        const infoDialogData = { title: 'Are you sure?', message: `Are you sure you want to remove this user access?` };
        openInfoDialog(infoDialogData, 'y/n', () => removeUser(id[0]))
    }

    const removeUser = (id) => {
        removeEntity('app_users', id).then(res => {
            props.refresh()
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err)
        })
    }

    const CustomActionTableBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100px' }}>
                {props.canRemoveUser &&
                    <Tooltip title='Remove User Access'>
                        <Delete className='action-icon' onClick={() => deleteUser([data.meta.rowData[0]])} />
                    </Tooltip>
                }
            </div>
        )
    }

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false } },
        { name: 'email', label: 'Email', options: { filter: false } },
        { name: 'name', label: 'Name', options: { filter: false } },
        { name: 'online', label: 'Actions', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomActionTableBody value={value} meta={tableMeta} update={updateValue} /> } },
    ]

    return (
        <div style={{ marginTop: '20px', width: '100%', maxWidth: '1600px' }}>
            <MuiDataTableStyled
                title={"Users"}
                data={props.data}
                columns={columns}
                options={options}
            />
        </div>
    )
}

export default UsersTable