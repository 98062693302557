import React from 'react'
import { Edit, Visibility, Delete } from '@mui/icons-material'
import CustomTooltip from '../../Misc/CustomTooltip'
import { IconButton } from '@mui/material'
import useUserInfo from '../../../customHooks/useUserInfo'

import './Actions.css'

function Actions(props) {
    const { userInfo } = useUserInfo()

    return (
        <div className='table-actions-div'>
           
            {props.onView &&
                <CustomTooltip title='Ver mais informação'>
                    <span>
                        <IconButton disabled={props.selection} onClick={props.onView}>
                            <Visibility />
                        </IconButton>
                    </span>
                </CustomTooltip>
            }
            {(props.onEdit && userInfo.user_role[`canEdit${props.roleId}`]) &&
                <CustomTooltip title='Editar'>
                    <span>
                        <IconButton disabled={props.selection} onClick={props.onEdit} >
                            <Edit />
                        </IconButton>
                    </span>
                </CustomTooltip>
            }
            {(props.onDelete && userInfo.user_role[`canRemove${props.roleId}`]) &&
                <CustomTooltip title='Eliminar'>
                    <span>
                        <IconButton disabled={props.selection} onClick={props.onDelete} >
                            <Delete />
                        </IconButton>
                    </span>
                </CustomTooltip>
            }
        </div>
    )
}

export default Actions