import React, { useState } from 'react'
import './UserCards.css'
import moment from 'moment'
import DeleteDialog from '../../Dialogs/DeleteDialog';
import UserInfoDialog from '../../Dialogs/Users/UserInfoDialog';

import { Divider, Tooltip } from '@mui/material'
import Portrait_Placeholder from '../../../assets/portrait_placeholder.png'
import Logo from '../../../assets/LEADER_LOGO.svg'
import useUserInfo from '../../../customHooks/useUserInfo';

import { Delete as DeleteIcon, Visibility } from '@mui/icons-material'

function UserCards(props) {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openViewProfile, setOpenViewProfile] = useState(false)
    const { userInfo } = useUserInfo()

    return (
        <div className='users-card-main-container'>
            <div className='logged-in-div' style={props.user.loggedIn ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }} />
            <div className='userType-wrapper-div'>
                <div className='userType-div-user-card'>{props.user.user_role.name}</div>
            </div>
            <div className='users-card-top-div' style={{ backgroundColor: 'var(--light-blue)' }}><img alt='leader insights' src={Logo} width='90%' height='auto' /></div>
            <div className='users-card-picture-div'>
                {props.user.picture && props.user.picture.length > 0 ? (
                    <img alt='user portrait' style={{ objectFit: 'cover' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${props.user.picture}`} width='100%' height='100%' />
                ) : (
                    <img alt='user portrait' src={Portrait_Placeholder} width='100%' height='100%' />
                )}
            </div>
            <div className='users-card-bottom-div'>
                <p className='users-name'>{props.user.name}</p>
                <p className='users-job'>{props.user.email}</p>
                {moment(props.user.last_login).isValid() ?
                    <p className='users-last-login'>{`Last login: ${moment(props.user.last_login).format('YYYY-MM-DD H:mm:ss')}`}</p>
                    :
                    <p className='users-last-login'>Last login: Never</p>
                }
                <Divider orientation='horizontal' sx={{ width: '80%' }} />
                <div className='users-action-area'>
                    {userInfo.user_role.canRemoveBackofficeUser &&
                        <Tooltip disableInteractive title='Delete User'>
                            <DeleteIcon htmlColor='#333f55' onClick={() => setDeleteDialog(true)} />
                        </Tooltip>
                    }
                    {userInfo.user_role.canViewBackofficeUser &&
                        <Tooltip disableInteractive title='User Profile'>
                            <Visibility htmlColor='#333f55' onClick={() => setOpenViewProfile(true)} />
                        </Tooltip>
                    }
                </div>
            </div>
            <DeleteDialog data={props.user} hideTrash={true} nameField='name' type='User' open={deleteDialog} close={() => setDeleteDialog(false)} refresh={props.refresh} />
            <UserInfoDialog refresh={props.refresh} data={props.user} open={openViewProfile} close={() => setOpenViewProfile(false)} />
        </div>
    )
}

export default UserCards