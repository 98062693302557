import React, { useState } from 'react';
import GroupedButtons from '../../Buttons/GroupedButtons';
import { ArrowBackIos as ArrowLeftIcon, ArrowForwardIos as ArrowRightIcon } from '@mui/icons-material';
import { getUserPrefs, setUserPrefs } from '../../user/UserPrefs';
import './EventsFilterPanel.css'


const viewButtons = [
    { label: 'View Cards', value: 'cards' },
    { label: 'View Table', value: 'table' },
]

const eventStateButtons = [
    { label: 'All', value: 'all' },
    { label: 'OK', value: 'ok', color: 'var(--light-green)' },
    { label: 'Warning', value: 'warning', color: 'var(--light-yellow)' },
    { label: 'Error', value: 'error', color: 'var(--light-red)' },
]

const eventsOnlineButtons = [
    { label: 'All', value: 'all' },
    { label: 'Online', value: true, color: 'var(--light-green)' },
    { label: 'Offline', value: false, color: 'var(--light-red)' },
]


const filterValues = {
    state: 'all',
    online: 'all',
}

function SpeakersFilterPanel(props) {
    const [show, setShow] = useState(false)
    const [filterState, setFilterState] = useState({
        state: 'all',
        online: 'all',
    })
    const [viewCollapsed, setViewCollapsed] = useState(getUserPrefs('speakers').collapsed.view)
    const [stateCollapsed, setStateCollapsed] = useState(getUserPrefs('speakers').collapsed.state)
    const [filterCollapsed, setFilterCollapsed] = useState(getUserPrefs('speakers').collapsed.filter)

    const clearFilters = () => {

        setFilterState({ state: 'all', online: 'all' })
        props.updateData(props.data)
    }

    const filterData = (filter, value) => {

        setFilterState(state => ({
            ...state,
            ...{ [filter]: value }
        }))

        filterValues[filter] = value

        const filteredData = props.data.filter(event => {
            return (
                (filterValues.online !== 'all' ? event.online === filterValues.online : true)
            )
        })

        props.updateData(filteredData)
    }

    return (
        <div className={`filter-panel-main-div${show ? ' show' : ''}`}>
            <div className={`filter-panel-fixed-div${show ? ' show' : ''}`}>

                <div className='filter-panel-handler' onClick={() => setShow(!show)}>
                    {show ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                </div>
                <div className='filter-panel-inner-div'>
                    <div className={`filter-view ${viewCollapsed ? 'collapsed' : ''}`}>
                        <p className='filter-panel-title' onClick={() => { setViewCollapsed(!viewCollapsed); setUserPrefs('speakers','collapsed', 'view', !viewCollapsed) }}>Change View</p>
                        <GroupedButtons currentValue={props.currentView} setValue={props.setView} buttons={viewButtons} />
                    </div>
                    <div className={`filter-state ${stateCollapsed ? 'collapsed' : ''}`}>
                        <p className='filter-panel-title' onClick={() => { setStateCollapsed(!stateCollapsed); setUserPrefs('speakers', 'collapsed', 'state', !stateCollapsed) }}>Speakers State</p>
                        <div className='filter-filter-inner-div'>
                            <GroupedButtons currentValue={filterState.state} setValue={(value) => filterData('state', value)} buttons={eventStateButtons} />
                        </div>
                    </div>
                    <div className={`filter-filter ${filterCollapsed ? 'collapsed' : ''}`}>
                        <p className='filter-panel-title' onClick={() => { setFilterCollapsed(!filterCollapsed); setUserPrefs('speakers','collapsed', 'filter', !filterCollapsed) }}>Filters</p>
                        <div className='filter-filter-inner-div'>
                            <div className='filter-filter-inner-subdiv'>
                                <p>Online?</p>
                                <GroupedButtons currentValue={filterState.online} setValue={(value) => filterData('online', value)} buttons={eventsOnlineButtons} />
                            </div>
                            <div className='filter-filter-inner-div'>
                                <div className='filter-clear-all-filter' onClick={clearFilters}>Clear all filters</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SpeakersFilterPanel;
