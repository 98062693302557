import React, { useState, useEffect } from 'react'
import { openInNewTab } from '../../../../utils'
import regexifyString from 'regexify-string'
import './Review.css'

function Review(props) {
	const [state, setState] = useState()

	useEffect(() => {
		setState(props.state)

	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='about-review-main-container'>
			{state &&
				<div className='review-scrollable-div'>
					<div className='section-div' style={getBackgroundStyle(state)} >
						<div className={`section-text${state.section_image ? `-image${state.image_position === 'right' ? '-right' : '-left'}` : ''}`} style={{ color: state.text_color }}>
							<div className='section-text-title'>{state.title}</div>
							<div className='section-text-content'>{regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: state.text })}</div>
						</div>
						{state.section_image &&
							<div className='section-img'>
								<img alt='landscape-preview' className='about-image' src={getImageBlob(state.section_image)} width='auto' height='100%' />
							</div>
						}
					</div>
				</div>
			}
		</div>
	)
}

export default Review

const regexifyPaterm = /\[\[(.*?)\]\]/gim;
const regexifyDecorator = (match, index) => {
	const string = match.substring(2, match.length - 2)
	return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>;
}

const getBackgroundStyle = (section) => {

	const style = {};

	if (section.gradient) {
		style.backgroundImage = `linear-gradient(${section.gradient_angle}deg,${section.gradient_color_1}, ${section.gradient_color_2})`
	} else {
		style.backgroundColor = section.background_color
	}

	if (section.section_image) {
		if (section.image_position === 'left') {
			style.flexDirection = 'row-reverse'
		}
	} else {
		style.textAlign = 'center'
	}

	return style
}