import React, { useState, useEffect } from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import moment from 'moment'
import { Divider } from '@mui/material'
import './Review.css'

function Review(props) {
	const [state, setState] = useState()

	useEffect(() => {
		setState(props.state)
	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review information before submitting</p>
			{state &&
				<div className='review-scrollable-div'>
					<div className='review-label-div'>
						<p className='review-label'>Title</p>
						<p className='review-text'>{state.title}</p>
					</div>
					<div className='review-label-div'>
						<p className='review-label'>Subtitle</p>
						<p className='review-text'>{state.subtitle}</p>
					</div>
					<div className='review-label-div'>
						<p className='review-label'>Description</p>
						<p className='review-text'>{state.description}</p>
					</div>
					{state.serie &&
						<div className='review-label-div'>
							<p className='review-label'>Serie</p>
							<p className='review-text'>{state.serie.title}</p>
						</div>
					}
					{state.trailer &&
						<div className='review-label-div'>
							<p className='review-label'>Trailer</p>
							<p className='review-text'>{state.trailer}</p>
						</div>
					}
					{state.event_page &&
						<div className='review-label-div'>
							<p className='review-label'>Event Page</p>
							<p className='review-text'>{state.event_page}</p>
						</div>
					}

					{state.topics.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Topics</p>
							<p className='review-text'>{state.topics.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{state.formats.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Formats</p>
							<p className='review-text'>{state.formats.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{state.types.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Types</p>
							<p className='review-text'>{state.types.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{state.industries.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Industries</p>
							<p className='review-text'>{state.industries.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{state.location &&
						<div className='review-label-div'>
							<p className='review-label'>Location</p>
							<p className='review-text'>{state.location}</p>
						</div>
					}
					{state.language &&
						<div className='review-label-div'>
							<p className='review-label'>Language</p>
							<p className='review-text'>{state.language.emoji} {state.language.name}</p>
						</div>
					}
					{state.responsible &&
						<div className='review-label-div'>
							<p className='review-label'>Responsible</p>
							<p className='review-text'>{state.responsible.split(',')[0]}</p>
						</div>
					}
					{state.region &&
						<div className='review-label-div'>
							<p className='review-label'>Region</p>
							<p className='review-text'>{state.region}</p>
						</div>
					}
					{state.organization &&
						<div className='review-label-div'>
							<p className='review-label'>Organization</p>
							<p className='review-text'>{state.organization}</p>
						</div>
					}
					{state.status &&
						<div className='review-label-div'>
							<p className='review-label'>Status</p>
							<p className='review-text'>{state.status}</p>
						</div>
					}
					{state.salesforce_url &&
						<div className='review-label-div'>
							<p className='review-label'>Salesforce Link</p>
							<p className='review-text'>{state.salesforce_url}</p>
						</div>
					}
					<div className='review-label-div'>
						<p className='review-label'>Dates</p>
						<p className='review-text'>{`From ${moment(state.start_date).format('yyyy-MMM-DD')} to ${moment(state.end_date).format('yyyy-MMM-DD')}`}</p>
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='review-chips-div'>
						<BooleanChip value={state.available} label='Available?' tooltip='available' />
						<BooleanChip value={state.highlighted} label='Highlighted?' tooltip='highlighted' />
						<BooleanChip value={state.online} label='Online?' tooltip='online' />
						<BooleanChip value={state.private} label='Private?' tooltip='private' />
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='review-images-div'>
						<img alt='landscape-preview' src={getImageBlob(state.landscape_image)} width='auto' height='100%' />
						<img alt='portrait-preview' src={getImageBlob(state.portrait_image)} width='auto' height='100%' />
					</div>
				</div>
			}
		</div>
	)
}

export default Review