import '../Add/AddEvent.css'
import React from 'react'
import { Dialog, DialogContent, Stepper, Step, StepLabel } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { RateReview, Image, Preview } from '@mui/icons-material';
import { styled } from '@mui/material/styles'
import FormData from 'form-data'
import ErrorDialog from '../../ErrorDialog';
import InfoDialog from '../../InfoDialog';
import { ThreeDots } from 'react-loading-icons'

import Info from './Info'
import Images from './Images'
import Review from './Review'
import moment from 'moment';
import { fetchEvents } from '../../../../Data/Events';
import { editWithFiles } from '../../../../API/requests';


const steps = ['Set Information', 'Upload images', 'Review information'];

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorLibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <RateReview />,
        2: <Image />,
        3: <Preview />,
    };

    return (
        <StyledStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </StyledStepIconRoot>
    );
}

class EditEvent extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            activeStep: 0,
            title: props.data.title,
            subtitle: props.data.subtitle,
            description: props.data.description,
            trailer: props.data.trailer,
            event_page: props.data.event_page ?? '',
            private: props.data.private,
            available: props.data.available,
            highlighted: props.data.highlighted,
            online: props.data.online,
            topics: props.data.topics,
            formats: props.data.formats,
            types: props.data.types,
            industries: props.data.industries,
            serie: props.data.series,
            start_date: props.data.start_date,
            end_date: props.data.end_date,
            landscape_image: props.data.landscape_image,
            portrait_image: props.data.portrait_image,
            language: props.data.language ? { name: props.data.language } : null,
            location: props.data.location ?? '',
            has_episodes: props.data.has_episodes,
            responsible: props.data.responsible?.length > 0 ? props.data.responsible : null,
            region: props.data.region ?? '',
            organization: props.data.organization ?? '',
            status: props.data.status?.length > 0 ? props.data.status : null,
            salesforce_url: props.data.salesforce_url ?? '',
            infoComplete: true,
            imagesComplete: true,
            submitting: false,
            openInfoDialog: false,
            openErrDialog: false,
            errDialogInfo: '',
            infoDialogData: ''
        }
        this.changedState = { string: {}, boolean: {}, object: {}, date: {}, images: {} }
    }

    resetState = () => {
        this.setState({ ...this.state, activeStep: 0, openInfoDialog: false })
        this.changedState = { string: {}, boolean: {}, object: {}, date: {}, images: {} }
    }

    checkNextBtn = () => {
        if (this.state.activeStep === 0 && this.state.infoComplete) return true
        if (this.state.activeStep === 1 && this.state.imagesComplete) return true
        return false
    }

    checkSubmitBtn = () => {
        if (Object.keys(this.changedState.string).length > 0 || Object.keys(this.changedState.boolean).length > 0 ||
            Object.keys(this.changedState.object).length > 0 || Object.keys(this.changedState.date).length > 0 || 
            Object.keys(this.changedState.images).length > 0) {
            return true
        }
        return false
    }

    handleNextStep = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    }

    handlePrevStep = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
    };

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (value instanceof Date) {
            if (!moment(value).isSame(moment(this.props.data[property]))) {
                this.changedState.date[property] = value;
            } else {
                delete this.changedState.date[property]
            }
            return
        }

        if (property === 'language') {
            if (!value) {
                this.changedState.string.language = ''
                return;
            }
            if (value.name !== this.props.data.language?.name) {
                this.changedState.string[property] = value;
            } else {
                delete this.changedState.string[property]
            }
            return;
        }

        if (property === 'responsible') {
            if (!value) {
                this.changedState.string.responsible = ''
                return;
            }
            if (value !== this.props.data.responsible) {
                this.changedState.string[property] = value;
            } else {
                delete this.changedState.string[property]
            }
            return;
        }

        if (property === 'status') {
            if (!value) {
                this.changedState.string.status = ''
                return;
            }
            if (value !== this.props.data.status) {
                this.changedState.string[property] = value;
            } else {
                delete this.changedState.string[property]
            }
            return;
        }

        if (property === 'landscape_image' || property === 'portrait_image') {
            this.changedState.images[property] = value;
            return;
        }

        if (value !== this.props.data[property]) {
            this.changedState[typeof value][property] = value;
        } else {
            delete this.changedState[typeof value][property]
        }

    }

    updateState = (property, value) => {
        this.setState({ [property]: value }, () => {
            if (this.state.landscape_image && this.state.portrait_image) {
                this.setState({ imagesComplete: true })
            } else {
                this.setState({ imagesComplete: false })
            }
            this.addToChangedState(property, value)
        })
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
            case 0: return <Info state={this.state} updateStateOnParent={this.updateState} />
            case 1: return <Images state={this.state} updateStateOnParent={this.updateState} />
            case 2: return <Review state={this.changedState} updateStateOnParent={this.updateState} />
            default: return ''
        }
    }


    submitEventToServer = () => {
        this.setState({ submitting: true })

        let formData = new FormData();

        Object.entries(this.changedState.string).forEach(([key, value]) => {
            if (key === 'language') {
                formData.set(key, value.name ?? '');
                return;
            }
            if (key === 'responsible') {
                formData.set(key, value ?? '');
                return;
            }
            if (key === 'subtitle') {
                formData.set(key, value ?? '');
                return;
            }
            if (key === 'status') {
                formData.set(key, value ?? '');
                return;
            }
            if (value === '') {
                formData.set(key, 'empty');
                return;
            }
            formData.set(key, value)
        })
        Object.entries(this.changedState.object).forEach(([key, value]) => {
            if (key === 'serie') {
                formData.set('seriesId', value ? value.id : null);
                return;
            }
            if (value.length > 0) {
                formData.set(key, value.map(el => el.id))
            } else {
                formData.set(key, 'empty')
            }
        })
        Object.entries(this.changedState.boolean).forEach(([key, value]) => {
            formData.set(key, value)
        })
        Object.entries(this.changedState.images).forEach(([key, value]) => {
            formData.append(key, value[0])
        })
        Object.entries(this.changedState.date).forEach(([key, value]) => {
            formData.append(key, value)
        })

        if (this.changedState.boolean.has_episodes) {
            formData.set('topics', 'empty')
            formData.set('formats', 'empty')
            formData.set('types', 'empty')
            formData.set('industries', 'empty')
            formData.set('responsible', '')
            formData.set('region', '')
            formData.set('organization', '')
            formData.set('status', '')
            formData.set('salesforce_url', '')
        }

        editWithFiles('events', this.props.data.id, formData).then(res => {
            this.setState({ infoDialogData: { title: 'Success', message: res.data.message } }, () => {
                this.setState({ openInfoDialog: true, submitting: false })
            })
        }, err => {
            console.log(err)
            this.setState({ errDialogInfo: err.response ? err.response : err }, () => {
                this.setState({ openErrDialog: true, submitting: false })
            })
        })
    }

    handleInfoDialogClose = () => {
        this.props.refresh();
        fetchEvents(true)
        this.props.close();
        this.resetState()
    }

    render() {

        return (
            <Dialog
                fullWidth
                maxWidth={this.state.has_episodes ? 'md' : 'lg'}
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby="max-width-dialog-title"
                className='event-mui-dialog-container'
            >
                <DialogContent className='add-event-dialog-content'>
                    <div className='add-event-header'>
                        <p>Edit Event</p>
                    </div>
                    <div className='add-event-stepper'>
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StyledStepConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className='add-event-inputs'>
                        {this.getStepContent()}
                    </div>
                    <div className='add-event-action'>
                        <button className='stepper-buttons' disabled={this.state.activeStep === 0} onClick={this.handlePrevStep}>Previous</button>
                        {this.state.activeStep === steps.length - 1 ? (
                            this.state.submitting ? (
                                <ThreeDots fill='var(--orange)' height='1em' />
                            ) : (
                                <button className='stepper-buttons' disabled={!this.checkSubmitBtn()} onClick={() => this.submitEventToServer()} >Submit</button>
                            )
                        ) : (
                            <button className='stepper-buttons' disabled={!this.checkNextBtn()} onClick={this.handleNextStep} >Next</button>
                        )}
                    </div>
                </DialogContent>
                <ErrorDialog
                    open={this.state.openErrDialog}
                    close={() => this.setState({ openErrDialog: false })}
                    ocb={() => this.setState({ openErrDialog: false })}
                    info={this.state.errDialogInfo}
                />
                <InfoDialog
                    open={this.state.openInfoDialog}
                    close={() => this.handleInfoDialogClose()}
                    ocb={() => this.handleInfoDialogClose()}
                    info={this.state.infoDialogData}
                />
            </Dialog>
        )
    }
}

export default EditEvent