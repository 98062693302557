import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import { Tooltip } from '@mui/material'
import { Delete, Restore, Visibility } from '@mui/icons-material'
import TableCustomToolbar from '../TableCustomToolbar'

import MoreInfo from '../../Dialogs/Events/MoreInfo'
import { TooltipHintsEvents } from '../../../Data/TooltipsHints'

import '../EventsTable.css'

const CustomTableBody = (props) => {
    return (
        <div className='custom-table-body' style={props.value ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }}>{props.value.toString()}</div>
    )
}

const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        }
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        borderLeft: '5px solid var(--light-red)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    }
})

function EventsTable(props) {
    const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState({})
    const [btnVisible, setBtnVisible] = useState(false)
    const [multipleSelected, setMultipleSelected] = useState([])
    const [rowsSelected, setRowsSelected] = useState([])

    const CustomActionTableBody = (props) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100px' }}>
                <Tooltip title='Delete Item'>
                    <Delete className='action-icon' onClick={() => deleteItems([props.meta.rowData[0]])} />
                </Tooltip>
                <Tooltip title='Restore Item'>
                    <Restore className='action-icon' onClick={() => restoreItems([props.meta.rowData[0]])} />
                </Tooltip>
                <Tooltip title='View More info'>
                    <Visibility className='action-icon' onClick={() => handleRowClick(props.meta.rowData[0])} />
                </Tooltip>
            </div>
        )
    }

    const getID = (id) => {
        return props.data.filter(faq => faq.id === id)[0]
    }

    const deleteItems = (ids) => {
        setRowsSelected([])
        props.delete('Events', ids)
    }

    const restoreItems = (ids) => {
        setRowsSelected([])
        props.restore('Events', ids)
    }

    const handleRowClick = (id) => {
        setSelectedEvent(getID(id))
        setOpenMoreInfoDialog(true)
    }

    const onRowsSelection = (lastSelected, allSelected, rowSelected) => {

        setBtnVisible(allSelected.length !== 0)

        const items = allSelected.map(data => props.data[data.dataIndex].id)
        setRowsSelected(rowSelected)
        setMultipleSelected(items)
    }   

    const options = {
        filter: false,
        responsive: 'standard',
        rowsPerPageOptions: [10, 25, 50, 100],
        selectToolbarPlacement: 'none',
        textLabels: {body:{ noMatch: "No records found" }},
        customToolbar: () => {
            return (
                <TableCustomToolbar visible={btnVisible} restoreCb={() => restoreItems(multipleSelected)} deleteCb={() => deleteItems(multipleSelected)} />
            )
        },
        onRowSelectionChange: onRowsSelection,
        rowsSelected: rowsSelected
    };

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: false } },
        { name: 'title', label: 'Title', options: { filter: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ maxWidth: '225px' }}>{value}</p> } },
        { name: 'available', label: 'Available', options: { hint: TooltipHintsEvents.available, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'highlighted', label: 'Highlighted', options: { hint: TooltipHintsEvents.highlighted, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'online', label: 'Online', options: { hint: TooltipHintsEvents.online, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'private', label: 'Private', options: { hint: TooltipHintsEvents.private, filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} /> } },
        { name: 'episodes', label: 'Episodes', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center' }}>{value.length}</p> } },
        { name: 'start_date', label: 'Start Date', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p>{moment(value).format('YYYY-MM-DD')}</p> } },
        { name: 'end_date', label: 'End Date', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p>{moment(value).format('YYYY-MM-DD')}</p> } },
        { name: 'online', label: 'Actions', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomActionTableBody value={value} meta={tableMeta} update={updateValue} /> } },
    ]

    return (
        <div style={{ marginTop: '20px' }}>
            <MuiDataTableStyled
                title={"Events"}
                data={props.data}
                columns={columns}
                options={options}
            />
            {selectedEvent && <MoreInfo hideActions={true} open={openMoreInfoDialog} close={() => setOpenMoreInfoDialog(false)} data={selectedEvent} />}
        </div>
    )
}

export default EventsTable