import React from 'react'
import { TextField, Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material'
import { TooltipHintsEvents } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'

import '../Add/Info.css'

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			nameIsValid: false,
			job_titleIsValid: false,
			social_linksIsValid: true,
			formIsValid: false,
			formErrors: { name: '', job_title: '', social_links: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		if (this.state.infoComplete) {
			this.setState({
				nameIsValid: true,
				job_titleIsValid: true,
				social_linksIsValid: true,
				formErrors: { title: '', description: '', video: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let nameIsValid = this.state.nameIsValid;
		let job_titleIsValid = this.state.job_titleIsValid;
		let social_linksIsValid = this.state.social_linksIsValid;

		switch (name) {
			case 'title':
				nameIsValid = value.length > 4;
				fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid name`}`
				break;
			case 'description':
				job_titleIsValid = value.length > 10;
				fieldValidationErrors.job_title = job_titleIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid Job Title`}`
				break;
			case 'video':
				social_linksIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.social_links = social_linksIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			nameIsValid: nameIsValid,
			job_titleIsValid: job_titleIsValid,
			social_linksIsValid: social_linksIsValid,
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.nameIsValid &&
				this.state.job_titleIsValid &&
				this.state.social_linksIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		return (
			<div className='speaker-info-main-container' >
				<div className='speaker-info-left-container'>
					<div className='speaker-info-input-container'>
						<TextField
							required
							autoFocus
							id='name'
							name='name'
							label='Name'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							autoComplete='name'
							variant='standard'
							value={this.state.name}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.nameIsValid}
							helperText={this.state.formErrors.name}
						/>
					</div>
					<div className='speaker-info-input-container'>
						<TextField
							required
							id='job_title'
							color='secondary'
							name='job_title'
							label='Job Title'
							fullWidth
							variant='standard'
							type='text'
							value={this.state.job_title}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.job_titleIsValid}
							helperText={this.state.formErrors.job_title}
						/>
					</div >
					<div className='speaker-info-input-container'>
						<TextField
							required
							id='social_links'
							color='secondary'
							name='social_links'
							label='LinkedIn'
							fullWidth
							variant='standard'
							type='url'
							value={this.state.social_links}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.social_linksIsValid}
							helperText={this.state.formErrors.social_links}
						/>
					</div>
					<FormGroup>
						<Tooltip title={TooltipHintsEvents.online} disableInteractive>
							<FormControlLabel control={<Checkbox
								name='online'
								color='secondary'
								value={this.state.online}
								checked={this.state.online}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Online' />
						</Tooltip>
					</FormGroup>
				</div>
			</div>
		)
	}
}

export default Info