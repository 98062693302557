import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title, Subtitles, Description, Translate, Person } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    OndemandVideoOutlined as EpisodesIcon,
    QueuePlayNext as AddEpisodeIcon,
    PowerSettingsNew as OnlineIcon,
    Language as PageIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import DeleteDialog from '../DeleteDialog';
import SmallEpisodeCard from '../../Cards/Episodes/SmallEpisodeCard'
import AddEpisode from '../../Dialogs/Episodes/Add/AddEpisode'

import { openInNewTab } from '../../../utils';
import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { getEntity, setEntityOnlineOrOffline } from '../../../API/requests';
import EditEvent from './Edit/EditEvent';
import useUserInfo from '../../../customHooks/useUserInfo'

import { fetchIndustries } from '../../../Data/Industries';
import { fetchTopics } from '../../../Data/Topics';
import { fetchSpeakers } from '../../../Data/Speakers';

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

function MoreInfo(props) {
    const [event, setEvent] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddEpisode, setOpenAddEpisode] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    useEffect(() => {
        if (props.open) {
            getEntity('events', props.data.id).then(res => {
                setEvent(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props])

    const setOnlineDialogInfo = { title: `Set event ${props.data.online ? 'Offline' : 'Online'}?`, message: `Set event ${props.data.title} ${props.data.online ? 'Offline' : 'Online'}?` }

    const handleSetEventOnline = () => {
        setEntityOnlineOrOffline('events', props.data.id, !props.data.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    const updateLists = (lists) => {
        switch (lists) {
            case 'industries': fetchIndustries(true);
                break;
            case 'topics': fetchTopics(true);
                break;
            case 'speakers': fetchSpeakers(true);
                break;
            default: return;

        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='event-mui-dialog-container'
        >
            {event &&
                <>
                    <DialogContent className='event-more-info-dialog-content'>
                        <div className='event-more-info-dialog-header'>
                            <div className='event-dialog-title'>
                                <p style={{ color: 'white', fontSize: '18px' }}>{event.title}</p>
                                <p style={{ color: '#bbbbbb', fontSize: '14px' }}>{`From ${moment(event.start_date).format('YYYY MMMM DD')} to ${moment(event.end_date).format('YYYY MMMM DD')} `}</p>
                            </div>
                            <div className='event-dialog-actions'>
                                {!props.hideActions &&
                                    <>
                                        {userInfo.user_role.canRemoveEvent &&
                                            <Tooltip title='Delete Event'>
                                                <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                            </Tooltip>
                                        }
                                        {userInfo.user_role.canEditEvent &&
                                            <Tooltip title='Edit Event'>
                                                <EditIcon onClick={() => setOpenEditDialog(true)} />
                                            </Tooltip>
                                        }
                                        {userInfo.user_role.canAddEpisode &&
                                            <Tooltip title='Add Episode to Event'>
                                                <AddEpisodeIcon onClick={() => setOpenAddEpisode(true)} />
                                            </Tooltip>
                                        }
                                        {userInfo.user_role.canEditEvent &&
                                            <Tooltip title={`Set event ${event.online ? 'Offline' : 'Online'}`}>
                                                <OnlineIcon sx={event.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetEventOnline())} />
                                            </Tooltip>
                                        }
                                    </>}
                            </div>
                        </div>
                        <div className='event-more-info-dialog-info-wrapper'>
                            <div className='event-more-info-dialog-episodes-div'>
                                <p style={{ textAlign: 'center' }}>{`${event.episodes.length} Episodes`}</p>
                                <div className='event-more-info-dialog-episodes-scroll-div'>
                                    {event.episodes.map(episode => {
                                        return (
                                            <SmallEpisodeCard event_title={event.title} key={episode.id} episode={episode} refresh={props.refresh} />
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider orientation='vertical' sx={{ height: '90%' }} />
                            <div className='event-more-info-info-container'>
                                <div className='event-more-info-dialog-info-div'>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    <Title />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText primary='Title' secondary={event.title} />
                                        </ListItem>
                                        {event.subtitle &&
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <StyledAvatar>
                                                        <Subtitles />
                                                    </StyledAvatar>
                                                </ListItemAvatar>
                                                <ListItemText primary='Subtitle' secondary={event.subtitle} />
                                            </ListItem>
                                        }
                                        <ListItem>
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    <Description />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText primary='Description' secondary={event.description} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    <Description />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText primary='Serie' secondary={event.series ? event.series.title : 'N/D'} />
                                        </ListItem>
                                        <ListItem>
                                            {event.trailer?.length > 0 &&
                                                <div className='list-item-div' onClick={() => openInNewTab(event.trailer)}>
                                                    <ListItemAvatar >
                                                        <StyledAvatar>
                                                            <EpisodesIcon />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText sx={{ wordBreak: 'break-all' }} primary='Trailer' secondary='Click to view' />
                                                </div>
                                            }
                                            {event.event_page?.length > 0 &&
                                                <div className='list-item-div' onClick={() => openInNewTab(event.event_page)}>
                                                    <ListItemAvatar >
                                                        <StyledAvatar>
                                                            <PageIcon />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Event Page' secondary='Click to visit' />
                                                </div>
                                            }
                                        </ListItem>
                                        <div className='joined-list-items'>
                                            {event.topics.length > 0 &&
                                                <ListItem sx={{ width: 'fit-content' }}>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Topics' secondary={event.topics.map((topic, index) => {
                                                        return (
                                                            <label key={topic.id}>{index === 0 ? `${topic.name}` : `, ${topic.name}`}</label>
                                                        )
                                                    })} />
                                                </ListItem>
                                            }
                                            {event.industries.length > 0 &&
                                                <ListItem sx={{ width: 'fit-content' }}>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Industries' secondary={event.industries.map((inds, index) => {
                                                        return (
                                                            <label key={inds.id}>{index === 0 ? `${inds.name}` : `, ${inds.name}`}</label>
                                                        )
                                                    })} />
                                                </ListItem>
                                            }
                                            {event.formats.length > 0 &&
                                                <ListItem sx={{ width: 'fit-content' }}>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Formats' secondary={event.formats.map((format, index) => {
                                                        return (
                                                            <label key={format.id}>{index === 0 ? `${format.name}` : `, ${format.name}`}</label>
                                                        )
                                                    })} />
                                                </ListItem>
                                            }
                                            {event.types.length > 0 &&
                                                <ListItem sx={{ width: 'fit-content' }}>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Type' secondary={event.types.map((type, index) => {
                                                        return (
                                                            <label key={type.id}>{index === 0 ? `${type.name}` : `, ${type.name}`}</label>
                                                        )
                                                    })} />
                                                </ListItem>
                                            }
                                            {event.location &&
                                                <ListItem sx={{ width: 'fit-content' }}>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Location' secondary={event.location} />
                                                </ListItem>
                                            }
                                            {event.language &&
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Translate />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Language' secondary={event.language} />
                                                </ListItem>
                                            }
                                            {event.responsible &&
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Person />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Responsible' secondary={event.responsible} />
                                                </ListItem>
                                            }
                                            {event.region &&
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Region' secondary={event.region} />
                                                </ListItem>
                                            }
                                            {event.organization &&
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Organization' secondary={event.organization} />
                                                </ListItem>
                                            }
                                            {event.status &&
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Status' secondary={event.status} />
                                                </ListItem>
                                            }
                                            {event.salesforce_url &&
                                                <ListItem sx={{ cursor: 'pointer' }} onClick={() => openInNewTab(event.salesforce_url)}>
                                                    <ListItemAvatar >
                                                        <StyledAvatar>
                                                            <Description />
                                                        </StyledAvatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary='Salesforce Link' secondary='Click to visit' />
                                                </ListItem>
                                            }
                                        </div>
                                    </List>
                                    <div className='event-more-info-chips-div'>
                                        <BooleanChip value={event.available} label='Available?' tooltip='available' />
                                        <BooleanChip value={event.highlighted} label='Highlighted?' tooltip='highlighted' />
                                        <BooleanChip value={event.online} label='Online?' tooltip='online' />
                                        <BooleanChip value={event.private} label='Private?' tooltip='private' />
                                    </div>
                                </div>
                                <Divider orientation='horizontal' sx={{ borderColor: 'rgb(216 217 222)', width: '95%' }} />
                                <DialogActions className='event-more-info-dialog-images'>
                                    <div className='event-more-info-other-info'>
                                        <p>{`ID: ${event.id}`}</p>
                                        <p>{`Image ID: ${event.image_id}`}</p>
                                        <p>{`Created At: ${moment(event.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                                        <p>{`Last Update: ${moment(event.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                                    </div>
                                    <Divider orientation='vertical' sx={{ height: '90%' }} />
                                    <div className='event-more-info-images-container'>
                                        <img
                                            alt='event portrait'
                                            src={`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.portrait_image}`}
                                            width='auto'
                                            height='100%'
                                            onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.portrait_image}`)}
                                        />
                                        <img
                                            alt='event landscape'
                                            src={`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.landscape_image}`}
                                            width='auto'
                                            height='100%'
                                            onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.landscape_image}`)}
                                        />
                                    </div>
                                </DialogActions>
                            </div>
                        </div>
                    </DialogContent>
                    <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} type='Event' data={event} refresh={props.refresh} />
                    <EditEvent open={openEditDialog} close={() => setOpenEditDialog(false)} data={event} refresh={props.refresh} />
                    <AddEpisode updateLists={(list) => updateLists(list)} predefinedEvent={event} open={openAddEpisode} close={() => setOpenAddEpisode(false)} refresh={() => { return }} />
                </>
            }
        </Dialog>
    )
}

export default MoreInfo

