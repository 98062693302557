import React from 'react'
import MUIDataTable from 'mui-datatables'
import { styled } from '@mui/material/styles'
import { FormLabel, TextField } from '@mui/material'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment'

import './EventsTable.css'


const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    width: '100%',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        },
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)',
        borderLeft: '5px solid var(--light-red)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    }

})

function ActivityTable(props) {


    const options = {
        filter: true,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        // onRowClick: item => handleRowClick(item[0])
    };

    const columns = [
        { name: 'user', label: 'User', options: { filter: false } },
        { name: 'activity', label: 'Activity', options: { filter: false } },
        {
            name: 'createdAt',
            label: 'Date',
            options: {
                searchable: false,
                customBodyRender: (value, tableMeta, updateValue) => <p>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</p>,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`From: ${v[0]}`, `To: ${v[1]}`]
                        } else if (v[0]) {
                            return `From: ${v[0]}`;
                        } else if (v[1]) {
                            return `To: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div style={{ width: '200%' }}>
                            <FormLabel>Date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                                    <MobileDatePicker
                                        showToolbar={false}
                                        inputFormat='yyyy-MM-dd'
                                        mask='____-__-__'
                                        InputAdornmentProps={{ position: 'start' }}
                                        label='From'
                                        value={filterList[index][0] || null}
                                        onChange={value => {
                                            if (value) {
                                                filterList[index][0] = moment(value).format('YYYY-MM-DD');
                                                onChange(filterList[index], index, column)
                                            }
                                        }}
                                        renderInput={(params) => <TextField variant='standard' sx={{ width: '45%' }} {...params} />}
                                    />
                                    <MobileDatePicker
                                        showToolbar={false}
                                        inputFormat='yyyy-MM-dd'
                                        mask='____-__-__'
                                        InputAdornmentProps={{ position: 'start' }}
                                        label='To'
                                        value={filterList[index][1] || null}
                                        onChange={value => {
                                            if (value) {
                                                filterList[index][1] = moment(value).format('YYYY-MM-DD');
                                                onChange(filterList[index], index, column)
                                            }
                                        }}
                                        renderInput={(params) => <TextField variant='standard' sx={{ width: '45%' }} {...params} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </div>
                    ),
                }
            }
        },
    ]

    return (
        <div style={{ marginTop: '20px', width: '100%', maxWidth: '1600px' }}>
            <MuiDataTableStyled
                title={"Activity"}
                data={props.data}
                columns={columns}
                options={options}
            />
        </div>
    )
}

export default ActivityTable