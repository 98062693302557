import { useState } from 'react'

export default function useToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        if (isJsonParsable(tokenString)) {
            const userToken = JSON.parse(tokenString)
            return userToken?.token
        }
        return null
    }
    
    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
        if(userToken === null) {
            sessionStorage.removeItem('token')
            setToken(null)
        } else {
            sessionStorage.setItem('token', JSON.stringify(userToken))
            setToken(userToken.token)
        }
    }

    return {
        setToken: saveToken,
        token
    }
}

const isJsonParsable = string => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
}