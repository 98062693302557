import React from 'react'
import { TextField, Checkbox, FormControlLabel, Tooltip } from '@mui/material'
import { TooltipHintsSeries } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'
import './Info.css'


class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			titleIsValid: false,
			descriptionIsValid: false,
			formIsValid: false,
			formErrors: { title: '', description: '' }
		}
	}

	componentDidMount() {
		if (this.state.infoComplete) {
			this.setState({
				titleIsValid: true,
				descriptionIsValid: true,
				formErrors: { title: '', description: '' }
			})
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let titleIsValid = this.state.titleIsValid;
		let descriptionIsValid = this.state.descriptionIsValid;

		switch (name) {
			case 'title':
				titleIsValid = value.length > 4;
				fieldValidationErrors.title = titleIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid title`}`
				break;
			case 'description':
				descriptionIsValid = value.length > 10;
				fieldValidationErrors.description = descriptionIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid description`}`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			titleIsValid,
			descriptionIsValid,
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.titleIsValid &&
				this.state.descriptionIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {
		return (
			<div className='serie-info-main-container' >
				<div className='serie-info-left-container'>
					<div className='serie-info-input-container'>
						<TextField
							required
							autoFocus
							id='title'
							name='title'
							label='Title'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.title}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.titleIsValid}
							helperText={this.state.formErrors.title}
						/>
					</div>
					<div className='serie-info-input-container'>
						<TextField
							required
							multiline
							rows={6}
							id='description'
							color='secondary'
							name='description'
							label='Description'
							fullWidth
							variant='standard'
							type='text'
							value={this.state.description}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.descriptionIsValid}
							helperText={this.state.formErrors.description}
						/>
					</div >
					<div className='serie-info-checkbox-container'>
						<Tooltip title={TooltipHintsSeries.private}>
							<FormControlLabel control={<Checkbox
								name='private'
								color='secondary'
								value={this.state.private}
								checked={this.state.private}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Private' sx={{ marginRight: '10px' }} />
						</Tooltip>
						<Tooltip title={TooltipHintsSeries.online}>
							<FormControlLabel control={<Checkbox
								name='online'
								color='secondary'
								value={this.state.online}
								checked={this.state.online}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Online' sx={{ marginRight: '10px' }} />
						</Tooltip>
					</div>
				</div>
			</div>
		)
	}
}

export default Info