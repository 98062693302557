import './App.css';
import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { getEntity } from './API/requests';

import { SnackbarProvider } from 'notistack'
import { SocketContext } from './context/socket/socket';
import ModalProvider from 'mui-modal-provider'

import DashboardPage from './pages/DashboardPage'
import EventsPage from './pages/EventsPage2'
import EpisodesPage from './pages/EpisodesPage2'
import SpeakersPage from './pages/SpeakersPage'
import BackofficeUsersPage from './pages/BackofficeUsersPage';
import Lists from './pages/Lists'
import Activity from './pages/Activity'
import RecycleBin from './pages/RecycleBin'
import Help from './pages/help'
import ReportsPage from './pages/ReportsPage'
import SeriesPage from './pages/SeriesPage';
import UsersPage from './pages/UsersPage'

import TopPanel from './components/panels/TopPanel';
import SidePanel from './components/panels/sidePanel/SidePanel';
import useToken from './customHooks/useToken';
import useUserInfo from './customHooks/useUserInfo';

import { fetchIndustries } from './Data/Industries'
import { fetchSpeakers } from './Data/Speakers'
import { fetchTopics } from './Data/Topics'
import { fetchEvents } from './Data/Events'

function App() {
	const { token } = useToken()
	const { userInfo, setUserState } = useUserInfo()
	const socket = useContext(SocketContext)

	const [ready, setReady] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		socket.on('ready', getData)

		return () => {
			socket.off('ready', getData)
		}
	}, [])

	useEffect(() => {
		if (!token) {
			navigate('/login', { replace: true })
			return;
		}
		connectSocket()

	}, [token])

	const connectSocket = async () => {
		if (socket.connected) {
			getData();
			return;
		}
		const uuid = sessionStorage.getItem('id');

		if (uuid !== 'undefined') {
			socket.auth = { uuid, token: token }
			socket.connect();
		} else {
			navigate('/login', { replace: true })
		}
	}

	const getData = async () => {
		await fetchEvents();
		await fetchIndustries();
		await fetchSpeakers();
		await fetchTopics();

		await getEntity('backoffice_users', sessionStorage.getItem('id')).then(res => {
			setUserState({ ...res.data, token })
			setReady(true)
		}, err => {
			console.log(err)
			navigate('/login', { replace: true })
		})
	}

	const PermissionElement = ({ permission, element }) => {
		if (userInfo.user_role[permission]) {
			return element
		}
		return (
			<Navigate to='/app/dashboard' />
		)
	}

	return (
		ready ?
			<div>
				<SnackbarProvider maxSnack={6}>
					<ModalProvider>
						<TopPanel />
						<div style={{ display: 'flex', paddingLeft: '72px' }}>
							<SidePanel />
							<Routes>
								<Route path='/dashboard' element={<DashboardPage />} />
								<Route path='/series' element={<PermissionElement permission='canViewSerie' element={<SeriesPage />} />} />
								<Route path='/events' element={<PermissionElement permission='canViewEvent' element={<EventsPage />} />} />
								<Route path='/episodes' element={<PermissionElement permission='canViewEpisode' element={<EpisodesPage />} />} />
								<Route path='/speakers' element={<PermissionElement permission='canViewSpeaker' element={<SpeakersPage />} />} />
								<Route path='/backoffice-users' element={<PermissionElement permission='canViewBackofficeUser' element={<BackofficeUsersPage />} />} />
								<Route path='/users' element={<PermissionElement permission='CanViewUser' element={<UsersPage />} />} />
								<Route path='/lists' element={<PermissionElement permission='canViewLists' element={<Lists />} />} />
								<Route path='/activity' element={<PermissionElement permission='canViewActivity' element={<Activity />} />} />
								<Route path='/recycle' element={<PermissionElement permission='canManageTrash' element={<RecycleBin />} />} />
								<Route path='/reports' element={<PermissionElement permission='canViewReport' element={<ReportsPage />} />} />
								<Route path='/help' element={<Help />} />
								<Route path='*' element={<Navigate to='/login' />} />
							</Routes>
						</div>
					</ModalProvider>
				</SnackbarProvider>
			</div >
			: ''
	)
}

export default App