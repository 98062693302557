import { getEntity } from "../API/requests"

export let topics = []

export const getTopics = (ids) => {
    if (ids === null) return []
    const idsArray = ids.split(',')
    return topics.filter(topic => idsArray.includes(topic.id.toString()))
}

export async function fetchTopics(force) {

    if (topics.length > 0 && !force) return topics;

    getEntity('topics').then(res => {
        topics = [...res.data]
        return [...res.data]
    }, err => {
        console.log(err)
    })
}