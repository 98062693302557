import React, { useState, useEffect } from 'react';
import SerieCard from '../components/Cards/Series/SerieCard';
import Search from '../components/Search/Search';
import AddSerie from '../components/Dialogs/Series/Add/AddSerie';

import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'

import useUserInfo from '../customHooks/useUserInfo';
import { getUserPrefs } from '../components/user/UserPrefs';

import './css/EventsPage.css'
import { ThreeDots } from 'react-loading-icons'
import { getEntity } from '../API/requests';

let staticSeries = []

const serieSortFields = [
	{ name: 'ID', value: 'id' },
	{ name: 'Nº Events', value: 'events' },
	{ name: 'Title', value: 'title' },
]

function SeriesPage() {
	const [series, setSeries] = useState([])
	const [filterData, setFilterData] = useState([])
	const [refreshSearch, setRefreshSearch] = useState(false)
	const [openAddSerie, setOpenAddSerie] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const { userInfo } = useUserInfo()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)

		getEntity('series').then(res => {
			staticSeries = res.data
			setSeries([...res.data])
			setFilterData([...res.data])
			setLoading(false)
		}, err => {
			console.log(err)
			setLoading(false)
		})

	}, [refresh])

	return (
		<div className='events-page-main-div'>
			<div className='events-page-top-div'>
				<div className='events-page-top-add'>
					{userInfo.user_role.canAddSerie &&
						<Fab
							sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
							onClick={() => setOpenAddSerie(true)}
						>
							<Add />
							<p>Add new Serie</p>
						</Fab>
					}
				</div>
				<div className='events-page-top-info-main-div'>
					<SerieTopInfo title='Total Series' value={staticSeries.length} />
				</div>
			</div>
			<div className='events-page-bottom-div'>
				<div className='bottom-div-events'>
					<div className='bottom-div-events-wrapper'>
						<Search
							entity='series'
							refresh={refreshSearch}
							data={filterData}
							orderFields={serieSortFields}
							order={getUserPrefs('series').order.order}
							defaultOrderDirection='desc'
							entitySearchProperty='title'
							setParentData={(data) => setSeries(data)}
						/>
						{loading ? <ThreeDots fill='orange' style={{ marginTop: '100px' }} /> :
							<div className='bottom-div-events-cards' style={{ marginTop: '50px', gap: '50px' }}>
								{series.length === 0 ?
									<p>There is no serie that match your filters criteria</p>
									:
									series.map(serie => {
										return (
											<SerieCard key={serie.id} serie={serie} refresh={() => setRefresh(!refresh)} />
										)
									})
								}
							</div>}
					</div>
				</div>
			</div>
			{userInfo.user_role.canAddSerie && <AddSerie open={openAddSerie} close={() => setOpenAddSerie(false)} refresh={() => setRefresh(!refresh)} />}
		</div>
	);
}

const SerieTopInfo = ({ title, value }) => {
	return (
		<div className='events-page-top-info'>
			<p className='events-page-top-info-title'>{title}</p>
			<p className='events-page-top-info-value'>{value}</p>
		</div>
	)
}

export default SeriesPage;
