import axios from 'axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const getHeaders = async () => {
    return {
        "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
        "requesting-user": `bo_${sessionStorage.getItem('id')}`,
    }
}

const getFileHeaders = async (data) => {
    return {
        "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
        "requesting-user": `bo_${sessionStorage.getItem('id')}`,
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }
}

export const loginUser = async (email, password) => {
    return await axios.post(`${SERVER_URL}/api/backoffice_users/auth`, { email, password })
}

export const forgotPass = async (email) => {
    return await axios.post(`${SERVER_URL}/api/backoffice_users/passresetrequest`, { email })
}

export const getEntity = async (entity, id) => {
    return await axios.get(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ''}`, { headers: await getHeaders() })
}

export const createEntity = async (entity, data) => {
    return await axios.post(`${SERVER_URL}/api/${entity}`, data, { headers: await getHeaders() })
}

export const editEntity = async (entity, id, data) => {
    return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, data, { headers: await getHeaders() })
}

export const removeEntity = async (entity, id, data) => {
    return await axios.delete(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ''}`, { headers: await getHeaders() }, data)
}

export const createWithFile = async (entity, data) => {
    return await axios.post(`${SERVER_URL}/api/${entity}`, data, { headers: await getFileHeaders(data) })
}

export const editWithFiles = async (entity, id, data) => {
    return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, data, { headers: await getFileHeaders(data) })
}

export const changePortrait = async (id, action, data) => {
    return await axios.put(`${SERVER_URL}/api/backoffice_users/${action}/picture/${id}`, data, { headers: await getFileHeaders(data) })
}

export const changePassword = async (id, data) => {
    return await axios.put(`${SERVER_URL}/api/backoffice_users/update/password/${id}`, data, { headers: await getHeaders() })
}

export const setEntityOnlineOrOffline = async (entity, id, online) => {
    return await axios.put(`${SERVER_URL}/api/${entity}/${id}`, { online }, { headers: await getHeaders() })
}

export const getFiltersData = async (entity) => {
    return await axios.get(`${SERVER_URL}/api/filter-data/${entity}`, { headers: await getHeaders() })
}
