import React from 'react'
import { Avatar } from '@mui/material'

import './UserSmallCard.css'

function UserSmallCard(props) {
   
    return (
        <>
            <div className='user-card-main-container'>
                {props.data.picture ? (
                    <Avatar sx={{ filter: 'drop-shadow(1px 1px 3px black)', marginLeft: '-15px', width: '55px', height: '55px' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/users/${props.data.picture}`} />
                ) : (
                    <Avatar sx={{ filter: 'drop-shadow(1px 1px 3px black)', marginLeft: '-15px', width: '55px', height: '55px' }} />
                )}
                <div className='user-card-text-div'>
                    <p>{props.data.name}</p>
                </div>
            </div>
        </>
    )
}

export default UserSmallCard