import React, { useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    PowerSettingsNew as OnlineIcon
} from '@mui/icons-material'
import EditFaq from './Edit/EditFaq'

import { styled } from '@mui/material/styles'

import DeleteDialog from '../DeleteDialog';

import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { setEntityOnlineOrOffline } from '../../../API/requests';
import { openInNewTab } from '../../../utils';
import regexifyString from 'regexify-string'
import useUserInfo from '../../../customHooks/useUserInfo'

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

const regexifyPaterm = /\[\[(.*?)\]\]/gim;
const regexifyDecorator = (match, index) => {
    const string = match.substring(2, match.length - 2)
    return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>;
}

function MoreInfo(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    const setOnlineDialogInfo = { title: `Set FAQ ${props.faq.online ? 'Offline' : 'Online'}?`, message: `Set FAQ ${props.faq.id} ${props.faq.online ? 'Offline' : 'Online'}?` }

    const handleSetSpeakerOnline = () => {
        setEntityOnlineOrOffline('faq', props.faq.id, !props.faq.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <Dialog
            maxWidth='sm'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='faq-mui-dialog-container'
        >
            <DialogContent className='faq-more-info-dialog-content'>
                <div className='faq-more-info-dialog-header'>
                    <div className='faq-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{`FAQ ID: ${props.faq.id}`}</p>
                    </div>
                    <div className='faq-dialog-actions'>
                        {!props.hideActions &&
                            <>
                                {userInfo.user_role.canRemoveFaq &&
                                    <Tooltip title='Delete FAQ'>
                                        <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                    </Tooltip>
                                }
                                {userInfo.user_role.canEditFaq &&
                                    <>
                                        <Tooltip title='Edit FAQ'>
                                            <EditIcon onClick={() => setOpenEditDialog(true)} />
                                        </Tooltip>
                                        <Tooltip title={`Set FAQ ${props.faq.online ? 'Offline' : 'Online'}`}>
                                            <OnlineIcon sx={props.faq.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetSpeakerOnline())} />
                                        </Tooltip>
                                    </>
                                }
                            </>}
                    </div>
                </div>
                <div className='faq-more-info-dialog-info-wrapper'>
                    <div className='faq-more-info-info-container'>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <StyledAvatar>
                                        <Title />
                                    </StyledAvatar>
                                </ListItemAvatar>
                                <ListItemText primary='Question' secondary={regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: props.faq.question })} />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <StyledAvatar>
                                        <Title />
                                    </StyledAvatar>
                                </ListItemAvatar>
                                <ListItemText primary='Answer' secondary={regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: props.faq.answer })} />
                            </ListItem>
                        </List>
                        <div className='faq-more-info-chips-div'>
                            <BooleanChip value={props.faq.online} label='Online?' tooltip='online' />
                        </div>
                    </div>
                    <Divider orientation='horizontal' sx={{ width: '90%' }} />
                    <div className='faq-more-info-other-info'>
                        <p>{`ID: ${props.faq.id}`}</p>
                        <p>{`Created At: ${moment(props.faq.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                        <p>{`Last Update: ${moment(props.faq.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                    </div>
                </div>
            </DialogContent>
            <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} nameField='id' type='FAQ' data={props.faq} refresh={props.refresh} />
            <EditFaq open={openEditDialog} close={() => { setOpenEditDialog(false); props.close() }} data={props.faq} refresh={props.refresh} />
        </Dialog>
    )
}

export default MoreInfo