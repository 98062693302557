import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../context/user'

export default function useUserInfo() {
    const [userState, dispatch] = useContext(UserContext)
    
    function getUserState() {
        return userState
    }

    const [userInfo, setUserInfo] = useState(getUserState().userState)
    
    useEffect(() => {
        setUserInfo(userState.userState)
    }, [userState])


    function setUserState(payload) {
        setUserInfo(payload)
        dispatch({ type: 'setUserState', payload });
    }

    return {
        userInfo,
        setUserState
    }
}