import React from 'react'
import { TextField, Checkbox, FormControlLabel, Tooltip, Autocomplete, Popper, Avatar } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TooltipHintsEvents } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'
import { getFiltersData, getEntity } from '../../../../API/requests';
import { statusDropdownOptions } from '../../../../Data/EpisodesStatus';
import { languages } from '../../../../Data/LanguageData';
import '../Add/Info.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			titleIsValid: false,
			descriptionIsValid: false,
			trailerIsValid: true,
			eventPageIsValid: true,
			formIsValid: false,
			salesForceIsValid: true,
			formErrors: { title: '', description: '', trailer: '', eventPage: '', salesForceUrl: '' },
			seriesData: null,
			responsibleData: []
		}
	}

	componentDidMount() {
		if (this.state.activeStep === 0) {
			getFiltersData('events').then(res => {
				this.setState({ dropDownData: res.data })
			}, err => {
				console.log(err)
			})

			getEntity('series').then(res => {
				this.setState({ seriesData: res.data })
			}, err => {
				console.log(err)
			})

			getEntity('backoffice_users').then(res => {
				const users = res.data.map(user => `${user.name},${user.picture}`)
				this.setState({ responsibleData: users })
			}, err => {
				console.log(err)
			})
		}

		if (this.state.infoComplete) {
			this.setState({
				titleIsValid: true,
				descriptionIsValid: true,
				trailerIsValid: true,
				eventPageIsValid: true,
				salesForceIsValid: true,
				formErrors: { title: '', description: '', trailer: '', eventPage: '', salesForceUrl: '' },
			})
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleDateInput = (name, value) => {
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	handleAutocompleteInput = (name, value) => {
		this.props.updateStateOnParent(name, value)
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let titleIsValid = this.state.titleIsValid;
		let descriptionIsValid = this.state.descriptionIsValid;
		let trailerIsValid = this.state.trailerIsValid;
		let eventPageIsValid = this.state.eventPageIsValid;
		let salesForceIsValid = this.state.salesForceIsValid;

		switch (name) {
			case 'title':
				titleIsValid = value.length > 4;
				fieldValidationErrors.title = titleIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid title`}`
				break;
			case 'description':
				descriptionIsValid = value.length > 10;
				fieldValidationErrors.description = descriptionIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid description`}`
				break;
			case 'trailer':
				trailerIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.trailer = trailerIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			case 'event_page':
				eventPageIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.eventPage = eventPageIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			case 'salesforce_url':
				salesForceIsValid = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
				fieldValidationErrors.salesForceUrl = salesForceIsValid ? '' : `${value} is not a valid url. Url must start with https:// or http://`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			titleIsValid,
			descriptionIsValid,
			trailerIsValid,
			eventPageIsValid,
			salesForceIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.titleIsValid &&
				this.state.descriptionIsValid &&
				this.state.trailerIsValid &&
				this.state.eventPageIsValid &&
				this.state.salesForceIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		const CustomPopper = (props) => {
			return <Popper {...props} style={{ width: 'max-content', maxWidth: '300px', minWidth: '150px' }} />
		}

		return (
			<div className='info-main-container' >
				<div className='info-container'>
					<div className='info-input-container'>
						<TextField
							required
							autoFocus
							id='title'
							name='title'
							label='Title'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.title}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.titleIsValid}
							helperText={this.state.formErrors.title}
						/>
					</div>
					<div className='info-input-container'>
						<TextField
							id='subtitle'
							name='subtitle'
							label='Subtitle'
							color='secondary'
							fullWidth
							multiline
							rows={2}
							variant='standard'
							type='text'
							value={this.state.subtitle}
							onChange={(e) => this.handelInput(e)}
						/>
					</div>
					<div className='info-input-container'>
						<TextField
							required
							multiline
							rows={4}
							id='description'
							color='secondary'
							name='description'
							label='Description'
							fullWidth
							variant='standard'
							type='text'
							value={this.state.description}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.descriptionIsValid}
							helperText={this.state.formErrors.description}
						/>
					</div >
					<div className='info-checkbox-container'>
						<Tooltip title={TooltipHintsEvents.private}>
							<FormControlLabel control={<Checkbox
								name='private'
								color='secondary'
								value={this.state.private}
								checked={this.state.private}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Private' sx={{ marginRight: '10px' }} />
						</Tooltip>
						<Tooltip title={TooltipHintsEvents.highlighted}>
							<FormControlLabel control={<Checkbox
								name='highlighted'
								color='secondary'
								value={this.state.highlighted}
								checked={this.state.highlighted}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Highlighted' sx={{ marginRight: '10px' }} />
						</Tooltip>
						<Tooltip title={TooltipHintsEvents.available}>
							<FormControlLabel control={<Checkbox
								name='available'
								color='secondary'
								value={this.state.available}
								checked={this.state.available}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Available' sx={{ marginRight: '10px' }} />
						</Tooltip>
						<Tooltip title={TooltipHintsEvents.online}>
							<FormControlLabel control={<Checkbox
								name='online'
								color='secondary'
								value={this.state.online}
								checked={this.state.online}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Online' sx={{ marginRight: '10px' }} />
						</Tooltip>
						<Tooltip title={TooltipHintsEvents.has_episodes}>
							<FormControlLabel control={<Checkbox
								name='has_episodes'
								color='secondary'
								value={this.state.has_episodes}
								checked={this.state.has_episodes}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='This Event has episodes?' sx={{ marginRight: '10px' }} />
						</Tooltip>
					</div>
				</div>
				<div className='info-container'>
					<div className='info-input-container'>
						<Autocomplete
							sx={{ marginTop: '3px' }}
							multiple={false}
							id="serie"
							options={this.state.seriesData ? this.state.seriesData : []}
							PopperComponent={CustomPopper}
							openOnFocus
							renderTags={(e) => <p>{`${e.title}`}</p>}
							value={this.state.serie}
							onChange={(e, newValue) => this.handleAutocompleteInput('serie', newValue)}
							getOptionLabel={(option) => option.title}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderOption={(props, option) => (
								<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
									{option.title}
								</li>
							)}
							renderInput={(params) => (
								<TextField fullWidth {...params} label='Serie' variant='standard' color='secondary' />
							)}
						/>
					</div >
					<div className='info-input-container'>
						<TextField
							id='trailer'
							color='secondary'
							name='trailer'
							label='Trailer Url'
							fullWidth
							variant='standard'
							type='url'
							value={this.state.trailer}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.trailerIsValid}
							helperText={this.state.formErrors.trailer}
						/>
					</div>
					<div className='info-input-container'>
						<TextField
							color='secondary'
							id='event_page'
							name='event_page'
							label='External Page Url'
							fullWidth
							variant='standard'
							type='url'
							value={this.state.event_page}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.eventPageIsValid}
							helperText={this.state.formErrors.eventPage}
						/>
					</div >
					<div className='joined-inputs'>
						<div className='info-input-container'>
							<Autocomplete
								sx={{ width: '100%' }}
								id="language"
								options={languages}
								limitTags={1}
								PopperComponent={CustomPopper}
								openOnFocus
								value={this.state.language}
								onChange={(e, newValue) => this.handleAutocompleteInput('language', newValue)}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.name === value.name}
								renderOption={(props, option) => (
									<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
										{option.emoji} {option.name}
									</li>
								)}
								renderInput={(params) => (
									<TextField fullWidth {...params} label='Language' variant='standard' color='secondary' />
								)}
							/>
						</div>
						<div className='info-input-container'>
							<TextField
								color='secondary'
								id='location'
								name='location'
								label='Location'
								fullWidth
								variant='standard'
								value={this.state.location}
								onChange={(e) => this.handelInput(e)}
							/>
						</div >
					</div>
					<div className='info-date-picker-container'>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
								<DatePicker
									inputFormat='yyyy-MM-dd'
									mask='____-__-__'
									label='Start Date'
									value={this.state.start_date}
									onChange={(value) => this.handleDateInput('start_date', value)}
									renderInput={(params) => <TextField sx={{ marginRight: '25px' }} variant='standard' color='secondary' {...params} />}
								/>
								<DatePicker
									inputFormat='yyyy-MM-dd'
									mask='____-__-__'
									label='End Date'
									value={this.state.end_date}
									onChange={(value) => this.handleDateInput('end_date', value)}
									renderInput={(params) => <TextField variant='standard' color='secondary' {...params} />}
								/>
							</div>
						</LocalizationProvider>
					</div>
					{!this.state.has_episodes &&
						<>
							<div className='joined-inputs'>
								<div className='info-input-container'>
									<Autocomplete
										multiple
										id="topics"
										options={this.state.dropDownData ? this.state.dropDownData.topics : []}
										disableCloseOnSelect
										limitTags={1}
										PopperComponent={CustomPopper}
										openOnFocus
										renderTags={(e) => <p>{`${e.length} selected`}</p>}
										value={this.state.topics}
										onChange={(e, newValue) => this.handleAutocompleteInput('topics', newValue)}
										getOptionLabel={(option) => option.name}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(props, option, { selected }) => (
											<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
												<Checkbox
													icon={<CheckBoxOutlineBlank fontSize='small' />}
													checkedIcon={<CheckBox fontSize='small' />}
													style={{ color: '#B14282' }}
													checked={selected}
												/>
												{option.name}
											</li>
										)}
										renderInput={(params) => (
											<TextField fullWidth {...params} label='Topics' variant='standard' color='secondary' />
										)}
									/>
								</div >
								<div className='info-input-container'>
									<Autocomplete
										multiple
										id="formats"
										options={this.state.dropDownData ? this.state.dropDownData.formats : []}
										disableCloseOnSelect
										limitTags={1}
										PopperComponent={CustomPopper}
										openOnFocus
										renderTags={(e) => <p>{`${e.length} selected`}</p>}
										value={this.state.formats}
										onChange={(e, newValue) => this.handleAutocompleteInput('formats', newValue)}
										getOptionLabel={(option) => option.name}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(props, option, { selected }) => (
											<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
												<Checkbox
													icon={<CheckBoxOutlineBlank fontSize='small' />}
													checkedIcon={<CheckBox fontSize='small' />}
													style={{ color: '#B14282' }}
													checked={selected}
												/>
												{option.name}
											</li>
										)}
										renderInput={(params) => (
											<TextField fullWidth {...params} label='Formats' variant='standard' color='secondary' />
										)}
									/>
								</div >
							</div>
							<div className='joined-inputs'>
								<div className='info-input-container'>
									<Autocomplete
										multiple
										id="types"
										options={this.state.dropDownData ? this.state.dropDownData.types : []}
										disableCloseOnSelect
										limitTags={1}
										PopperComponent={CustomPopper}
										openOnFocus
										renderTags={(e) => <p>{`${e.length} selected`}</p>}
										value={this.state.types}
										onChange={(e, newValue) => this.handleAutocompleteInput('types', newValue)}
										getOptionLabel={(option) => option.name}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(props, option, { selected }) => (
											<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
												<Checkbox
													icon={<CheckBoxOutlineBlank fontSize='small' />}
													checkedIcon={<CheckBox fontSize='small' />}
													style={{ color: '#B14282' }}
													checked={selected}
												/>
												{option.name}
											</li>
										)}
										renderInput={(params) => (
											<TextField fullWidth {...params} label='Types' variant='standard' color='secondary' />
										)}
									/>
								</div >
								<div className='info-input-container'>
									<Autocomplete
										multiple
										id="Industries"
										options={this.state.dropDownData ? this.state.dropDownData.industries : []}
										disableCloseOnSelect
										limitTags={1}
										PopperComponent={CustomPopper}
										openOnFocus
										renderTags={(e) => <p>{`${e.length} selected`}</p>}
										value={this.state.industries}
										onChange={(e, newValue) => this.handleAutocompleteInput('industries', newValue)}
										getOptionLabel={(option) => option.name}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										renderOption={(props, option, { selected }) => (
											<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
												<Checkbox
													icon={<CheckBoxOutlineBlank fontSize='small' />}
													checkedIcon={<CheckBox fontSize='small' />}
													style={{ color: '#B14282' }}
													checked={selected}
												/>
												{option.name}
											</li>
										)}
										renderInput={(params) => (
											<TextField fullWidth {...params} label='Industries' variant='standard' color='secondary' />
										)}
									/>
								</div >
							</div>

						</>
					}
				</div>
				{!this.state.has_episodes &&
					<div className='info-container'>
						<div className='info-input-container'>
							<Autocomplete
								sx={{ width: '100%' }}
								id="responsible"
								options={this.state.responsibleData}
								limitTags={1}
								PopperComponent={CustomPopper}
								openOnFocus
								value={this.state.responsible}
								onChange={(e, newValue) => this.handleAutocompleteInput('responsible', newValue)}
								getOptionLabel={(option) => option.split(',')[0]}
								isOptionEqualToValue={(option, value) => option === value}
								renderOption={(props, option) => (
									<li {...props} style={{ height: 'fit-content', fontSize: '14px', gap: '10px' }}>
										<Avatar alt={option.split(',')[0]} src={option.split(',')[1] !== 'null' ? `${SERVER_URL}/resources/images/users/${option.split(',')[1]}` : null} sx={{ width: '30px', height: '30px' }} />
										{option.split(',')[0]}
									</li>
								)}
								renderInput={(params) => (
									<TextField fullWidth {...params} label='Responsible' variant='standard' color='secondary' />
								)}
							/>
						</div>
						<div className='info-input-container'>
							<TextField
								id='region'
								color='secondary'
								name='region'
								label='Region'
								fullWidth
								variant='standard'
								value={this.state.region}
								onChange={(e) => this.handelInput(e)}
							/>
						</div>
						<div className='info-input-container'>
							<TextField
								id='organization'
								color='secondary'
								name='organization'
								label='Organization'
								fullWidth
								variant='standard'
								value={this.state.organization}
								onChange={(e) => this.handelInput(e)}
							/>
						</div>
						<div className='info-input-container'>
							<Autocomplete
								sx={{ width: '100%' }}
								id="status"
								options={statusDropdownOptions}
								limitTags={1}
								PopperComponent={CustomPopper}
								openOnFocus
								value={this.state.status}
								onChange={(e, newValue) => this.handleAutocompleteInput('status', newValue)}
								renderOption={(props, option) => (
									<li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
										{option}
									</li>
								)}
								renderInput={(params) => (
									<TextField fullWidth {...params} label='Status' variant='standard' color='secondary' />
								)}
							/>
						</div>
						<div className='info-input-container'>
							<TextField
								id='salesforce_url'
								color='secondary'
								name='salesforce_url'
								label='Salesforce Link'
								fullWidth
								variant='standard'
								type='utl'
								value={this.state.salesforce_url}
								onChange={(e) => this.handelInput(e)}
								error={!this.state.salesForceIsValid}
								helperText={this.state.formErrors.salesForceUrl}
							/>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default Info