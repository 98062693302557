import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { debounce } from '../../utils'
import { StyledEngineProvider } from "@mui/material";

import './InputTextFields.css'

const preventEvent = (e) => {
    e.preventDefault();
}

export const EmailTextField = (props) => {

    const debouncedHanldeOnChange = debounce((e) => {
        props.onChange(e)
    }, 300)

    return (
        <StyledEngineProvider injectFirst>
            <TextField
                variant='standard'
                required
                id='email'
                label='Email'
                name='email'
                className={props.color === 'light' ? 'light-scheme' : 'dark-scheme'}
                defaultValue={props.value}
                helperText={props.helperText}
                error={props.error}
                onChange={debouncedHanldeOnChange}
                autoComplete='email'
                type='email'
                autoFocus
                fullWidth
            />
        </StyledEngineProvider>
    )
}

export const PasswordTextField = (props) => {
    const [showPassword, setShowPassword] = useState(false)

    const debouncedHanldeOnChange = debounce((e) => {
        props.onChange(e)
    }, 500)

    return (
        <StyledEngineProvider injectFirst>
            <TextField
                variant='standard'
                required
                id='password'
                label='Password'
                name='password'
                fullWidth
                className={props.color === 'light' ? 'light-scheme' : 'dark-scheme'}
                defaultValue={props.value}
                helperText={props.helperText}
                error={props.error}
                onCopy={preventEvent}
                onCut={preventEvent}
                onChange={debouncedHanldeOnChange}
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                tabIndex={-1}
                                aria-label='toggle password visibility'
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </StyledEngineProvider>
    )
}