import React, { useState } from 'react'
import MoreInfo from '../../Dialogs/Events/MoreInfo';

function SmallEventCard({ event, refresh }) {
    const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false)

    return (
        <>
            <div className='small-episode-card-container'
                onClick={() => setOpenMoreInfoDialog(true)}
                style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.landscape_image})` }}
            >
                <div className='small-episode-card-gradient'>
                    <p className='small-episode-card-title'>{event.title}</p>
                </div>
            </div>
            <MoreInfo hideActions={true} data={event} open={openMoreInfoDialog} close={() => setOpenMoreInfoDialog(false)} refresh={refresh} />
        </>
    )
}

export default SmallEventCard