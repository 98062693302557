export const reducer = (state, action) => {
    switch (action.type) {
        case 'setUserState':
            return {
                ...state,
                userState: action.payload
            }
        case 'setEventsAlert':
            return {
                ...state,
                alerts: action.payload
            }
        case 'setEpisodesAlert':
            return {
                ...state,
                alerts: action.payload
            }
        default: return state
    }
}

export const initialState = {
    userState: { },
    alerts: { events: [], episodes: [] }
}