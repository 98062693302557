const defaultSettings = {
    episodesTable: {
        columnVisibility: {
            id: false,
            title: true,
            event: true,
            location: true,
            language: false,
            region: true,
            organization: false,
            responsible: false,
            status: true,
            salesforce_url: false,
            date: true,
            video: false,
            featured: false,
        },
        sorting: [{ id: 'date', desc: true }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'compact',
        expanded: false,
        showColumnFilters: false,
        columnPinning: { left: [], right: [] },
        columnOrder: [
            "mrt-row-expand", "id", "title", "event", "location", "language", "region", "organization", "responsible", "status", "featured", "salesforce_url", "date", "video", "mrt-row-actions"
        ],
    },
    eventsTable: {
        columnVisibility: {
            id: false,
            title: true,
            location: true,
            language: false,
            region: true,
            organization: false,
            responsible: false,
            status: true,
            salesforce_url: false,
            has_episodes: false,
            episodes: true,
            start_date: true,
            end_date: true,
        },
        sorting: [{ id: 'start_date', desc: true }],
        pagination: { pageIndex: 0, pageSize: 10 },
        density: 'compact',
        expanded: false,
        showColumnFilters: false,
        columnPinning: { left: [], right: [] },
        columnOrder: [
            "mrt-row-expand", "id", "title", "location", "language", "region", "organization", "responsible", "status", "has_episodes", "episodes", "salesforce_url", "start_date", "end_date", "mrt-row-actions"
        ],
    },
}

export const getUserSettings = (entity) => {
    if (localStorage.getItem('li_backoffice_settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('li_backoffice_settings'))
        if (localStorageData[entity]) {
            return localStorageData[entity]
        }
    }

    return defaultSettings[entity]

}

export const setUserSettings = (entity, value) => {
    if (localStorage.getItem('li_backoffice_settings')) {
        const localStorageData = JSON.parse(localStorage.getItem('li_backoffice_settings'))
        localStorageData[entity] = value

        localStorage.setItem('li_backoffice_settings', JSON.stringify(localStorageData))

    } else {
        const prefObject = defaultSettings;
        prefObject[entity] = value

        localStorage.setItem('li_backoffice_settings', JSON.stringify(prefObject))
    }
}