import React from 'react'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { RRule } from 'rrule'
import { StyledEngineProvider } from '@mui/material'
import './ViewsChart.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const buildRRule = () => {

    const rule = new RRule({
        dtstart: moment().subtract(1, 'months')._d,
        freq: RRule.DAILY,
        interval: 1,
        count: 32
    }).all();

    for (let i = 0; i < rule.length; i++) {
        rule[i] = moment(rule[i]).format('DD MMM')
    }

    return rule;
}

function MiniChartView(props) {
    let views = [...props.data]
    let counter = 0 //place inside of calculateViewsPerDay for a non evolution chart

    const prepareDataToGraph = () => {
        const data = []
        const numberOfDays = buildRRule('month');

        numberOfDays.forEach((day, index) => {
            data.push({ x: day, y: calculateViewsPerDay(day) })
        })
        return data
    }

    const calculateViewsPerDay = (day) => {
        
        views.forEach((view, index) => {
            if (moment(new Date(view.createdAt)).format('DD MMM') === day) {
                views = views.filter(item => item !== view)
                counter++;
            }
        })

        return counter
    }

    const state = {
        labels: buildRRule('month'),
        datasets: [{ data: prepareDataToGraph() }]
    }

    return (
        <StyledEngineProvider injectFirst>
            <div>
                <Line
                    data={state}
                    width={200}
                    height={80}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        tension: 0.3,
                        cubicInterpolationMode: 'monotone',
                        elements: {
                            line: {
                                backgroundColor: '#ff5c00',
                                borderColor: '#ff5c00'
                            },
                            point: {
                                backgroundColor: '#ff5c00',
                                radius: 0
                            }
                        },
                        scales: {
                            x: {
                                display: false,
                                grid: {
                                    display: false,
                                    type: 'time',
                                },

                            },
                            y: {
                                display: false,
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    stepSize: 1
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: { enabled: false },
                            title: {
                                display: false,
                                position: 'top',
                                text: ['Total Views'],
                                padding: 10
                            },
                        },
                    }}
                />
            </div>
        </StyledEngineProvider>
    )
}

export default MiniChartView