import React from 'react'
import moment from 'moment'
import './ActivityCard.css'

function ActivityCard(props) {
    return (
        <div className='activity-main-container'>
            <div className='activity-card-message'>
                <p><span className='activity-card-user'>{props.data.user}</span>{props.data.activity}</p>
            </div>
            <div className='activity-card-timestamp'>
                <p>{moment(props.data.createdAt).format('YYYY-MM-DD')}</p>
                <p>{moment(props.data.createdAt).format('HH:mm:ss')}</p>
            </div>
        </div>
    )
}

export default ActivityCard