import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Dialog, DialogContent, DialogActions, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ThreeDots } from 'react-loading-icons'
import useGlobalSnackbar from '../Snackbar/useGlobalSnackbar';
import useDialog from '../Dialogs/DialogHooks/useDialog'


import './DeleteDialog.css'

function DeleteDialog({ data, type, nameField = 'title', ...props }) {
    const [submitting, setSubmitting] = useState(false);
    const [entityType, setEntityType] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [showConfirmPass, setShowConfirmPass] = useState(false)
    const { triggerSnackbar } = useGlobalSnackbar();
    const { openInfoDialog, openErrDialog } = useDialog()

    const infoDialogData = { title: 'Are you sure?', message: `Are you sure you want to delete this ${type} permanently?` };

    useEffect(() => {
        switch (type) {
            case 'Serie': setEntityType('series');
                break;
            case 'Event': setEntityType('events');
                break;
            case 'Episode': setEntityType('episodes');
                break;
            case 'Speaker': setEntityType('speakers');
                break;
            case 'About section': setEntityType('aboutus');
                break;
            case 'FAQ': setEntityType('faq');
                break;
            case 'Topic': setEntityType('topics');
                break;
            case 'Industry': setEntityType('industries');
                break;
            case 'User': setEntityType('user');
                break;
            default: setEntityType(null)
        }

    }, [type])

    const handleOpenInfoDialog = () => {
        openInfoDialog(infoDialogData, 'y/n', () => handleSubmit(true))
    }

    const handleSubmit = (permanently) => {
        setSubmitting(true)

        let fetchConfig;

        if (props.hideTrash) {
            fetchConfig = {
                headers: {
                    "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
                    "requesting-user": `bo_${sessionStorage.getItem('id')}`,
                },
                data: { password: confirmPass }
            }
        } else {
            fetchConfig = {
                headers: {
                    "Authorization": JSON.parse(sessionStorage.getItem('token'))?.token,
                    "requesting-user": `bo_${sessionStorage.getItem('id')}`,
                },
                data: { permanently }
            }
        }
        //TODO: ver esta request
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/${entityType}/${data.id}`, fetchConfig)
            .then((res) => {
                setSubmitting(false)
                props.refresh()
                triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
                props.close()
                if (props.closeAll) props.closeAll()
            }, (err) => {
                openErrDialog(err.response ? err.response : err, () => props.close())
                setSubmitting(false)
            });
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={props.open}
                onClose={props.close}
                aria-labelledby="max-width-dialog-title"
                sx={{ '& .MuiPaper-root': { borderRadius: '20px', backgroundColor: 'transparent' } }}
            >
                <DialogContent className='delete-dialog-content'>
                    <p>{`Are you sure you want to remove ${type}: ${data[nameField]}?`}</p>
                </DialogContent>
                <DialogActions className='delete-dialog-action'>
                    <div className='delete-dialog-text-div'>
                        {!props.hideTrash ? (
                            <p>
                                {`You can choose to send ${type} to the trash bin and be able to recover it later, or delete permanently.
                            Deleting permanently will remove all information from the database as well as all images associated with this ${type}, this action cannot be undone.`}
                            </p>
                        ) : (
                            <>
                                <p>
                                    {`Deleting permanently will remove all information from the database as well as all images associated with this ${type}, this action cannot be undone.`}
                                </p>
                                <TextField
                                    required
                                    id='password'
                                    color='secondary'
                                    name='password'
                                    label='Password'
                                    variant='standard'
                                    size='small'
                                    sx={{ width: '60%', margin: '10px 0' }}
                                    type={showConfirmPass ? 'text' : 'password'}
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    error={confirmPass.length < 1}
                                    helperText='Type your current password to confirm your identity'
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton tabIndex={-1} onClick={() => setShowConfirmPass(!showConfirmPass)} >
                                                {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </>
                        )}
                    </div>
                    {submitting ? <ThreeDots fill='var(--orange)' height='1em' /> : (
                        <div className='delete-dialog-buttons-div'>
                            <div>
                                {!props.hideTrash &&
                                    <button
                                        className='delete-dialog-button'
                                        onClick={() => handleSubmit(false)}
                                    >
                                        Send to Trash
                                    </button>
                                }
                                <button
                                    className='delete-dialog-button'
                                    onClick={handleOpenInfoDialog}
                                >
                                    Delete Permanently
                                </button>
                            </div>
                            <div>
                                <button
                                    className='delete-dialog-button'
                                    style={{ backgroundColor: 'var(--pink-soft)' }}
                                    onClick={props.close}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteDialog