import React, { useState, useEffect } from 'react'
import sortArray from 'sort-array'
import AboutTable from '../Tables/AboutTable'
import { openInNewTab } from '../../utils'
import regexifyString from 'regexify-string'

import './About.css'
import { getEntity } from '../../API/requests'

function About(props) {
	const [about, setAbout] = useState([])
	const [previewSection, setPreviewSection] = useState(null)
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {
		
		getEntity('aboutus').then(res => {
			setAbout(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
			props.setAmount('about', res.data.length)
		}, err => {
			console.log(err)
		})

		setPreviewSection(null)
	}, [refresh, props.parentRefresh])

	const getSection = (id) => {
		return about.filter(section => section.id === id)[0];
	}

	return (
		<div className='about-main-div'>
			<div className='about-table-div'>
				<AboutTable data={about} refresh={() => setRefresh(!refresh)} setPreview={(id) => setPreviewSection(getSection(id))} />
			</div>
			<div className='about-section-preview'>
				{previewSection ? (
					<div key={previewSection.id} className='section-div' style={getBackgroundStyle(previewSection)} >
						<div className={`section-text${previewSection.section_image ? `-image${previewSection.image_position === 'right' ? '-right' : '-left'}` : ''}`} style={{ color: previewSection.text_color }}>
							<div className='section-text-title'>{previewSection.title}</div>
							<div className='section-text-content'>{regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: previewSection.text })}</div>
						</div>
						{previewSection.section_image &&
							<div className='section-img'>
								<img className='about-image' alt='about-section-1' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/aboutus/${previewSection.section_image}`} />
							</div>
						}
					</div>
				) : (
					<p style={{ fontSize: '20px', userSelect: 'none' }}>Click on any section to preview it </p>
				)}
			</div>
		</div>
	)
}

export default About

const regexifyPaterm = /\[\[(.*?)\]\]/gim;
const regexifyDecorator = (match, index) => {
	const string = match.substring(2, match.length - 2)
	return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>;
}

const getBackgroundStyle = (section) => {

	const style = {};

	if (section.gradient) {
		style.backgroundImage = `linear-gradient(${section.gradient_angle}deg,${section.gradient_color_1}, ${section.gradient_color_2})`
	} else {
		style.backgroundColor = section.background_color
	}

	if (section.section_image) {
		if (section.image_position === 'left') {
			style.flexDirection = 'row-reverse'
		}
	} else {
		style.textAlign = 'center'
	}

	return style
}