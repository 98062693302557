import React, { useState, useEffect } from 'react'
import {TextField, InputAdornment, IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import './Review.css'

function Review(props) {
	const [state, setState] = useState()
	const [showPassword, setShowPassword] = useState(false)
	
	useEffect(() => {
		setState(props.state)
	}, [props.state])

	return (
		<div className='user-review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review information before submitting</p>
			{state &&
				<div className='user-review-scrollable-div'>
					<div className='user-review-label-div'>
						<p className='user-review-label'>Name</p>
						<p className='user-review-text'>{state.name}</p>
					</div>
					<div className='user-review-label-div'>
						<p className='user-review-label'>Email</p>
						<p className='user-review-text'>{state.email}</p>
					</div>
					<div className='user-review-label-div'>
						<p className='user-review-label'>User Type</p>
						<p className='user-review-text'>{state.userType === 0 ? 'User' : 'Admin'}</p>
					</div>
					<TextField
							required
							id='passwordIdentity'
							color='secondary'
							name='passwordIdentity'
							label='Password'
							sx={{ width: '45%' }}
							variant='standard'
							type={showPassword ? 'text' : 'password'}
							helperText='Enter your password to confirm your identity'
							// value={this.state.password}
							onChange={(e) => props.updateStateOnParent(e.target.name, e.target.value)}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton tabIndex={-1} onClick={() => setShowPassword(!showPassword)} >
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>,
							}}
						/>
				</div>
			}
		</div>
	)
}

export default Review