import './RowsComponents.css'
import { Avatar, Chip } from '@mui/material'
import { openInNewTab } from '../../../utils'
import CustomTooltip from '../../Misc/CustomTooltip'
import { Edit } from '@mui/icons-material'
import moment from 'moment'
import { getLanguage } from '../../../Data/LanguageData'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const ResponsibleAvatar = ({ cell }) => {
    const value = cell.getValue()
    if (!value) return ''
    const name = value.split(',')[0]
    const image = value.split(',')[1]

    return (
        <div className='responsible-div'>
            <Avatar alt={name} src={image !== 'null' ? `${SERVER_URL}/resources/images/users/${image}` : null} sx={{ width: '30px', height: '30px' }} />
            <CustomTooltip arrow={true} title={name} enterDelay={500} leaveDelay={200} enterNextDelay={500}>
                <p>{name}</p>
            </CustomTooltip>
        </div>
    )
}

export const BooleanComponent = ({ boolean }) => {
    return (
        <div className='status-component-div'>
            <Chip
                color={boolean ? 'success' : 'default'}
                sx={{ width: '100%' }}
                size="small"
                label={boolean ? 'Yes' : 'No'}
            />
        </div>
    )
}

export const TitleComponent = ({ value }) => {
    return (
        <CustomTooltip arrow={true} title={value} enterDelay={500} leaveDelay={200} enterNextDelay={500}>
            <p className='title-component' >{value}</p>
        </CustomTooltip>
    )
}

export const LinkComponent = ({ name, url }) => {
    return (
        <p className='url-component' onClick={() => openInNewTab(url)}>{name ? name : url}</p>
    )
}

export const LanguageComponent = ({ language }) => {
    if (!language) return ''
    const langInfo = getLanguage(language)
    return (
        <p>{langInfo ? `${langInfo.emoji} ${langInfo.name}` : language}</p>
    )
}

export const DateBodyComponent = ({ cell }) => {
    const value = cell.getValue()
    return (
        <Chip
            color='default'
            sx={{ width: '100%' }}
            size="small"
            label={moment(value).isValid() ? moment(value).format('DD MMM YYYY') : 'N/A'}
        />
    )
}

export const StatusBodyComponent = ({ value }) => {

    const getColor = (status) => {
        switch (status) {
            case 'Delivered': return 'success'
            case 'In Progress': return 'secondary'
            case 'Completed': return 'success'
            case 'Planned': return 'default'
            default: return 'default'
        }
    }

    return (
        <div className='status-component-div'>
            <Chip
                color={getColor(value)}
                sx={{ width: '100%' }}
                size="small"
                label={value}
                deleteIcon={<Edit />}
            />
        </div>
    )
}

export const SimpleComponent = ({ value }) => {
    return (
        <div className='status-component-div'>
            <Chip
                color='default'
                sx={{ width: '100%' }}
                size="small"
                label={value}
            />
        </div>
    )
}
