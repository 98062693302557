import React, { useState, useEffect } from 'react';
import moment from 'moment'
import EpisodesTable from '../components/Tables2/EpisodesTable'
import AddEpisode from '../components/Dialogs/Episodes/Add/AddEpisode';
import useUserInfo from '../customHooks/useUserInfo';
import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import { fetchIndustries } from '../Data/Industries';
import { fetchTopics } from '../Data/Topics';
import { fetchSpeakers } from '../Data/Speakers';

import { getEntity } from '../API/requests';
import './css/EpisodesPage.css'

let staticEpisodes = []


function EpisodesPage2() {
    const [episodes, setEpisodes] = useState([])
    const [openAddEpisode, setOpenAddEpisode] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { userInfo } = useUserInfo()
    const [loading, setLoading] = useState(false)

    const checkIfEpisodeHasStarted = (episode) => {
        return moment(episode.date).isAfter(moment());
    }

    useEffect(() => {
        setLoading(true)

        getEntity('episodes').then(res => {
            staticEpisodes = res.data
            setEpisodes(res.data)
            setLoading(false)
        }, err => {
            console.log(err)
            setLoading(false)
        })
    }, [refresh])

    const updateLists = (lists) => {
        switch (lists) {
            case 'industries': fetchIndustries(true);
                break;
            case 'topics': fetchTopics(true);
                break;
            case 'speakers': fetchSpeakers(true);
                break;
            default: return;
        }
    }

    return (
        <div className='episodes-page-main-div'>
            <div className='episodes-page-top-div'>
                <div className='episodes-page-top-add'>
                    {userInfo.user_role.canAddEpisode &&
                        <Fab
                            sx={{ width: '200px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
                            onClick={() => setOpenAddEpisode(true)}
                        >
                            <Add />
                            <p>Add new episode</p>
                        </Fab>
                    }
                </div>
                <div className='episodes-page-top-info-main-div'>
                    <EpisodesTopInfo title='Total Episodes' value={staticEpisodes.length} />
                    <EpisodesTopInfo title='Episodes Online' value={staticEpisodes.filter(episode => episode.online === true).length} />
                    <EpisodesTopInfo title='Future Episodes' value={staticEpisodes.filter(episode => checkIfEpisodeHasStarted(episode)).length} />
                </div>
            </div>
            <div className='episodes-page-bottom-div' style={{ padding:'25px'}}>
                <EpisodesTable episodes={episodes} refresh={() => setRefresh(!refresh)} loading={loading} />
            </div>
            <AddEpisode updateLists={(list) => updateLists(list)} open={openAddEpisode} close={() => setOpenAddEpisode(false)} refresh={() => setRefresh(!refresh)} />
        </div>
    );
}

const EpisodesTopInfo = ({ title, value }) => {
    return (
        <div className='episodes-page-top-info'>
            <p className='episodes-page-top-info-title'>{title}</p>
            <p className='episodes-page-top-info-value'>{value}</p>
        </div>
    )
}


export default EpisodesPage2;
