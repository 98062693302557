import React from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";

import "./DeleteDialog.css";
import "./InfoDialog.css";
import "./ErrorDialog.css"

function ErrorDialog(props) {

	return (
		<div>
			<Dialog
				maxWidth="sm"
				open={props.open}
				onClose={props.close}
				aria-labelledby="max-width-dialog-title"
				sx={{
					"& .MuiPaper-root": {
						borderRadius: "20px",
						backgroundColor: "transparent",
					},
				}}
			>
				<DialogContent className="error-dialog-content" >
					<p>Error</p>
				</DialogContent>
				<DialogActions className="info-dialog-action">
					<div className="info-dialog-text-div">
						<div className="err-dialog-status">
							<p>{props.info.status}</p>
							<p>{props.info.statusText}</p>
						</div>
						<div>
							{props.info.data ?
								<p>{props.info.data}</p> :
								<p>{JSON.stringify(props.info.message)}</p>
							}
						</div>
					</div>
					<div className="info-dialog-buttons-div">
						<button className="delete-dialog-button" onClick={props.ocb}>
							ok
						</button>
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default ErrorDialog;
