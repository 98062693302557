import React, { useState } from 'react'
import './SpeakersCard.css'
import { openInNewTab } from '../../../utils'
import { setEntityOnlineOrOffline } from '../../../API/requests'
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import DeleteDialog from '../../Dialogs/DeleteDialog';
import MoreInfo from '../../Dialogs/Speakers/MoreInfo';
import EditSpeaker from '../../Dialogs/Speakers/Edit/EditSpeaker';
import useUserInfo from '../../../customHooks/useUserInfo';

import { Divider, Tooltip } from '@mui/material'
import Portrait_Placeholder from '../../../assets/portrait_placeholder.png'

import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as ViewMoreIcon,
    PowerSettingsNew as OnlineIcon,
    LinkedIn as LinkedInIcon
} from '@mui/icons-material'

function SpeakersCard(props) {
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openMoreInfo, setOpenMoreInfo] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    const setOnlineDialogInfo = { title: `Set speaker ${props.speaker.online ? 'Offline' : 'Online'}?`, message: `Set speaker ${props.speaker.name} ${props.speaker.online ? 'Offline' : 'Online'}?` }

    const handleSetSpeakerOnline = () => {
        setEntityOnlineOrOffline('speakers', props.speaker.id, !props.speaker.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <div className='speakers-card-main-container'>
            <div className='speaker-card-top-div' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/placeholder.png)` }} />
            <div className='speaker-card-picture-div'>
                {props.speaker.portrait ? (
                    <img style={{ objectFit: 'cover' }} alt='speaker portrait' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/speakers/${props.speaker.portrait}`} width='100%' height='100%' />
                ) : (
                    <img alt='speaker portrait' src={Portrait_Placeholder} width='100%' height='100%' />
                )}
            </div>
            <div className='speaker-card-bottom-div'>
                <p className='speaker-name'>{props.speaker.name}</p>
                <p className='speaker-job'>{props.speaker.job_title}</p>
                {props.speaker.social_links.length > 0 &&
                    <div className='speaker-linkedin'>
                        <LinkedInIcon color='primary' fontSize='medium' onClick={() => openInNewTab(props.speaker.social_links)} />
                    </div>
                }
                <Divider orientation='horizontal' sx={{ width: '80%' }} />
                <div className='speaker-action-area'>
                    {userInfo.user_role.canRemoveSpeaker &&
                        <Tooltip disableInteractive title='Delete Speaker'>
                            <DeleteIcon htmlColor='#333f55' onClick={() => setDeleteDialog(true)} />
                        </Tooltip>
                    }
                    {userInfo.user_role.canEditSpeaker &&
                        <Tooltip disableInteractive title='Edit Speaker'>
                            <EditIcon htmlColor='#333f55' onClick={() => setOpenEditDialog(true)} />
                        </Tooltip>
                    }
                    <Tooltip disableInteractive title='View more info'>
                        <ViewMoreIcon htmlColor='#333f55' onClick={() => setOpenMoreInfo(true)} />
                    </Tooltip>
                    {userInfo.user_role.canEditSpeaker &&
                        <Tooltip disableInteractive title={`Set event ${props.speaker.online ? 'Offline' : 'Online'}`} >
                            <OnlineIcon htmlColor='#333f55' className={`event-card-icon-online-${props.speaker.online ? 'on' : 'off'}`} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetSpeakerOnline())} />
                        </Tooltip>
                    }
                </div>
            </div>
            <DeleteDialog data={props.speaker} type='Speaker' open={deleteDialog} close={() => setDeleteDialog(false)} nameField='name' refresh={props.refresh} />
            <MoreInfo open={openMoreInfo} close={() => setOpenMoreInfo(false)} speaker={props.speaker} refresh={props.refresh} />
            <EditSpeaker open={openEditDialog} close={() => setOpenEditDialog(false)} data={props.speaker} refresh={props.refresh} />
        </div>
    )
}

export default SpeakersCard