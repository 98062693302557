import React, { useState, useEffect } from 'react';
import sortArray from 'sort-array';

import WarningAlert from '../components/Alerts/WarningAlert';
import Search from '../components/Search/Search';
import SpeakersCard from '../components/Cards/Speakers/SpeakersCard';
import SpeakersFilterPanel from '../components/panels/filterPanel/SpeakersFilterPanel'
import SpeakersTable from '../components/Tables/SpeakersTable';
import AddSpeaker from '../components/Dialogs/Speakers/Add/AddSpeaker';
import useUserInfo from '../customHooks/useUserInfo';
import { ThreeDots } from 'react-loading-icons'
import { getUserPrefs, setUserPrefs } from '../components/user/UserPrefs';

import './css/EpisodesPage.css'

import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import { getEntity } from '../API/requests';

let staticSpeakers = []

const speakersSortFields = [
	{ name: 'ID', value: 'id' },
	{ name: 'Name', value: 'name' },
]

function SpeakersPage() {
	const [showAlert, setShowAlert] = useState(false)
	const [view, setView] = useState(getUserPrefs('speakers').view.view)
	const [speakers, setSpeakers] = useState([])
	const [filterData, setFilterData] = useState([])
	const [refreshSearch, setRefreshSearch] = useState(false)
	const [openAddSpeaker, setOpenAddSpeaker] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const { userInfo } = useUserInfo()
	const [loading, setLoading] = useState(false)

	const speakerFilterUpdateData = (data) => {
		setSpeakers(data)
		setFilterData(data)
		setRefreshSearch(!refreshSearch)
	}

	useEffect(() => {
		setLoading(true)

		getEntity('speakers').then(res => {
			staticSpeakers = res.data
			setSpeakers(sortArray([...res.data], { by: 'name', order: 'asc' }))
			setFilterData([...res.data])
			setLoading(false)
		}, err => {
			setLoading(false)
			console.log(err)
		})

	}, [refresh])

	return (
		<div className='episodes-page-main-div'>
			<div className='episodes-page-top-div'>
				<div className='episodes-page-top-add'>
					{userInfo.user_role.canAddSpeaker &&
						<Fab
							sx={{ width: '200px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
							onClick={() => setOpenAddSpeaker(true)}
						>
							<Add />
							<p>Add new Speaker</p>
						</Fab>
					}
				</div>
				<div className='episodes-page-top-info-main-div'>
					<SpeakersTopInfo title='Total Speakers' value={staticSpeakers.length} />
					<SpeakersTopInfo title='Speakers Online' value={staticSpeakers.filter(episode => episode.online === true).length} />
				</div>
			</div>
			<div className='episodes-page-bottom-div'>
				<div className='bottom-div-episodes'>
					<div className='bottom-div-episodes-wrapper'>
						<WarningAlert show={showAlert} viewAction={() => console.log('view')} dissmissAction={() => setShowAlert(false)}>
							There are currently 2 speakers without portrait
						</WarningAlert>
						{view === 'cards' && <Search entity='speakers' refresh={refreshSearch} data={filterData} orderFields={speakersSortFields} entitySearchProperty='name' order={getUserPrefs('speakers').order.order} defaultOrderDirection='asc' setParentData={(data) => setSpeakers(data)} />}
						{loading ? <ThreeDots fill='orange' style={{ marginTop: '100px' }} /> :
							<div className={`bottom-div-episodes-cards ${view === 'cards' ? '' : 'hidden'}`} >
								{speakers.map(speaker => {
									return (
										<SpeakersCard key={speaker.id} speaker={speaker} refresh={() => setRefresh(!refresh)} />
									)
								})}
								{speakers.length === 0 && <p>There is no speakers that match your filters criteria</p>}
							</div>}
						<div className={`bottom-div-episodes-cards ${view === 'table' ? '' : 'hidden'}`}>
							<SpeakersTable data={speakers} refresh={() => setRefresh(!refresh)} />
						</div>
					</div>
					<SpeakersFilterPanel
						currentView={view}
						setView={(view) => { setView(view); setUserPrefs('speakers', 'view', 'view', view) }}
						data={staticSpeakers}
						maxNumberOfEpisodes={20}
						updateData={(data) => speakerFilterUpdateData(data)}
					/>
				</div>
			</div>
			<AddSpeaker open={openAddSpeaker} close={() => setOpenAddSpeaker(false)} refresh={() => setRefresh(!refresh)} />
		</div>
	);
}

const SpeakersTopInfo = ({ title, value }) => {
	return (
		<div className='episodes-page-top-info'>
			<p className='episodes-page-top-info-title'>{title}</p>
			<p className='episodes-page-top-info-value'>{value}</p>
		</div>
	)
}


export default SpeakersPage;
