import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import './PerEpisode.css'

ChartJS.register(ArcElement, Tooltip, Legend);

const randomRGB = () => {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
}

const getColors = (arr) => {
    const arrayOfColors = ['rgb(255,99,132)', 'rgb(75,192,192)', 'rgb(255,205,86)', 'rgb(255,146,37)', 'rgb(54,162,235)', 'rgb(153,102,255)', 'rgb(255,51,51)', 'rgb(46,199,192)', 'rgb(255,215,181)', 'rgb(193,170,160)', 'rgb(227,187,225)', 'rgb(154,255,153)']

    arr.forEach((item, index) => {
        arr[index] = arrayOfColors[index] !== undefined ? arrayOfColors[index] : randomRGB();
    })
    return arr;
}

function PerEpisode(props) {
    const prepareChartData = () => {
        const chartData = []

        props.subData.forEach((data, index) => {
            let counter = 0;
            props.episodes.forEach(episode => {
                if(episode[props.subDataProperty].some(el => el.id.toString() === data.id.toString())) {
                    counter ++;
                }
                chartData[index] = counter
            })
        })
        return chartData
    }

    const state = {
        labels: [...props.subData].map(data => data.name),
        datasets: [
            {
                backgroundColor: getColors([...props.subData]),
                data: prepareChartData(),
            }
        ],
    }

    return (
        <div>
            <Doughnut
                data={state}
                width={420}
                height={250}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right',
                        },
                        title: {
                            display: true,
                            position: 'top',
                            text: [`Episodes per ${props.subDataTitle}`, ' '],
                            padding: 0
                        }
                    }
                }}
            />
        </div>
    )
}

export default PerEpisode