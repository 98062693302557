import React, { useState, useEffect } from 'react';

import Search from '../components/Search/Search';
import sortArray from 'sort-array';
import UserCards from '../components/Cards/Users/UserCards';
import AddUser from '../components/Dialogs/Users/Add/AddUser'
import useUserInfo from '../customHooks/useUserInfo';
import { getUserPrefs } from '../components/user/UserPrefs';

import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'

import './css/UserPage.css'
import { getEntity } from '../API/requests';

const usersSortField = [
    { name: 'ID', value: 'id' },
    { name: 'Name', value: 'name' },
]

let staticUsers = []

function BackofficeUsersPage() {
    const [openAddUser, setOpenAddUser] = useState(false);
    const [users, setUsers] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { userInfo } = useUserInfo()

    useEffect(() => {
        getEntity('backoffice_users').then(res => {
            staticUsers = res.data
            setUsers(sortArray([...res.data], { by: 'name', order: 'asc' }))
        }, err => {
            console.log(err)
        })
    }, [refresh])

    return (
        <div className='users-page-main-div'>
            <div className='users-page-top-div'>
                <div className='users-page-top-add'>
                    {userInfo.user_role.canAddBackofficeUser &&
                        <Fab
                            sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
                            onClick={() => setOpenAddUser(true)}
                        >
                            <Add />
                            <p>Add new backoffice user</p>
                        </Fab>
                    }
                </div>
                <div className='users-page-top-info-main-div'>
                    <EventsTopInfo title='Total Backoffice Users' value={staticUsers.length} />
                </div>
            </div>
            <div className='users-page-bottom-div'>
                <div className='bottom-div-users'>
                    <div className='bottom-div-users-wrapper'>
                        <Search entity='users' refresh={refresh} data={staticUsers} orderFields={usersSortField} order={getUserPrefs('users').order.order} defaultOrderDirection='asc' entitySearchProperty='name' setParentData={(data) => setUsers(data)} />
                        <div className='bottom-div-users-cards'>
                            {users.map(user => {
                                return (
                                    <UserCards key={user.id} user={user} refresh={() => setRefresh(!refresh)} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <AddUser open={openAddUser} close={() => setOpenAddUser(false)} refresh={() => setRefresh(!refresh)} />
        </div>
    );
}

const EventsTopInfo = ({ title, value }) => {
    return (
        <div className='events-page-top-info'>
            <p className='events-page-top-info-title'>{title}</p>
            <p className='events-page-top-info-value'>{value}</p>
        </div>
    )
}

export default BackofficeUsersPage
