import React, { useState } from 'react'
import { Avatar } from '@mui/material'
import MoreInfo from '../../Dialogs/Speakers/MoreInfo'

import './SmallSpeakersCard.css'

function SmallSpeakersCard(props) {
    const [openMoreInfo, setOpenMoreInfo] = useState(false)

    return (
        <>
            <div className='speaker-card-main-container' onClick={() => setOpenMoreInfo(true)}>
                <Avatar sx={{ filter: 'drop-shadow(1px 1px 3px black)', marginLeft: '-15px', width: '60px', height: '60px' }} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/speakers/${props.data.portrait}`} />
                <div className='speaker-card-text-div'>
                    <p>{props.data.name}</p>
                </div>
            </div>
            <MoreInfo hideActions={true} open={openMoreInfo} close={() => setOpenMoreInfo(false)} speaker={props.data} refresh={props.refresh} />
        </>
    )
}

export default SmallSpeakersCard