import React from "react";
import { Dialog, DialogContent, DialogActions } from "@mui/material";

import "./DeleteDialog.css";
import "./InfoDialog.css";

function InfoDialog({ info, ...props }) {
	return (
		<div>
			<Dialog
				maxWidth="sm"
				open={props.open}
				onClose={props.close}
				aria-labelledby="max-width-dialog-title"
				sx={{
					"& .MuiPaper-root": {
						borderRadius: "20px",
						backgroundColor: "transparent",
					},
				}}
			>
				<DialogContent className="delete-dialog-content">
					<p>{info.title}</p>
				</DialogContent>
				<DialogActions className="info-dialog-action">
					<div className="info-dialog-text-div">
						<p>{info.message}</p>
					</div>
					<div className="info-dialog-buttons-div">
						{props.type === "y/n" ? (
							<>
								<button className="delete-dialog-button" onClick={props.ycb}>
									Yes
								</button>
								<button className="delete-dialog-button" onClick={props.ncb}>
									No
								</button>
							</>
						) : (
							<button className="delete-dialog-button" onClick={props.ocb}>
								ok
							</button>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default InfoDialog;
