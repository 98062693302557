import '../Add/AddFormats.css'
import React from 'react'
import { Dialog, DialogContent, Stepper, Step, StepLabel } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { RateReview } from '@mui/icons-material';
import { styled } from '@mui/material/styles'
import ErrorDialog from '../../ErrorDialog';
import InfoDialog from '../../InfoDialog';
import { ThreeDots } from 'react-loading-icons'
import Info from './Info'
import { editEntity } from '../../../../API/requests';

const steps = ['Edit Information'];

const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorLibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <RateReview />,
    };

    return (
        <StyledStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </StyledStepIconRoot>
    );
}

class EditFormats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            name: props.data.name,
            online: props.data.online,
            infoComplete: true,
            submitting: false,
            openInfoDialog: false,
            openErrDialog: false,
            errDialogInfo: '',
            infoDialogData: ''
        }
        this.changedState = { string: {}, boolean: {} }
    }

    resetState = () => {
        this.setState({ ...this.state, activeStep: 0, openInfoDialog: false })
        this.changedState = { string: {}, boolean: {} }
    }

    checkSubmitBtn = () => {

        if (Object.keys(this.changedState.string).length > 0 || Object.keys(this.changedState.boolean).length > 0) {
            if (this.state.infoComplete) {
                return true
            }
        }
        return false
    }

    addToChangedState = (property, value) => {
        if (property === 'infoComplete') return;

        if (value !== this.props.data[property]) {
            this.changedState[typeof value][property] = value;
        } else {
            delete this.changedState[typeof value][property]
        }
    }

    updateState = (property, value) => {
        this.setState({ [property]: value }, () => {
            this.addToChangedState(property, value)
        })
    }

    getStepContent = () => {
        switch (this.state.activeStep) {
            case 0: return <Info state={this.state} updateStateOnParent={this.updateState} />
            default: return ''
        }
    }

    submitEventToServer = () => {
        this.setState({ submitting: true })

        let data = {}

        Object.entries(this.changedState.string).forEach(([key, value]) => {
            data[key] = value;
        })

        Object.entries(this.changedState.boolean).forEach(([key, value]) => {
            data[key] = value;
        })

        editEntity('formats', this.props.data.id, data).then(res => {
            this.setState({ infoDialogData: { title: 'Success', message: res.data.message } }, () => {
                this.setState({ openInfoDialog: true, submitting: false })
            })
        }, err => {
            console.log(err)
            this.setState({ errDialogInfo: err.response ? err.response : err }, () => {
                this.setState({ openErrDialog: true, submitting: false })
            })
        })
    }

    handleInfoDialogClose = () => {
        this.props.refresh();
        this.props.close();
        this.resetState()
    }

    render() {
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={this.props.open}
                onClose={this.props.close}
                aria-labelledby="max-width-dialog-title"
                className='add-industry-mui-dialog-container'
            >
                <DialogContent className='add-industry-dialog-content'>
                    <div className='add-industry-header'>
                        <p>Edit Format</p>
                    </div>
                    <div className='add-industry-stepper'>
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<StyledStepConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={ColorLibStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div className='add-industry-inputs'>
                        {this.getStepContent()}
                    </div>
                    <div className='add-industry-action'>
                        {this.state.submitting ? (
                            <ThreeDots fill='var(--orange)' height='1em' />
                        ) : (
                            <button className='stepper-buttons' disabled={!this.checkSubmitBtn()} onClick={() => this.submitEventToServer()} >Submit</button>
                        )}
                    </div>
                </DialogContent>
                <ErrorDialog
                    open={this.state.openErrDialog}
                    close={() => this.setState({ openErrDialog: false })}
                    ocb={() => this.setState({ openErrDialog: false })}
                    info={this.state.errDialogInfo}
                />
                <InfoDialog
                    open={this.state.openInfoDialog}
                    close={() => this.handleInfoDialogClose()}
                    ocb={() => this.handleInfoDialogClose()}
                    info={this.state.infoDialogData}
                />
            </Dialog>
        )
    }
}

export default EditFormats