import React from 'react'
import { TextField, Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material'
import { TooltipHintsEvents } from "../../../../Data/TooltipsHints"
import { debounce } from '../../../../utils'

import '../Add/Info.css'


class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			nameIsValid: false,
			formIsValid: false,
			formErrors: { name: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		if (this.state.infoComplete) {
			this.setState({
				nameIsValid: true,
				formErrors: { name: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let nameIsValid = this.state.nameIsValid;

		switch (name) {
			case 'name':
				nameIsValid = value.length > 1;
				fieldValidationErrors.name = nameIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid name`}`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			nameIsValid,
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.nameIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {
		
		return (
			<div className='topic-info-main-container' >
				<div className='topic-info-left-container'>
					<div className='topic-info-input-container'>
						<TextField
							required
							autoFocus
							id='name'
							name='name'
							label='Name'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							autoComplete='name'
							variant='standard'
							value={this.state.name}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.nameIsValid}
							helperText={this.state.formErrors.name}
						/>
					</div>
					<FormGroup>
						<Tooltip title={TooltipHintsEvents.online} disableInteractive>
							<FormControlLabel control={<Checkbox
								name='online'
								color='secondary'
								value={this.state.online}
								checked={this.state.online}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Online' />
						</Tooltip>
					</FormGroup>
				</div>
			</div>
		)
	}
}

export default Info