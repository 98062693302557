import React from 'react'
import { TextField, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Help } from '@mui/icons-material'
import { debounce } from '../../../../utils'
import { TooltipHintsEvents } from '../../../../Data/TooltipsHints';
import { styled } from '@mui/material/styles'

import './Info.css'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: 'none',
        fontSize: '12px',
		maxWidth: 'none',
		whiteSpaces: 'pre-wrap'
    },
}));

const TooltipContent = () => {
	return(
		<div>
			<p>You can add links or clickable email by adding this format to the text:</p>
			<p>[[name here|link or email here]]</p>
			<p>Example: [[Learn more about Teleperformance|https://teleperformance.com]] or [[My Email|myemail@mail.com]]</p>
		</div>
	)
}

class Info extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...props.state,
			questionIsValid: false,
			answerIsValid: false,
			formIsValid: false,
			formErrors: { question: '', answer: '' }
		}
	}

	debounceInput = debounce((name, value) => {
		this.validateFields(name, value)
	}, 300)

	componentDidMount() {
		if (this.state.infoComplete) {
			this.setState({
				questionIsValid: false,
				answerIsValid: false,
				formErrors: { question: '', answer: '' }
			})
		}
	}

	handelInput = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.debounceInput(name, value));
	}

	handleCheckboxInput = event => {
		const name = event.target.name;
		const value = event.target.checked;
		this.props.updateStateOnParent(name, value);
		this.setState({ [name]: value }, () => this.validateForm())
	}

	validateFields(name, value) {
		let fieldValidationErrors = this.state.formErrors;
		let questionIsValid = this.state.questionIsValid;
		let answerIsValid = this.state.answerIsValid;

		switch (name) {
			case 'question':
				questionIsValid = value.length > 4;
				fieldValidationErrors.question = questionIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid question`}`
				break;
			case 'answer':
				answerIsValid = value.length > 4;
				fieldValidationErrors.answer = answerIsValid ? '' : `${value.length === 0 ? `This field is required` : `${value} is not a valid answer`}`
				break;
			default: return;
		}

		this.setState({
			formErrors: fieldValidationErrors,
			questionIsValid: questionIsValid,
			answerIsValid: answerIsValid
		}, () => this.validateForm())
	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.questionIsValid &&
				this.state.answerIsValid
		}, () => this.props.updateStateOnParent('infoComplete', this.state.formIsValid))
	}

	render() {

		return (
			<div className='faq-info-main-container' >
				<div className='faq-info-left-container'>
					<StyledTooltip title={<TooltipContent />}><Help /></StyledTooltip>
					<div className='faq-info-input-container'>
						<TextField
							required
							autoFocus
							multiline
							rows={4}
							id='question'
							name='question'
							label='Question'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.question}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.questionIsValid}
							helperText={this.state.formErrors.question}
						/>
						<TextField
							required
							multiline
							rows={4}
							id='answer'
							name='answer'
							label='Answer'
							color='secondary'
							fullWidth
							size='small'
							type='text'
							variant='standard'
							value={this.state.answer}
							onChange={(e) => this.handelInput(e)}
							error={!this.state.answerIsValid}
							helperText={this.state.formErrors.answer}
						/>
					</div>
					<FormGroup>
						<Tooltip title={TooltipHintsEvents.online} disableInteractive>
							<FormControlLabel control={<Checkbox
								name='online'
								color='secondary'
								value={this.state.online}
								checked={this.state.online}
								onChange={(e) => this.handleCheckboxInput(e)}
							/>} label='Online' />
						</Tooltip>
					</FormGroup>
				</div>
			</div>
		)
	}
}

export default Info