import React, { Component } from 'react'
import { IconButton, TextField, InputAdornment, Button } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { debounce } from '../../../utils'
import ErrorDialog from '../ErrorDialog';
import InfoDialog from '../InfoDialog';
import { changePassword } from '../../../API/requests';

export default class UserProfileChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: '',
            currentPassword: '',
            newPasswordIsValid: false,
            confirmPasswordIsValid: false,
            currentPasswordIsValid: false,
            formIsValid: false,
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
            openInfoDialog: false,
            openErrDialog: false,
            infoDialogData: '',
            errDialogInfo: '',
            formErrors: { newPassword: '', confirmPassword: '', currentPassword: '' }
        }
    }

    handelInput = (event) => {
        const name = event.target.name;

        if (name === 'newPassword') {
            this.setState({ confirmPassword: '', confirmPasswordIsValid: false })
        }

        const value = event.target.value;
        this.setState({ [name]: value }, () => this.debounceInput(name, value));
    }

    debounceInput = debounce((name, value) => {
        this.validateFields(name, value)
    }, 300)

    validateFields(name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let newPasswordIsValid = this.state.newPasswordIsValid;
        let confirmPasswordIsValid = this.state.confirmPasswordIsValid;
        let currentPasswordIsValid = this.state.currentPasswordIsValid;

        switch (name) {
            case 'newPassword':
                newPasswordIsValid = new RegExp(/^(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.newPassword = newPasswordIsValid ? '' : 'Password must have at least 2 Uppercase Letters, 2 numbers and more than 8 characters'
                break;
            case 'confirmPassword':
                confirmPasswordIsValid = this.state.newPassword === this.state.confirmPassword;
                fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : 'Passwords do not match'
                break;
            case 'currentPassword':
                currentPasswordIsValid = value.length > 7
                fieldValidationErrors.password = currentPasswordIsValid ? '' : 'Type your current password to confirm your identity'
                break;
            default: return;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            newPasswordIsValid,
            confirmPasswordIsValid,
            currentPasswordIsValid
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.newPasswordIsValid &&
                this.state.confirmPasswordIsValid &&
                this.state.currentPasswordIsValid
        })
    }

    handleSubmit = () => {

        changePassword(this.props.data.id, { newPassword: this.state.newPassword, oldPassword: this.state.currentPassword }).then(res => {
            this.setState({ infoDialogData: { title: 'Success', message: res.data.message } }, () => {
                this.setState({ openInfoDialog: true, submitting: false, currentPassword: '', currentPasswordIsValid: false, formIsValid: false })
            })
        }, err => {
            console.log(err)
            this.setState({ errDialogInfo: err.response ? err.response : err }, () => {
                this.setState({ openErrDialog: true, submitting: false, currentPassword: '', currentPasswordIsValid: false, formIsValid: false })
            })
        })
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (this.state.formIsValid) {
                this.handleSubmit()
            }
        }
    }

    handleInfoDialogClose = () => {
        this.props.close();
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '15px 0' }}>
                <TextField
                    required
                    id='newPassword'
                    color='secondary'
                    name='newPassword'
                    label='New Password'
                    sx={{ width: '80%', margin: '10px 0' }}
                    variant='standard'
                    type={this.state.showPassword1 ? 'text' : 'password'}
                    value={this.state.newPassword}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.newPasswordIsValid}
                    helperText={this.state.formErrors.newPassword}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton tabIndex={-1} onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })} >
                                {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <TextField
                    required
                    id='confirmPassword'
                    color='secondary'
                    name='confirmPassword'
                    label='Confirm Password'
                    variant='standard'
                    sx={{ width: '80%', margin: '10px 0' }}
                    type={this.state.showPassword2 ? 'text' : 'password'}
                    value={this.state.confirmPassword}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.confirmPasswordIsValid}
                    helperText={this.state.formErrors.confirmPassword}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton tabIndex={-1} onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })} >
                                {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <TextField
                    required
                    id='currentPassword'
                    color='secondary'
                    name='currentPassword'
                    label='Current Password'
                    variant='standard'
                    sx={{ width: '80%', margin: '10px 0' }}
                    type={this.state.showPassword3 ? 'text' : 'password'}
                    value={this.state.currentPassword}
                    onChange={(e) => this.handelInput(e)}
                    error={!this.state.currentPasswordIsValid}
                    onKeyPress={this.handleKeyPress}
                    helperText='Type your current password to confirm your identity'
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton tabIndex={-1} onClick={() => this.setState({ showPassword3: !this.state.showPassword3 })} >
                                {this.state.showPassword3 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />
                <div style={{ margin: '10px 0' }}>
                    <Button disabled={!this.state.formIsValid} variant='standard' onClick={this.handleSubmit}>Submit</Button>
                </div>
                <ErrorDialog
                    open={this.state.openErrDialog}
                    close={() => this.setState({ openErrDialog: false })}
                    ocb={() => this.setState({ openErrDialog: false })}
                    info={this.state.errDialogInfo}
                />
                <InfoDialog
                    open={this.state.openInfoDialog}
                    close={() => this.handleInfoDialogClose()}
                    ocb={() => this.handleInfoDialogClose()}
                    info={this.state.infoDialogData}
                />
            </div>
        )
    }
}
