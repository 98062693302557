import { getEntity } from '../API/requests'

export let events = []

export const getEvent = (id) => {
    return events.filter(event => event.episodes.some(episode => episode.id === parseInt(id)))[0];
}

export const getEventByID = (id) => {
    return events.filter(event => event.id === parseInt(id))[0]
}

export async function fetchEvents(force) {

    if (events.length > 0 && !force) return events;

    getEntity('events').then(res => {
        events = [...res.data]
        return [...res.data] 
    }, err => {
        console.log(err)
    })

}