import React, { useState, useEffect } from 'react';
import useUserInfo from '../customHooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import ViewsReportsPanel from '../components/ReportsPanels/ViewsReportsPanel';
import MiscReportPanel from '../components/ReportsPanels/MiscReportPanel';
import { getEntity } from '../API/requests';

import './css/ReportsPage.css'

function ReportsPage() {
	const navigate = useNavigate()
	const { userInfo } = useUserInfo()
	const [data, setData] = useState({})

	useEffect(() => {
		const fetch = async () => {
			getEntity('reports').then(res => {
				setData(res.data)
			}, err => {
				console.log(err)
			})
		}

		if (!userInfo.user_role.canViewReport) {
			navigate('/app/dashboard', { replace: true })
		} else {
			fetch();
		}

	}, [])

	return (
		<div className='reports-page-main-div'>
			<div className='reports-page-top-div'>
				<div className='reports-page-top-info-main-div'>
					<EventsTopInfo title='Total Events' value={data.counts?.eventsCount} />
					<EventsTopInfo title='Total Episodes' value={data.counts?.episodeCount} />
					<EventsTopInfo title='Total Events Views' value={data.counts?.eventsViews} />
					<EventsTopInfo title='Total Episodes Views' value={data.counts?.episodesViews} />
				</div>
			</div>
			<div className='reports-page-bottom-div'>
				<div className='bottom-div-reports'>
					<div className='reports-views-charts'>
						<div className='views-charts-events'>
							{data.data &&
								<ViewsReportsPanel type='Events' data={data.data.events} views={data.data.eventsViews} />
							}
						</div>
						<div className='views-charts-episodes'>
							{data.data &&
								<ViewsReportsPanel type='Episodes' data={data.data.episodes} views={data.data.episodesViews} />
							}
						</div>
					</div>
					<div className='reports-misc-charts'>
						{data.data &&
							<MiscReportPanel data={data.data} />
						}
					</div>
				</div>
			</div>
		</div>
	);
}

const EventsTopInfo = ({ title, value }) => {
	return (
		<div className='reports-page-top-info'>
			<p className='reports-page-top-info-title'>{title}</p>
			<p className='reports-page-top-info-value'>{value}</p>
		</div>
	)
}

export default ReportsPage;
