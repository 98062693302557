import React, { useState, useEffect } from 'react'
import sortArray from 'sort-array'

import TypesTable from '../Tables/TypesTable'
import FormatsTable from '../Tables/FormatsTable'
import { getEntity } from '../../API/requests'

import './TopicsAndIndustries.css'

function TypesAndFormats(props) {
	const [types, setTypes] = useState([])
	const [formats, setFormats] = useState([])
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {

		const fetch = async (entity) => {
			getEntity(entity).then(res => {
				if (entity === 'types') {
					setTypes(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
					props.setAmount('types', res.data.length)
				} else {
					setFormats(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
					props.setAmount('formats', res.data.length)
				}
			}, err => {
				console.log(err)
			})
		}
		fetch('types');
		fetch('formats');

	}, [refresh, props.parentRefresh])

	return (
		<div className='topicsAndIndustries-main-div'>
			<div className='topics-table-div'>
				<TypesTable data={types} refresh={() => setRefresh(!refresh)} />
			</div>
			<div className='industries-table-div'>
				<FormatsTable data={formats} refresh={() => setRefresh(!refresh)} />
			</div>
		</div>
	)
}

export default TypesAndFormats