import React from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import moment from 'moment'
import { Divider } from '@mui/material'
import './Review.css'

function Review(props) {

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review information before submitting</p>
			{props.state &&
				<div className='review-scrollable-div'>
					<div className='review-label-div'>
						<p className='review-label'>Title</p>
						<p className='review-text'>{props.state.title}</p>
					</div>
					<div className='review-label-div'>
						<p className='review-label'>Description</p>
						<p className='review-text'>{props.state.description}</p>
					</div>
					{props.state.eventId &&
						<div className='review-label-div'>
							<p className='review-label'>Event</p>
							<p className='review-text'>{props.state.eventId?.title}</p>
						</div>
					}
					{props.state.video &&
						<div className='review-label-div'>
							<p className='review-label'>Video</p>
							<p className='review-text'>{props.state.video}</p>
						</div>
					}

					{props.state.topic.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Topics</p>
							<p className='review-text'>{props.state.topic.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{props.state.formats.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Formats</p>
							<p className='review-text'>{props.state.formats.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{props.state.types.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Types</p>
							<p className='review-text'>{props.state.types.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{props.state.industry.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Industries</p>
							<p className='review-text'>{props.state.industry.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					{props.state.speaker.length > 0 &&
						<div className='review-label-div'>
							<p className='review-label'>Speakers</p>
							<p className='review-text'>{props.state.speaker.map((el, index) => index === 0 ? `${el.name}` : `, ${el.name}`)}</p>
						</div>
					}
					<div className='review-label-div'>
						<p className='review-label'>Date</p>
						<p className='review-text'>{`${moment(props.state.date).format('yyyy-MMM-DD')}`}</p>
					</div>
					{props.state.location &&
						<div className='review-label-div'>
							<p className='review-label'>Location</p>
							<p className='review-text'>{props.state.location}</p>
						</div>
					}
					{props.state.language &&
						<div className='review-label-div'>
							<p className='review-label'>Language</p>
							<p className='review-text'>{props.state.language.emoji} {props.state.language.name}</p>
						</div>
					}
					{props.state.responsible &&
						<div className='review-label-div'>
							<p className='review-label'>Responsible</p>
							<p className='review-text'>{props.state.responsible.split(',')[0]}</p>
						</div>
					}
					{props.state.region &&
						<div className='review-label-div'>
							<p className='review-label'>Region</p>
							<p className='review-text'>{props.state.region}</p>
						</div>
					}
					{props.state.organization &&
						<div className='review-label-div'>
							<p className='review-label'>Organization</p>
							<p className='review-text'>{props.state.organization}</p>
						</div>
					}
					{props.state.status &&
						<div className='review-label-div'>
							<p className='review-label'>Status</p>
							<p className='review-text'>{props.state.status}</p>
						</div>
					}
					{props.state.salesforce_url &&
						<div className='review-label-div'>
							<p className='review-label'>Salesforce Link</p>
							<p className='review-text'>{props.state.salesforce_url}</p>
						</div>
					}
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='review-chips-div'>
						<BooleanChip value={props.state.available} label='Available?' tooltip='available' />
						<BooleanChip value={props.state.force} label='Forced?' tooltip='force' />
						<BooleanChip value={props.state.online} label='Online?' tooltip='online' />
						<BooleanChip value={props.state.private} label='Private?' tooltip='private' />
						<BooleanChip value={props.state.featured} label='Featured?' tooltip='featured' />
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='review-images-div'>
						{props.state.image ? (
							<img alt='landscape-preview' src={getImageBlob(props.state.image)} width='auto' height='100%' />
						) : (
							<p>No image provided</p>
						)}
					</div>
				</div>
			}
		</div>
	)
}

export default Review