import React, { useEffect, useState } from 'react'
import '../Add/Images.css'
import { DropzoneDialog } from 'material-ui-dropzone';
import { Divider } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';

const LandscapesUploadDialog = props => {
	return (
		<DropzoneDialog
			acceptedFiles={['image/*']}
			cancelButtonText='Cancel'
			submitButtonText='Submit'
			filesLimit={1}
			maxFileSize={2500000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeProfilePic(file); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Landscape Image'
			maxWidth='xs'
		/>
	)
}
const PortraitUploadDialog = props => {
	return (
		<DropzoneDialog
			acceptedFiles={['image/*']}
			cancelButtonText='Cancel'
			submitButtonText='Submit'
			filesLimit={1}
			maxFileSize={1000000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeProfilePic(file); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Portrait Image'
			maxWidth='xs'
		/>
	)
}

function Images(props) {
	const [openLandscapeDialog, setOpenLandscapeDialog] = useState(false);
	const [openPortraitDialog, setOpenPortraitDialog] = useState(false)
	const [previewLandscape, setPreviewLandscape] = useState()
	const [previewPortrait, setPreviewPortrait] = useState()
	const [landscapeImageInfo, setLandscapeImageInfo] = useState({})
	const [portraitImageInfo, setPortraitImageInfo] = useState({})

	useEffect(() => {
		if (props.state.landscape_image) handleImageUpload(props.state.landscape_image, 'landscape_image', false)
		if (props.state.portrait_image) handleImageUpload(props.state.portrait_image, 'portrait_image', false)
	}, [])

	const handleImageUpload = async (file, type, updateParent) => {
		
		if (file instanceof Object) {
			const picture = URL.createObjectURL(new Blob(file, { type: 'image/png' }))
			// const pictureTest = URL.createObjectURL(file[0])
			const image = new Image()

			image.onload = () => {
				file[0].width = image.width;
				file[0].height = image.height;
				const GCD = findGCD(image.width, image.height);
				file[0].aspectRatio = `${image.width / GCD}:${image.height / GCD}`

				if (type === 'landscape_image') {
					setPreviewLandscape(picture)
					setLandscapeImageInfo(file[0])
				}

				if (type === 'portrait_image') {
					setPreviewPortrait(picture)
					setPortraitImageInfo(file[0])
				}
			}

			image.src = picture;

		} else {
			const image = new Image();
			const imageInfo = { name: file, size: ''}

			//create a blob from the fetched img to show the size of the image
			await fetch(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${file}`).then(r => {
				r.blob().then(blob => imageInfo.size = blob.size)
			})
			
			image.onload = () => {
				imageInfo.width = image.width;
				imageInfo.height = image.height;

				const GCD = findGCD(image.width, image.height);
				imageInfo.aspectRatio = `${image.width / GCD}:${image.height / GCD}`

				if (type === 'landscape_image') {
					setPreviewLandscape(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${file}`)
					setLandscapeImageInfo(imageInfo)
				}

				if (type === 'portrait_image') {
					setPreviewPortrait(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${file}`)
					setPortraitImageInfo(imageInfo)
				}
			}

			image.src = `${process.env.REACT_APP_SERVER_URL}/resources/images/events/${file}`
		}

		if (updateParent) props.updateStateOnParent(type, file)
	}

	const findGCD = (w, h) => {
		if (h === 0) return w
		return findGCD(h, w % h)
	}

	const removeImage = (type) => {
		if (type === 'landscape_image') {
			setPreviewLandscape(null);
			setLandscapeImageInfo({})
		}
		if (type === 'portrait_image') {
			setPreviewPortrait(null)
			setPortraitImageInfo({})
		}

		props.updateStateOnParent(type, null)
	}

	return (
		<div className='images-upload-container'>
			<div className='images-landscape-upload-div'>
				<div style={{ width: '80%' }}>
					<p className='image-div-title'>Landscape Image</p>
					<p className='image-div-subtitle'>Landscape image has a recommended aspect ration of 16:9 and a max size of 2.5Mb</p>
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				<div className='landscape-image-place' onClick={() => setOpenLandscapeDialog(true)}>
					{previewLandscape ?
						(
							<img className='landscape-image-preview' alt='preview landscape' src={previewLandscape} width='100%' height='100%' />
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p style={{ marginBottom: '10px' }}>Click here to upload Landscape Image</p>
								<UploadIcon fontSize='large' />
							</div>
						)
					}
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				{previewLandscape && <button className='remove-image-btn' onClick={() => removeImage('landscape_image')}>Remove image</button>}
				{landscapeImageInfo.name &&
					<div className='image-info-div'>
						<p>{`${landscapeImageInfo.name}`}</p>
						<p>{`${landscapeImageInfo.size} Bytes`}</p>
						<p>{`${landscapeImageInfo.width} X ${landscapeImageInfo.height} | ${landscapeImageInfo.aspectRatio}`}</p>
					</div>
				}
			</div>
			<Divider orientation='vertical' sx={{ height: '80%' }} />
			<div className='images-portrait-upload-div'>
				<div style={{ width: '80%' }}>
					<p className='image-div-title'>Portrait Image</p>
					<p className='image-div-subtitle'>Portrait image has a recommended aspect ration of 9:16 and a max size of 1Mb</p>
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				<div className='landscape-image-place' onClick={() => setOpenPortraitDialog(true)}>
					{previewPortrait ?
						(
							<img className='landscape-image-preview' alt='preview portrait' src={previewPortrait} width='100%' height='100%' />
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p style={{ marginBottom: '10px' }}>Click here to upload Portrait Image</p>
								<UploadIcon fontSize='large' />
							</div>
						)
					}
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				{previewPortrait && <button className='remove-image-btn' onClick={() => removeImage('portrait_image')}>Remove image</button>}
				{portraitImageInfo.name &&
					<div className='image-info-div'>
						<p>{`${portraitImageInfo.name}`}</p>
						<p>{`${portraitImageInfo.size} Bytes`}</p>
						<p>{`${portraitImageInfo.width} X ${portraitImageInfo.height} | ${portraitImageInfo.aspectRatio}`}</p>
					</div>
				}
			</div>
			<LandscapesUploadDialog
				close={() => setOpenLandscapeDialog(false)}
				open={openLandscapeDialog}
				changeProfilePic={(file) => handleImageUpload(file, 'landscape_image', true)}
			/>
			<PortraitUploadDialog
				close={() => setOpenPortraitDialog(false)}
				open={openPortraitDialog}
				changeProfilePic={(file) => handleImageUpload(file, 'portrait_image', true)}
			/>
		</div>
	)
}

export default Images