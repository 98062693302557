import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, DialogActions, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title, Description } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    PowerSettingsNew as OnlineIcon,
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'

import DeleteDialog from '../DeleteDialog';

import { openInNewTab } from '../../../utils';
import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { getEntity, setEntityOnlineOrOffline } from '../../../API/requests';
import EditSerie from './Edit/EditSerie';
import useUserInfo from '../../../customHooks/useUserInfo'
import SmallEventCard from '../../Cards/Events/SmallEventCard';

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

function MoreInfo(props) {
    const [serie, setSerie] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    useEffect(() => {
        if (props.open) {
            getEntity('series', props.data.id).then(res => {
                setSerie(res.data)
            }, err => {
                console.log(err)
            })
        }
    }, [props])

    const setOnlineDialogInfo = { title: `Set event ${props.data.online ? 'Offline' : 'Online'}?`, message: `Set event ${props.data.title} ${props.data.online ? 'Offline' : 'Online'}?` }

    const handleSetEventOnline = () => {
        setEntityOnlineOrOffline('events', props.data.id, !props.data.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='event-mui-dialog-container'
        >
            {serie &&
                <>
                    <DialogContent className='event-more-info-dialog-content'>
                        <div className='event-more-info-dialog-header'>
                            <div className='event-dialog-title'>
                                <p style={{ color: 'white', fontSize: '18px' }}>{serie.title}</p>
                            </div>
                            <div className='event-dialog-actions'>
                                {!props.hideActions &&
                                    <>
                                        {userInfo.user_role.canRemoveEvent &&
                                            <Tooltip title='Delete Serie'>
                                                <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                            </Tooltip>
                                        }
                                        {userInfo.user_role.canEditEvent &&
                                            <Tooltip title='Edit Serie'>
                                                <EditIcon onClick={() => setOpenEditDialog(true)} />
                                            </Tooltip>
                                        }
                                        {userInfo.user_role.canEditEvent &&
                                            <Tooltip title={`Set serie ${serie.online ? 'Offline' : 'Online'}`}>
                                                <OnlineIcon sx={serie.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetEventOnline())} />
                                            </Tooltip>
                                        }
                                    </>}
                            </div>
                        </div>
                        <div className='event-more-info-dialog-info-wrapper'>
                            <div className='event-more-info-dialog-episodes-div'>
                                <p style={{ textAlign: 'center' }}>{`${serie.events.length} Events`}</p>
                                <div className='event-more-info-dialog-episodes-scroll-div'>
                                    {serie.events.map(event => (
                                        <SmallEventCard key={event.id} event={event} refresh={props.refresh} />
                                    )
                                    )}
                                </div>
                            </div>
                            <Divider orientation='vertical' sx={{ height: '90%' }} />
                            <div className='event-more-info-info-container'>
                                <div className='event-more-info-dialog-info-div'>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    <Title />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText primary='Title' secondary={serie.title} />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <StyledAvatar>
                                                    <Description />
                                                </StyledAvatar>
                                            </ListItemAvatar>
                                            <ListItemText primary='Description' secondary={serie.description} />
                                        </ListItem>

                                    </List>
                                    <div className='event-more-info-chips-div'>
                                        <BooleanChip value={serie.online} label='Online?' tooltip='online' />
                                        <BooleanChip value={serie.private} label='Private?' tooltip='private' />
                                    </div>
                                </div>
                                <Divider orientation='horizontal' sx={{ borderColor: 'rgb(216 217 222)', width: '95%' }} />
                                <DialogActions className='event-more-info-dialog-images'>
                                    <div className='event-more-info-other-info'>
                                        <p>{`ID: ${serie.id}`}</p>
                                        <p>{`Image ID: ${serie.image_id}`}</p>
                                        <p>{`Created At: ${moment(serie.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                                        <p>{`Last Update: ${moment(serie.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                                    </div>
                                    <Divider orientation='vertical' sx={{ height: '90%' }} />
                                    <div className='event-more-info-images-container'>
                                        <img
                                            alt='event portrait'
                                            src={`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.portrait_image}`}
                                            width='auto'
                                            height='100%'
                                            onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.portrait_image}`)}
                                        />
                                        <img
                                            alt='event landscape'
                                            src={`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.landscape_image}`}
                                            width='auto'
                                            height='100%'
                                            onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.landscape_image}`)}
                                        />
                                    </div>
                                </DialogActions>
                            </div>
                        </div>
                    </DialogContent>
                    <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} type='Serie' data={serie} refresh={props.refresh} />
                    <EditSerie open={openEditDialog} close={() => setOpenEditDialog(false)} data={serie} refresh={props.refresh} />
                </>
            }
        </Dialog>
    )
}

export default MoreInfo

