import React, { useEffect, useState } from 'react'
import { FormControlLabel, FormGroup, Checkbox, Divider, Button, Tooltip } from '@mui/material'
import { Dialog, DialogContent, DialogActions, TextField } from '@mui/material'
import useDialog from '../DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';

import {
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@mui/icons-material'

import './RolesSettings.css'
import { createEntity, editEntity, removeEntity } from '../../../API/requests'

const checkboxData = [
    {
        category: 'Series', permissions: [
            { label: 'View', property: 'canViewSerie' },
            { label: 'Add', property: 'canAddSerie' },
            { label: 'Edit', property: 'canEditSerie' },
            { label: 'Remove', property: 'canRemoveSerie' },
        ]
    },
    {
        category: 'Events', permissions: [
            { label: 'View', property: 'canViewEvent' },
            { label: 'Add', property: 'canAddEvent' },
            { label: 'Edit', property: 'canEditEvent' },
            { label: 'Remove', property: 'canRemoveEvent' },
        ]
    },
    {
        category: 'Episodes', permissions: [
            { label: 'View', property: 'canViewEpisode' },
            { label: 'Add', property: 'canAddEpisode' },
            { label: 'Edit', property: 'canEditEpisode' },
            { label: 'Remove', property: 'canRemoveEpisode' },
        ]
    },
    {
        category: 'Speakers', permissions: [
            { label: 'View', property: 'canViewSpeaker' },
            { label: 'Add', property: 'canAddSpeaker' },
            { label: 'Edit', property: 'canEditSpeaker' },
            { label: 'Remove', property: 'canRemoveSpeaker' },
        ]
    },
    {
        category: 'Topics', permissions: [
            { label: 'Add', property: 'canAddTopic' },
            { label: 'Edit', property: 'canEditTopic' },
            { label: 'Remove', property: 'canRemoveTopic' },
        ]
    },
    {
        category: 'Industries', permissions: [
            { label: 'Add', property: 'canAddIndustry' },
            { label: 'Edit', property: 'canEditIndustry' },
            { label: 'Remove', property: 'canRemoveIndustry' },
        ]
    },
    {
        category: 'Types', permissions: [
            { label: 'Add', property: 'canAddType' },
            { label: 'Edit', property: 'canEditType' },
            { label: 'Remove', property: 'canRemoveType' },
        ]
    },
    {
        category: 'Formats', permissions: [
            { label: 'Add', property: 'canAddFormat' },
            { label: 'Edit', property: 'canEditFormat' },
            { label: 'Remove', property: 'canRemoveFormat' },
        ]
    },
    {
        category: 'FAQ', permissions: [
            { label: 'Add', property: 'canAddFaq' },
            { label: 'Edit', property: 'canEditFaq' },
            { label: 'Remove', property: 'canRemoveFaq' },
        ]
    },
    {
        category: 'About Sections', permissions: [
            { label: 'Add', property: 'canAddAbout' },
            { label: 'Edit', property: 'canEditAbout' },
            { label: 'Remove', property: 'canRemoveAbout' },
        ]
    },
    // {
    //     category: 'Users', permissions: [
    //         { label: 'View', property: 'canViewUser' },
    //         { label: 'Add', property: 'canAddUser' },
    //         { label: 'Edit', property: 'canEditUser' },
    //         { label: 'Remove', property: 'canRemoveUser' },
    //     ]
    // },
    {
        category: 'Backoffice Users', permissions: [
            { label: 'View', property: 'canViewBackofficeUser' },
            { label: 'Add', property: 'canAddBackofficeUser' },
            { label: 'Edit', property: 'canEditBackofficeUser' },
            { label: 'Remove', property: 'canRemoveBackofficeUser' },
        ]
    },
    {
        category: 'Misc', permissions: [
            { label: 'View Lists', property: 'canViewLists' },
            { label: 'Manage Trash', property: 'canManageTrash' },
            { label: 'Manage Settings', property: 'canManageSettings' },
            { label: 'View Reports', property: 'canViewReport' },
            { label: 'View Activity', property: 'canViewActivity' },
        ]
    },
]

let stateChanges = {}

function RolesSettings(props) {
    const [selectedRole, setSelectedRole] = useState(null)
    const [originalData, setOriginalData] = useState()
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openAddRoleDialog, setOpenAddRoleDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.checked

        setSelectedRole(state => ({
            ...state,
            ...{ [name]: Boolean(value) }
        }))

        if (originalData[name] !== value) {
            stateChanges[name] = Boolean(value)
        } else {
            delete stateChanges[name]
        }
    }

    useEffect(() => {
        if (selectedRole) {
            setOriginalData(props.userRoles.filter(role => role.id === selectedRole.id)[0])
        }
    }, [selectedRole])


    const confirmSwitchRole = (role) => {
        if (Object.keys(stateChanges).length > 0) {
            openInfoDialog(
                {
                    title: 'Unsaved Changes',
                    message: `You made changes to ${selectedRole.name} role but didn't save them. 
                    Discard these changes and switch to ${role.name}? `
                },
                'y/n',
                () => handleChangeSelectedRole(role)
            )
        } else {
            handleChangeSelectedRole(role)
        }
    }

    const handleChangeSelectedRole = (role) => {
        setSelectedRole(role);
        stateChanges = {}
    }

    const handleSubmit = async () => {

        editEntity('roles', selectedRole.id, stateChanges).then(res => {
            stateChanges = {}
            setSelectedRole(null)
            props.refresh()
            triggerSnackbar(res.data, '', 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })

        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err)
        })
    }

    const createNewRole = async (name) => {

        createEntity('roles', {name}).then(res => {
            setSelectedRole(null)
            props.refresh()
            triggerSnackbar(res.data, '', 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err) 
        })
    }

    const removeRole = async () => {
        removeEntity('roles', selectedRole.id).then(res => {
            setSelectedRole(null)
            props.refresh()
            triggerSnackbar(res.data, '', 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })

        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err) 
        })
    }

    const editRole = async (name) => {

        editEntity('roles', selectedRole.id, { name }).then(res => {
            console.log(res)
            setSelectedRole(null)
            props.refresh()
            triggerSnackbar(res.data, '', 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })

        }, err => {
            console.log(err)
            openErrDialog(err.response ? err.response : err) 
        })
    }

    return (
        <div className='roles-settings-container'>
            <p className='roles-settings-title'>User Roles Settings</p>
            <div className='roles-settings-bottom'>
                <div className='roles-selector'>
                    {props.userRoles.map(role => {
                        return (
                            <RolesCard key={role.id} active={role.id === selectedRole?.id ? 'true' : 'false'} name={role.name} onClick={() => confirmSwitchRole(role)} />
                        )
                    })}
                    <Tooltip title='Add new Role'>
                        <div className='role-card' onClick={() => setOpenAddRoleDialog(true)}>
                            +
                        </div>
                    </Tooltip>
                </div>
                <div className='roles-info'>
                    {selectedRole &&
                        <div className='role-permissions-main-div'>
                            <div className='role-permissions-title-div'>
                                <p className='role-permissions-title'>{selectedRole.name}</p>
                                <div className='role-permissions-actions'>
                                    <Tooltip title='Edit Role Name'>
                                        <EditIcon onClick={() => setOpenEditDialog(true)} />
                                    </Tooltip>
                                    {selectedRole.id !== 1 &&
                                        <Tooltip title='Remove Role'>
                                            <DeleteIcon onClick={() => removeRole()} />
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            {checkboxData.map((category, index) => {
                                return (
                                    <div key={index} className='roles-category'>
                                        <p className='role-category-title'>{category.category}</p>
                                        <Divider orientation='horizontal' sx={{ width: '80%' }} />
                                        <div className='role-category-checkbox'>
                                            <FormGroup key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                {category.permissions.map((permissions, index) => {
                                                    return (
                                                        <FormControlLabel key={index} control={<Checkbox
                                                            name={permissions.property}
                                                            color='secondary'
                                                            disabled={selectedRole.id === 1}
                                                            value={selectedRole[permissions.property]}
                                                            checked={selectedRole[permissions.property]}
                                                            onChange={(e) => handleInput(e)}
                                                        />} label={permissions.label} />
                                                    )
                                                })}
                                            </FormGroup>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
            <Button disabled={Object.keys(stateChanges).length < 1} sx={{ margin: '10px' }} color='secondary' onClick={handleSubmit} >Save Changes</Button>
            <EditDialog open={openEditDialog} close={() => setOpenEditDialog(false)} ocb={(name) => editRole(name)} default={selectedRole?.name} />
            <AddDialog open={openAddRoleDialog} close={() => setOpenAddRoleDialog(false)} ocb={(name) => createNewRole(name)} />
        </div>
    )
}

const RolesCard = (props) => {
    return (
        <div className={`role-card ${props.active === 'true' ? 'active' : ''}`} {...props}>
            <p>{props.name}</p>
        </div>
    )
}

const EditDialog = (props) => {
    const [value, setValue] = useState('')

    useEffect(() => {
        if (props.default) {
            setValue(props.default)
        }
    }, [props.default])

    return (
        <Dialog
            maxWidth="sm"
            open={props.open}
            onClose={() => { props.close(); setValue('') }}
            aria-labelledby="max-width-dialog-title"
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                },
            }}
        >
            <DialogContent className="delete-dialog-content">
                <p>Edit Role Name</p>
            </DialogContent>
            <DialogActions className="info-dialog-action">
                <div className="info-dialog-text-div">
                    <p style={{ marginBottom: '10px' }}>Type the new name for this role</p>
                    <TextField
                        required
                        autoFocus
                        fullWidth
                        id='name'
                        name='name'
                        label='Name'
                        color='secondary'
                        size='small'
                        type='text'
                        variant='standard'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={value.length === 0}
                    />
                </div>
                <div className="info-dialog-buttons-div">
                    <button disabled={value.length === 0 || value === props?.default} className="delete-dialog-button" onClick={() => { props.ocb(value); props.close() }}>
                        Submit
                    </button>
                </div>
            </DialogActions>
        </Dialog>
    )
}


const AddDialog = (props) => {
    const [value, setValue] = useState('')


    return (
        <Dialog
            maxWidth="sm"
            open={props.open}
            onClose={() => { props.close(); setValue('') }}
            aria-labelledby="max-width-dialog-title"
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                },
            }}
        >
            <DialogContent className="delete-dialog-content">
                <p>Create New Role</p>
            </DialogContent>
            <DialogActions className="info-dialog-action">
                <div className="info-dialog-text-div">
                    <p style={{ marginBottom: '10px' }}>Type the new name for the new role</p>
                    <TextField
                        required
                        autoFocus
                        fullWidth
                        id='name'
                        name='name'
                        label='Name'
                        color='secondary'
                        size='small'
                        type='text'
                        variant='standard'
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        error={value.length === 0}
                    />
                </div>
                <div className="info-dialog-buttons-div">
                    <button disabled={value.length === 0} className="delete-dialog-button" onClick={() => { props.ocb(value); props.close() }}>
                        Submit
                    </button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default RolesSettings