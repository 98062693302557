import React, { useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { styled } from '@mui/material/styles'
import sortArray from 'sort-array'

import './EventsTable.css'

const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    width: '100%',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        },
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)',
        borderLeft: '5px solid var(--orange-soft)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        cursor: 'pointer',
        borderLeft: '5px solid transparent'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    },
    '& .MuiTableCell-head' : {
        padding: '0 16px'
    },
    '& .MuiTableCell-body' : {
        padding: '0 16px'
    }

})

function ViewsReportsTable(props) {
    
    useEffect(() => {
        sortArray(props.data, { by: 'views', order: 'desc' } )
    }, [props.data])

    const getEntityById = (id) => {
        return props.data.filter(entity => entity.id === id)[0]
    }

    const handleRowClick = (id) => {
        props.setActiveEntity(getEntityById(id))
    }

    const options = {
        filter: false,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10],
        tableBodyMaxHeight: '350px',
        onRowClick: item => handleRowClick(item[0])
    };

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: false, display: false  } },
        { name: 'title', label: 'Title', options: { filter: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ maxHeight:'20px', maxWidth: '350px', overflow: 'auto' }}>{value}</p> } },
        { name: 'views', label: 'Views', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ fontSize: '18px', textAlign: 'center' }}>{value}</p>, } },
        
    ]

    return (
        <div style={{ width: '70%' }}>
            <MuiDataTableStyled
                title={`${props.type} views`}
                data={props.data}
                columns={columns}
                options={options}
                
            />
        </div>
    )
}

export default ViewsReportsTable