import React, { useEffect, useState } from 'react'
import './Images.css'
import { DropzoneDialog } from 'material-ui-dropzone';
import { Divider, Tooltip } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { InfoTooltips } from '../../../../Data/TooltipsHints';
import { Help } from '@mui/icons-material';

const ImageUploadDialog = props => {
	return (
		<DropzoneDialog
			acceptedFiles={['image/*']}
			cancelButtonText='Cancel'
			submitButtonText='Submit'
			filesLimit={1}
			maxFileSize={600000}
			open={props.open}
			onClose={() => props.close()}
			onSave={(file) => { props.changeProfilePic(file); props.close() }}
			showPreviews={false}
			showPreviewsInDropzone={true}
			dialogTitle='Upload Image'
			maxWidth='xs'
		/>
	)
}

function Images(props) {
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [previewImage, setPreviewImage] = useState()
	const [imageInfo, setImageInfo] = useState({})

	useEffect(() => {
		if (props.state.portrait) handleImageUpload(props.state.portrait, 'portrait', false)
	}, [])

	const handleImageUpload = (file, type, updateParent) => {

		const picture = URL.createObjectURL(new Blob(file, { type: 'image/png' }))
		const image = new Image();

		image.onload = () => {
			file[0].width = image.width;
			file[0].height = image.height;
			const GCD = findGCD(image.width, image.height);
			file[0].aspectRatio = `${image.width / GCD}:${image.height / GCD}`

			setPreviewImage(picture)
			setImageInfo(file[0])
		}

		image.src = picture;

		if (updateParent) props.updateStateOnParent(type, file)
	}

	const findGCD = (w, h) => {
		if (h === 0) return w
		return findGCD(h, w % h)
	}

	const removeImage = (type) => {
		setPreviewImage(null);
		setImageInfo({})

		props.updateStateOnParent(type, null)
	}

	return (
		<div className='speakers-images-upload-container'>
			<div className='speakers-images-portrait-upload-div'>
				<div style={{ width: '80%' }}>
					<p className='speakers-image-div-title'>Speaker Image<span style={{ marginLeft: '10px' }}><Tooltip title={InfoTooltips.speakerImage}><Help /></Tooltip></span></p>
					<p className='speakers-image-div-subtitle'>Speaker image has a recommended aspect ration of 1:1 and a max size of 600Kb</p>
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				<div className='speakers-landscape-image-place' onClick={() => setOpenImageDialog(true)}>
					{previewImage ?
						(
							<img className='speakers-landscape-image-preview' alt='preview' src={previewImage} width='100%' height='100%' />
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<p style={{ marginBottom: '10px' }}>Click here to upload Image</p>
								<UploadIcon fontSize='large' />
							</div>
						)
					}
				</div>
				<Divider orientation='horizontal' sx={{ width: '80%' }} />
				{previewImage && <button className='speakers-remove-image-btn' onClick={() => removeImage('portrait')}>Remove image</button>}
				{imageInfo.name &&
					<div className='speakers-image-info-div'>
						<p>{`${imageInfo.name}`}</p>
						<p>{`${imageInfo.size} Bytes`}</p>
						<p>{`${imageInfo.width} X ${imageInfo.height} | ${imageInfo.aspectRatio}`}</p>
					</div>
				}
			</div>
			<ImageUploadDialog
				close={() => setOpenImageDialog(false)}
				open={openImageDialog}
				changeProfilePic={(file) => handleImageUpload(file, 'portrait', true)}
			/>
		</div>
	)
}

export default Images