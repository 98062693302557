import React from 'react';
import { Snackbar, IconButton, Alert, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

function StandardSnackbar({ open, close, autoHide, message, children, type, position, direction }) {

    const actions = (
        <IconButton size='small' aria-label='close' color='inherit' onClick={close}>
            <CloseIcon fontSize='small' />
        </IconButton>
    )

    function SlideTransition(props){
        return <Slide {...props} direction={direction} />
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHide ? 6000 : 0}
            onClose={close}
            message={message}
            action={actions}
            TransitionComponent={SlideTransition}
            anchorOrigin={{vertical: position[0], horizontal: position[1] }}
        >
            <Alert onClose={close} severity={type} sx={{ width: '100%', boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.5)' }}>{children}</Alert>
        </Snackbar>
    )
}

export default StandardSnackbar;
