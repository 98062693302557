import React, { useState, useEffect } from 'react'
import useDialog from '../Dialogs/DialogHooks/useDialog'
import sortArray from 'sort-array'
import { getEntity } from '../../API/requests'

import ActivityCard from '../Cards/ActivityCard'

function Activity() {
    const [data, setData] = useState([])
    const { openErrDialog } = useDialog();

    useEffect(() => {

        getEntity('activitiesFrom', 1).then(res => {
            setData(res.data)
        }, (err) => {
            console.log(err)
            openErrDialog(err.response ? err.response : err) 
        })
    }, [])

    return (
        <div>
            {data.length === 0 && <p style={{ textAlign: 'center', marginTop: '20px' }}>Seems pretty calm around here</p>}
            {sortArray(data, { by: 'createdAt', order: 'desc' }).map(activity => {
                return (
                    <ActivityCard key={activity.id} data={activity} />
                )
            })}
        </div>
    )
}

export default Activity