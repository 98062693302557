import React, { useState, useEffect } from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import '../Add/Review.css'

function Review(props) {
	const [infoText, setInfoText] = useState('No changes Detected')

	useEffect(() => {

		if (Object.keys(props.state.string).length > 0 ||
		Object.keys(props.state.boolean).length > 0 ||
		Object.keys(props.state.object).length > 0) {
            setInfoText('Detected Changes:')
        }

	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='series-review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review changed information before submitting</p>
			<p style={{ textAlign: 'center', fontSize: '16px' }}>{infoText}</p>
			<div className='series-review-scrollable-div'>
				{Object.entries(props.state.string).map(([key, value]) => {
					return (
						<div key={key} className='series-review-label-div'>
							<p className='series-review-label'>{key}</p>
							<p className='series-review-text'>{value}</p>
						</div>
					)
				})}
				<div className='series-review-chips-div'>
					{Object.entries(props.state.boolean).map(([key, value]) => {
						return (
							<BooleanChip key={key} value={value} label={key} tooltip={key} />
						)
					})}
				</div>
				<div className='series-review-images-div'>
					{Object.entries(props.state.object).map(([key, value]) => {
						return (
							<img key={key} alt={`${key}-preview`} src={getImageBlob(value)} width='auto' height='100%' />
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Review