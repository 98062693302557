import React, { useState, useEffect } from 'react'
import { BooleanChip } from '../../../Chip/CustomChip'
import { Divider } from '@mui/material'

function Review(props) {
	const [state, setState] = useState()

	useEffect(() => {
		setState(props.state)
	}, [props.state])

	const getImageBlob = (file) => {
		return URL.createObjectURL(new Blob(file, { type: 'image/png' }))
	}

	return (
		<div className='series-review-main-container'>
			<p style={{ textAlign: 'center', fontSize: '20px' }}>Review information before submitting</p>
			{state &&
				<div className='series-review-scrollable-div'>
					<div className='series-review-label-div'>
						<p className='series-review-label'>Title</p>
						<p className='series-review-text'>{state.title}</p>
					</div>
					<div className='series-review-label-div'>
						<p className='series-review-label'>Description</p>
						<p className='series-review-text'>{state.description}</p>
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='series-review-chips-div'>
						<BooleanChip value={state.online} label='Online?' tooltip='online' />
						<BooleanChip value={state.private} label='Private?' tooltip='private' />
					</div>
					<Divider orientation='horizontal' sx={{ width: '80%', margin: '10px 0' }} />
					<div className='series-review-images-div'>
						<img alt='landscape-preview' src={getImageBlob(state.landscape_image)} width='auto' height='100%' />
						<img alt='portrait-preview' src={getImageBlob(state.portrait_image)} width='auto' height='100%' />
					</div>
				</div>
			}
		</div>
	)
}

export default Review