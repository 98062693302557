import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
    Visibility as ViewMoreIcon,
} from '@mui/icons-material'

import MoreInfo from '../Dialogs/About/MoreInfo'
import { TooltipHintsEvents } from '../../Data/TooltipsHints'

import './EventsTable.css'

const CustomTableBody = (props) => {
    return (
        <div className='custom-table-body' style={props.value ? { backgroundColor: 'var(--light-green)' } : { backgroundColor: 'var(--light-red)' }}>{props.value.toString()}</div>
    )
}

const MuiDataTableStyled = styled(MUIDataTable)({
    borderRadius: '20px',
    width: '100%',
    '& > .MuiToolbar-root': {
        backgroundImage: 'linear-gradient(to bottom, var(--dark-blue), var(--light-blue))',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        color: 'white',
        '& .MuiIconButton-root': {
            color: 'white',
            '&:hover': {
                color: 'var(--orange-soft)'
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root > input': {
            color: 'white'
        },
        '& .MuiFormControl-root > .MuiInput-root:after': {
            borderColor: 'var(--orange-soft)'
        },
        '& .MuiFormControl-root > .MuiInput-root:before': {
            borderColor: 'white'
        },
    },
    '& .MuiTable-root .MuiButton-root': {
        color: 'var(--light-blue)',
        fontSize: '16px',
    },
    '& .MuiTable-root .MuiSvgIcon-root ': {
        color: 'var(--light-blue)',
    },
    '& .MuiTable-root .MuiTableRow-hover:hover': {
        backgroundColor: 'var(--light-blue)',
        borderLeft: '5px solid var(--orange-soft)'
    },
    '& .MuiTable-root .MuiTableRow-hover': {
        borderLeft: '5px solid transparent',
        cursor: 'pointer'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover .MuiSvgIcon-root': {
        color: 'var(--background)'
    },
    '& .MuiTable-root .MuiTableRow-hover:hover > .MuiTableCell-root': {
        color: 'white'
    }

})

function AboutTable(props) {
    const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false)
    const [selectedSection, setSelectedSection] = useState(null)

    const CustomActionTableBody = (props) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '50px' }}>
                <Tooltip title='View More Info'>
                    <ViewMoreIcon className='action-icon' onClick={() => handleRowClick(props.meta.rowData[0])} />
                </Tooltip>
            </div>
        )
    }

    const getFaqID = (id) => {
        return props.data.filter(section => section.id === id)[0]
    }

    const handleRowClick = (id) => {
        setSelectedSection(getFaqID(id))
        setOpenMoreInfoDialog(true)
    }

    const options = {
        filter: true,
        viewColumns: false,
        responsive: 'standard',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: item => props.setPreview(item[0])
    };

    const columns = [
        { name: 'id', label: 'ID', options: { filter: false, searchable: false } },
        { name: 'title', label: 'Title', options: { filter: false, customBodyRender: (value, tableMeta, updateValue) => <p style={{ maxWidth: '300px' }}>{value}</p> } },
        {
            name: 'online', label: 'Online', options: {
                hint: TooltipHintsEvents.online,
                filter: true,
                searchable: false,
                customBodyRender: (value, tableMeta, updateValue) => <CustomTableBody value={value} meta={tableMeta} update={updateValue} />,
                customFilterListOptions: {
                    render: v => {
                        return `Online? ${v.toString()}`
                    },
                },
            }
        },
        { name: 'online', label: 'Actions', options: { filter: false, searchable: false, customBodyRender: (value, tableMeta, updateValue) => <CustomActionTableBody value={value} meta={tableMeta} update={updateValue} /> } },
    ]

    return (
        <div style={{ marginTop: '20px', width: '100%' }}>
            <MuiDataTableStyled
                title={"About Sections"}
                data={props.data}
                columns={columns}
                options={options}
            />
            {selectedSection && <MoreInfo open={openMoreInfoDialog} close={() => setOpenMoreInfoDialog(false)} about={selectedSection} refresh={props.refresh} />}
        </div>
    )
}

export default AboutTable