import React, { useState, useEffect } from 'react';
import moment from 'moment'
import EventsTable from '../components/Tables2/EventsTable';
import AddEvent from '../components/Dialogs/Events/Add/AddEvent';

import { Fab } from '@mui/material'
import { Add } from '@mui/icons-material'

import useUserInfo from '../customHooks/useUserInfo';
import { getEntity } from '../API/requests';

import './css/EventsPage.css'

let staticEvents = []

function EventsPage() {
	const [events, setEvents] = useState([])
	const [openAddEvent, setOpenAddEvent] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const { userInfo } = useUserInfo()
	const [loading, setLoading] = useState(false)

	const checkIfEventHasStarted = (event) => {
		return moment(event.start_date).isAfter(moment());
	}

	useEffect(() => {
		setLoading(true)

		getEntity('events').then(res => {
			staticEvents = res.data
			setEvents(res.data)
			setLoading(false)
		}, err => {
			console.log(err)
			setLoading(false)
		})

	}, [refresh])

	return (
		<div className='events-page-main-div'>
			<div className='events-page-top-div'>
				<div className='events-page-top-add'>
					{userInfo.user_role.canAddEvent &&
						<Fab
							sx={{ width: '180px', borderRadius: '2rem', justifyContent: 'space-evenly', lineHeight: '1', paddingRight: '6px' }}
							onClick={() => setOpenAddEvent(true)}
						>
							<Add />
							<p>Add new event</p>
						</Fab>
					}
				</div>
				<div className='events-page-top-info-main-div'>
					<EventsTopInfo title='Total Events' value={staticEvents.length} />
					<EventsTopInfo title='Events Online' value={staticEvents.filter(event => event.online === true).length} />
					<EventsTopInfo title='Future Events' value={staticEvents.filter(event => checkIfEventHasStarted(event)).length} />
				</div>
			</div>
			<div className='events-page-bottom-div' style={{ padding: '25px' }}>
				<EventsTable events={events} refresh={() => setRefresh(!refresh)} loading={loading} />
			</div>
			<AddEvent open={openAddEvent} close={() => setOpenAddEvent(false)} refresh={() => setRefresh(!refresh)} />
		</div >
	);
}

const EventsTopInfo = ({ title, value }) => {
	return (
		<div className='events-page-top-info'>
			<p className='events-page-top-info-title'>{title}</p>
			<p className='events-page-top-info-value'>{value}</p>
		</div>
	)
}

export default EventsPage;
