import React, { useState, useEffect } from 'react'
import sortArray from 'sort-array'

import TopicsTable from '../Tables/TopicsTable'
import IndustriesTable from '../Tables/IndustriesTable'

import './TopicsAndIndustries.css'
import { getEntity } from '../../API/requests'

function TopicsAndIndustries(props) {
	const [topics, setTopics] = useState([])
	const [industries, setIndustries] = useState([])
	const [refresh, setRefresh] = useState(false)

	useEffect(() => {

		const fetch = async (entity) => {
			getEntity(entity).then(res => {
				if (entity === 'topics') {
					setTopics(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
					props.setAmount('topics', res.data.length)
				} else {
					setIndustries(sortArray([...res.data], { by: 'createdAt', order: 'asc' }))
					props.setAmount('industries', res.data.length)
				}
			}, err => {
				console.log(err)
			})
		}
		fetch('topics');
		fetch('industries');

	}, [refresh, props.parentRefresh])

	return (
		<div className='topicsAndIndustries-main-div'>
			<div className='topics-table-div'>
				<TopicsTable data={topics} refresh={() => setRefresh(!refresh)} />
			</div>
			<div className='industries-table-div'>
				<IndustriesTable data={industries} refresh={() => setRefresh(!refresh)} />
			</div>
		</div>
	)
}

export default TopicsAndIndustries