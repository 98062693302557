import React, { useState, useEffect } from 'react';
import moment from 'moment'
import useUserInfo from '../customHooks/useUserInfo';
import { useNavigate } from 'react-router-dom';
import ActivityTable from '../components/Tables/ActivityTable';
import { getEntity } from '../API/requests';

import './css/Activity.css'

let staticActivity = []

function Activity() {
	const [activities, setActivities] = useState([])
	const navigate = useNavigate()
	const { userInfo } = useUserInfo()

	useEffect(() => {

		const fetch = async () => {
			await getEntity('activities').then(res => {
				staticActivity = res.data
				setActivities([...res.data])
				console.log(res.data)
			}, err => {
				console.log(err)
			})
		}

		if (!userInfo.user_role.canViewActivity) {
			navigate('/app/dashboard', { replace: true })
		} else {
			fetch();
		}

	}, [])

	return (
		<div className='activity-page-main-div'>
			<div className='activity-page-top-div'>
				<div className='activity-page-top-info-main-div'>
					<EventsTopInfo title='Total Activity' value={staticActivity.length} />
					<EventsTopInfo title='Activity today' value={staticActivity.filter(activity => moment(activity.createdAt).isSame(moment(), 'day')).length} />
				</div>
			</div>
			<div className='activity-page-bottom-div'>
				<div className='bottom-div-activity'>
					<div className='bottom-div-activity-wrapper'>
						<div className='bottom-div-activity-table'>
							<ActivityTable data={activities} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const EventsTopInfo = ({ title, value }) => {
	return (
		<div className='activity-page-top-info'>
			<p className='activity-page-top-info-title'>{title}</p>
			<p className='activity-page-top-info-value'>{value}</p>
		</div>
	)
}

export default Activity;
