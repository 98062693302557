import React, { useState } from 'react'
import moment from 'moment';
import { Dialog, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar, Tooltip } from '@mui/material'
import { Title } from '@mui/icons-material';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    PowerSettingsNew as OnlineIcon
} from '@mui/icons-material'

import { styled } from '@mui/material/styles'
import { openInNewTab } from '../../../utils'

import DeleteDialog from '../DeleteDialog';
import EditAbout from './Edit/EditAbout';

import { BooleanChip } from '../../Chip/CustomChip';
import useDialog from '../../Dialogs/DialogHooks/useDialog'
import useGlobalSnackbar from '../../Snackbar/useGlobalSnackbar';
import { setEntityOnlineOrOffline } from '../../../API/requests';
import regexifyString from 'regexify-string'
import useUserInfo from '../../../customHooks/useUserInfo';

import './MoreInfo.css'

const StyledAvatar = styled(Avatar)({
    backgroundColor: 'var(--light-blue)',
})

function MoreInfo(props) {
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const { openInfoDialog, openErrDialog } = useDialog()
    const { triggerSnackbar } = useGlobalSnackbar();
    const { userInfo } = useUserInfo()

    const setOnlineDialogInfo = { title: `Set About Section ${props.about.online ? 'Offline' : 'Online'}?`, message: `Set About Section ${props.about.title} ${props.about.online ? 'Offline' : 'Online'}?` }

    const handleSetSpeakerOnline = () => {
        setEntityOnlineOrOffline('aboutus', props.about.id, !props.about.online).then(res => {
            triggerSnackbar(res.data.message, res.data.activity, 'success', { vertical: process.env.REACT_APP_SNACKBAR_VERTICAL, horizontal: process.env.REACT_APP_SNACKBAR_HORIZONTAL })
            props.refresh()
            props.close()
        }, err => {
            openErrDialog(err.response ? err.response : err)
        })
    }

    const regexifyPaterm = /\[\[(.*?)\]\]/gim;
    const regexifyDecorator = (match, index) => {
        const string = match.substring(2, match.length - 2)
        return <label key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</label>;
    }

    return (
        <Dialog
            maxWidth='md'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
            className='about-mui-dialog-container'
        >
            <DialogContent className='about-more-info-dialog-content'>
                <div className='about-more-info-dialog-header'>
                    <div className='about-dialog-title'>
                        <p style={{ color: 'white', fontSize: '18px' }}>{props.about.title}</p>
                    </div>
                    <div className='about-dialog-actions'>
                        {!props.hideActions &&
                            <>
                                {userInfo.user_role.canRemoveAbout &&
                                    <Tooltip title='Delete About Section'>
                                        <DeleteIcon onClick={() => setDeleteDialog(true)} />
                                    </Tooltip>
                                }
                                {userInfo.user_role.canEditAbout &&
                                    <>
                                        <Tooltip title='Edit About Section'>
                                            <EditIcon onClick={() => setOpenEditDialog(true)} />
                                        </Tooltip>
                                        <Tooltip title={`Set About Section ${props.about.online ? 'Offline' : 'Online'}`}>
                                            <OnlineIcon sx={props.about.online ? { fill: 'var(--light-green)' } : { fill: 'var(--light-red)' }} onClick={() => openInfoDialog(setOnlineDialogInfo, 'y/n', () => handleSetSpeakerOnline())} />
                                        </Tooltip>
                                    </>
                                }
                            </>}
                    </div>
                </div>
                <div className='about-more-info-dialog-info-wrapper'>
                    <div className='about-more-info-info-container'>
                        <div className='about-list-scrollable'>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Title />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Title' secondary={props.about.title} />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <StyledAvatar>
                                            <Title />
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Text' secondary={regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: props.about.text })} />
                                </ListItem>
                            </List>
                        </div>
                        <Divider orientation='horizontal' sx={{ width: '90%' }} />
                        <div className='style-info-div'>
                            <div className='style-inner-div'>
                                <p style={{ fontSize: '18px' }}>Misc Style:</p>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginRight: '5px' }}>Background Color:</p>
                                    <Tooltip title={props.about.background_color}>
                                        <div style={{ backgroundColor: props.about.background_color, width: '20px', height: '20px' }} />
                                    </Tooltip>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ marginRight: '5px' }}>Text Color:</p>
                                    <Tooltip title={props.about.text_color}>
                                        <div style={{ backgroundColor: props.about.text_color, width: '20px', height: '20px' }} />
                                    </Tooltip>
                                </div>
                                <p>{`Image position: ${props.about.image_position}`}</p>
                            </div>
                            {props.about.gradient &&
                                <div className='style-inner-div'>
                                    <p style={{ fontSize: '18px' }}>Gradient Style:</p>
                                    <div style={{ display: 'flex' }}>
                                        <p style={{ marginRight: '5px' }}>Gradient Color 1:</p>
                                        <Tooltip title={props.about.gradient_color_1}>
                                            <div style={{ backgroundColor: props.about.gradient_color_1, width: '20px', height: '20px' }} />
                                        </Tooltip>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <p style={{ marginRight: '5px' }}>Gradient Color 2:</p>
                                        <Tooltip title={props.about.gradient_color_2}>
                                            <div style={{ backgroundColor: props.about.gradient_color_2, width: '20px', height: '20px' }} />
                                        </Tooltip>
                                    </div>
                                    <p>{`Gradient angle: ${props.about.gradient_angle}deg`}</p>
                                </div>
                            }
                        </div>
                        <div className='about-more-info-chips-div'>
                            <BooleanChip value={props.about.online} label='Online?' tooltip='online' />
                        </div>
                    </div>
                    <Divider orientation='horizontal' sx={{ width: '90%' }} />
                    <div className='about-more-info-bottom'>
                        <div className='about-more-info-image'>
                            {props.about.section_image &&
                                <img
                                    alt='section landscape'
                                    src={`${process.env.REACT_APP_SERVER_URL}/resources/images/aboutus/${props.about.section_image}`}
                                    width='auto'
                                    height='100%'
                                    onClick={() => openInNewTab(`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${props.about.section_image}`)}
                                />
                            }
                        </div>
                        <Divider orientation='vertical' sx={{ height: '90%' }} />
                        <div className='about-more-info-other-info'>
                            <p>{`ID: ${props.about.id}`}</p>
                            <p>{`Image ID: ${props.about.image_id}`}</p>
                            <p>{`Created At: ${moment(props.about.createdAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                            <p>{`Last Update: ${moment(props.about.updatedAt).format('YYYY-MM-DD, HH:mm:ss')}`}</p>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DeleteDialog open={deleteDialog} close={() => setDeleteDialog(false)} closeAll={props.close} type='About section' data={props.about} refresh={props.refresh} />
            <EditAbout open={openEditDialog} close={() => { setOpenEditDialog(false); props.close() }} data={props.about} refresh={props.refresh} />
        </Dialog>
    )
}

export default MoreInfo